import React, {useEffect,useState, useContext} from 'react'
import { Link } from 'react-router-dom'
import { ProfileContext } from "../../Context";
import { alertErrorMessage } from '../../UtilityComponent/CustomAlertMessage';
import LoaderHelper from '../../UtilityComponent/Loading/LoaderHelper';
import AuthService from '../../Api_Module/Api_Services/AuthService';

const Header = () => {
    const [notifications, setNotifications] = useState([]);
    const { firstName, email, lastName,  mobile } = useContext(ProfileContext);

    console.log(firstName, lastName, email, mobile, "details")

    // useEffect(()=>{
    //     handleNotifications()
    // },[])

    // const handleNotifications = async () => {
    //   LoaderHelper.loaderStatus(true);
    //   await AuthService.p2pNotificationList().then(async (result) => {
    //     LoaderHelper.loaderStatus(false);
    //     if (result.success) {
    //         console.log(result.data)
    //       try {
    //         setNotifications(result?.data?.reverse()?.[0]);
    //       } catch (error) {
    //         console.log(error);
    //       }
    //     }
    //   });
    // };


    return (
        <>
           <section className="page_banner" >
                <div className="container" >
                    <div className="css-wcquo4">
                        <h2 className="css-akh7ai">Trade USDT Easily - Buy and Sell Using Your Favorite Payment Method</h2>
                    </div>
                </div>
            </section>
            <div className="page_title_bar" >
                <div className="container" >
                    <div className="flex_rows" >
                        <nav className="  pt_bar_left" >
                            <div className="nav nav-tabs pt_bar_nav" id="nav-tab" role="tablist">
                                {/* <Link to='/p2p_trading' className="nav-link ps-0" >Buy Crypto</Link> */}
                                <Link to='/p2p_trading' className="nav-link" >P2P Trading</Link>
                            </div>
                        </nav>
                        <nav className="  text-end" >
                            <div className="pt_bar_right" >
                      
                                <Link className="pbr_link  d-none d-md-flex  " to="/my_orders" >
                                    <img src="/images/order_icon.svg" width="18" className="imng-fluid me-2" alt="" />
                                    Orders
                                </Link>
                                <div className="dropdown pbr_link_dropdown">
                                    <a className=" pbr_link pe-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="ri-more-2-fill me-2"></i>
                                        <span className=" d-none d-md-flex ">More</span>
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li>
                                        {(firstName && lastName && email && mobile) ?
                                            <Link to="/add_post" className="dropdown-item" >  <img src="/images/my_post.svg" width="18" className="img-fluid me-2" alt="" /> Add New post</Link>
                                            :
                                            <Link to="#" className="dropdown-item"  onClick={()=>alertErrorMessage("Please update your profile")}>  <img src="/images/my_post.svg" width="18" className="img-fluid me-2" alt="" /> Add New post</Link>}

                                            </li>
                                        <li><Link className="dropdown-item" to="/MyPost"> <img src="/images/add_post.svg" width="18" className="imng-fluid me-2" alt="" /> My Posts</Link></li>
                                        {/* <li><Link className="dropdown-item d-block" to="/PaymentMethod"> <i className="ri-bank-card-line mr-2" style={{ color: '#fda52f' }}></i> Payment Method </Link></li> */}
                                        
                                    </ul>
                                    
                                </div>
                                {/* <ul>
                                  
                <li className="avatar-info notifications">
                  <span className="cursor_pointer   btn-icon">
                    <i className="ri-notification-3-line me-0"></i>
                    <span className="nofti_alert"></span>
                  </span>
                  <ul className="submenu pb-0">
                    <h6 className="dropdown-header d-flex align-items-center pb-3 ">
                      <i className="ri-notification-3-line me-3"></i>New
                      Notificatons
                    </h6>
                    <hr />

                    <div className="py-2" style={{ textAlign: "center" }}>{notifications ? notifications?.title?.length > 15 ? notifications?.title?.slice(0, 10) + '...' : notifications?.title : "No Data Available"} </div>
                  </ul>
                </li>
                                </ul> */}
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
