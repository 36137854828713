import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FacebookShareButton, WhatsappShareButton, LinkedinShareButton, LinkedinIcon, FacebookIcon, WhatsappIcon } from "react-share";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../UtilityComponent/CustomAlertMessage";

const ReferralPage = () => {
  const [totalReferrals, setTotalReferrals] = useState("");
  const [totalRewards, setTotalRewards] = useState("");
  const [referralLink, setReferralLink] = useState("");
  const [referralAmount, setReferralAmount] = useState();

  useEffect(() => {
    handleReferralAmount();
    handleReferralLink();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleReferralAmount = async () => {
    await AuthService.totalrefercount().then(async (result) => {
      if (result?.success) {
        setTotalReferrals(result?.data?.total_referred);
      }
    });
  };

  const handleReferralLink = async () => {
    await AuthService.referralLink().then(async (result) => {
      if (result?.success) {
        setReferralLink(result?.data?.user_code);
      }
    });
  };


  const link = "https://Inoutbit.com/signup?reffcode=" + referralLink;
  const msg = "Get 20 IOB on Inoutbit Sign Up Now | Valid for the first 100000 Sign Ups. Refer your friends to earn 10 IOB on every signup & 50% of the commission fee generated by your invites.";


  return (
    <>
      <section className="inner-page-banner">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title text-gradient">Inoutbit Referral Program</h1>
            <span className="partnerx-hero-description-title pt-3">
              Inoutbit presents you first ever exchange that will educate you
              about Crypto Trading and learning. Start your trading journey now!
            </span>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <Link to="/profile">My Account</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Referral Program
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <div className="bg-1">
        <section className=" invite_sec logg_inn bg_img">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 mx-auto">
                <div className="card shadow-soft ">
                  <div className="card-body invite_card ">
                    <div className="text-center">
                      <h4>
                        INOUTBIT EXCHANGE WILL BE LIVE AFTER 100000 SIGN-UPS
                      </h4>
                      <p className=" ">
                        Get 20 IOB worth 12$ on Inoutbit Sign Up Now | Valid for
                        the first 100000 Sign Ups. Refer your friends to earn 10
                        IOB worth 6$ on every signup & 50% of the commission fee
                        generated by your invites.
                      </p>
                    </div>
                    <br />

                    <hr />
                    <br />

                    <div className="congr_sec">
                      <h4>Congratulations!</h4>
                      <p>
                        You have been registered & verified successfully you've
                        earned 20 IOB as a signup bonus, You'll be notified on
                        registered email when our platform will go live
                      </p>

                      <div className="token_share">
                        <a href="#/s" className="button is-play">
                          <img alt="" src="/images/main_img.svg" width="100" height="100" />
                          <div className="button-outer-circle has-scale-animation"></div>
                          <div className="button-outer-circle has-scale-animation has-delay-short"></div>
                        </a>

                        <h3 className="earned_points text-gradient"> 20 IOB</h3>
                        <small>You have earned</small>
                      </div>
                    </div>
                    <h5>
                      <i className="fa fa-link"></i> Refer & Earn</h5>
                    <form data-copy="true ">
                      <input type="text" className="shadow-soft" value={"https://Inoutbit.com/signup?reffcode=" + referralLink} data-click-select-all readOnly />
                      <button type="button" className="shadow-soft_inner" style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                        .writeText(link).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                        <i className="ri-file-copy-line text-gradient"></i>
                      </button>
                    </form>
                    <div className="share" style={{ columnGap: "2rem" }}>
                      <FacebookShareButton url={link} title={msg}>
                        <FacebookIcon size={40} round />
                      </FacebookShareButton>

                      <LinkedinShareButton url={link} title={msg}>
                        <LinkedinIcon size={40} round />
                      </LinkedinShareButton>

                      <WhatsappShareButton url={link} title={msg}>
                        <WhatsappIcon size={40} round />
                      </WhatsappShareButton>
                    </div>
                    <div className="hurryup text-center mb-4">
                      <p className=" ">
                        Hurry up and don’t miss the opportunity to earn free
                        Crypto. It's your time to be a part of <br /> world
                        leading Crypto Learning, Trading & Earning platform.
                      </p>
                      <br />
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-md-4">
                        <div className="bl_card shadow-soft_inner h-100">
                          <img alt="" src="/images/download_1.png" className="img-fluid" />
                          <h3 className="">
                            <small>Total Referrals</small>
                            {totalReferrals}
                          </h3>
                          <Link to="/ReferralList" className=" text-gradient mt-3 decoration-underline" >
                            View Referal List
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="bl_card shadow-soft_inner  h-100">
                          <img alt="" src="/images/download_2.png" className="img-fluid" />
                          <h3 className="">
                            <small>Total Rewards</small>
                            {totalRewards} IOB = {referralAmount} $
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="partnerx-section partnerx-hero">
          <div className="partnerx-hero-bullets">
            <ul className="partnerx-hero-bullets-list">
              <li className="partnerx-hero-bullets-list-item">
                <img src="/images/50.png" alt="Bullet-1" className="partnerx-hero-bullets-list-item-image" />
                <span className="partnerx-hero-bullets-list-item-text">
                  Earn 50% as reward of every trading fee
                </span>
              </li>
              <li className="partnerx-hero-bullets-list-item">
                <img src="/images/cash-on-delivery.png" alt="Bullet-2" className="partnerx-hero-bullets-list-item-image" />
                <span className="partnerx-hero-bullets-list-item-text">
                  Payout every 24 hours!
                </span>
              </li>
              <li className="partnerx-hero-bullets-list-item">
                <img src="/images/bitcoin_new.png" alt="Bullet-3" className="partnerx-hero-bullets-list-item-image" />
                <span className="partnerx-hero-bullets-list-item-text">
                  Unlimited referrals
                </span>
              </li>
              <li className="partnerx-hero-bullets-list-item">
                <img src="/images/money.png" alt="Bullet-4" className="partnerx-hero-bullets-list-item-image" />
                <span className="partnerx-hero-bullets-list-item-text">
                  Unlimited rewards
                </span>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default ReferralPage;
