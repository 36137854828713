import React, { useEffect, useState , useContext} from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import { $ } from "react-jquery-plugin";
import { alertErrorMessage, alertSuccessMessage } from "../../UtilityComponent/CustomAlertMessage";
import { ApiConfig } from "../../Api_Module/Api_Config/ApiEndpoints";
import { ProfileContext } from "../../Context";

const PTwoP = () => {
  const navigate = useNavigate();
  const [coinList, setCoinList] = useState([]);
  const [fiatCurrency, setFiatCurrency] = useState([]);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState({})
  const [buyOrders, setbuyOrders] = useState([]);
  const [sellOrders, setSellOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedCoin, setSelectedCoin] = useState('');
  const [payableAmount, setPayableAmount] = useState('');
  const [receivableAmount, setReceivableAmount] = useState('');
  const [checkAgreement, setCheckAgreement] = useState(false);
  const [filterInp, setFilterInp] = useState({ amount: '', fiat: 'ALL', paymentMethod: 'ALL' });
  // const availPaymentMode = [{ type: 'Bank' }, { type: 'UPI' }, { type: 'Paytm' }, { type: 'Phonepay' }, { type: 'IMPS' }];
  const [currentTab, setCurrentTab] = useState('BUY');
  const [selectedPaymentMode, setSelectedPaymentMode] = useState('');
    // const [paymentInputs, setPaymentInputs] = useState({});
  const [sellerPaymentMethods, setSellerPaymentMethods] = useState([])
  // const [paymentMethod, setPaymentMethod] = useState({})
  // const [upiList,setUpiList] = useState()
  // const [bankList,setBankList] = useState()
  const [traderPaymentMethod, setTraderPaymentMethod] = useState()
  const [buyerPaymentMethods, setBuyerPaymentMethods] = useState([])
  const [sellerSelectedPayment, setSellerSelectedPayment] = useState([]);
  const [buyerSelectedPayment, setBuyerSelectedPayment] = useState([]);
  const [sellerMethod,setSellerMethod] = useState()
  const [buyerMethod, setBuyerMethod] = useState()
  const [notify,setNotify] = useState(false)

  const { kycStatus, trades, handleUserDetials, registeredDays, counterCurrency, counterCurrencyAmount} = useContext(ProfileContext);
  const copyText = (data) => {
    navigator.clipboard.writeText(data).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })
  }


  const { firstName, email, lastName,  mobile } = useContext(ProfileContext);

  const showExpandedRow = (data) => {
    console.log(data, "ooo")
    handleResetinput();
    if (data === selectedOrder) {
      setSelectedOrder(null);
    }
    else {
      setSelectedOrder(data);
    }
  };

  console.log(currentTab, "77777")


  const resetInput = () => {
    setFilterInp({ amount: '', fiat: 'ALL', paymentMethod: 'ALL' })
  };

  // const getPaymentMethods = async () => {
  //   LoaderHelper?.loaderStatus(true);
  //   await AuthService.paymentMethod().then(async (result) => {
  //     LoaderHelper?.loaderStatus(false);
  //     if (result?.success) {
  //       setPaymentMethod(result?.data);
  //     }
  //   });
  // };

  const fetchPaymentMethods = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.fetchPaymentMethods().then(async (result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        setTraderPaymentMethod(result?.data)
        //  setUpiList(result?.data?.UpiDetails)
        //  setBankList(result?.data?.BankDetails)
      }
    });
  };


  const p2pNotify = async () => {
    LoaderHelper?.loaderStatus(true);
    await AuthService.paymentMethod().then(async (result) => {
      LoaderHelper?.loaderStatus(false);
      if (result?.success) {
        if(result.data){
          setNotify(true)
        }
      }
    });
  };


  const getBuyOrder = async (type) => {
    LoaderHelper?.loaderStatus(true);
    await AuthService.p2pBuyOrder(type).then(async (result) => {
      LoaderHelper?.loaderStatus(false);
      if (result?.success) {
        setbuyOrders(result?.data);
        setAllData(result?.data);
        setData(result?.data);
        console.log(result.data._id, "1111")
      
        // setSellerSelectedPayment(result?.data?.payment_method)
      }
    });
  };

  useEffect(()=>{
if(buyOrders.length > 0){
 p2pCounterCurrency( selectedCoin)
} 
if(sellOrders.length > 0){
 p2pCounterCurrency( selectedCoin)
 
}
  },[buyOrders, sellOrders])



  const getSellOrder = async (type) => {
    LoaderHelper?.loaderStatus(true);
    await AuthService.p2pSellOrder(type).then(async (result) => {
      LoaderHelper?.loaderStatus(false);
      if (result?.success) {
        setSellOrders(result?.data)
        setAllData(result?.data)
        // setBuyerSelectedPayment(result?.data?.payment_method)
      }
    });
  };


  // const checkingCurrency = buyOrders.filter(item=> item?.currencyAmount <= counterCurrencyAmount && item?.counterCurrency == counterCurrency)
  // console.log(checkingCurrency, "111")

  // const checkingTrades = buyOrders.filter(item=> item?.totalTransactions <= trades)
  // console.log(checkingTrades, "77")

  // const checkingKyc = buyOrders.filter(item => item?.kyc == true ? kycStatus == "2" : false)
  // console.log(checkingKyc, "22")

  // const checkingRegisterDays = buyOrders.filter(item=> item?.registered_days <= registeredDays)
  // console.log(checkingRegisterDays, "zz")


  // checkingCurrency 

  
  const p2pCoinList = async () => {
    await AuthService.p2pCoinList().then(async (result) => {
      if (result?.success) {
        setCoinList(result?.data)
        getBuyOrder(result?.data[0]?.short_name)
        setSelectedCoin(result?.data[0]?.short_name)
      }
    });
  };
  const fiatCurrencyList = async () => {
    await AuthService.fiatCurrencyList().then(async (result) => {
      if (result?.success) {
        setFiatCurrency(result?.data)
      }
    });
  };

  const selectCoin = (item) => {
    setSelectedCoin(item?.short_name);
    if (currentTab === 'BUY') {
      getBuyOrder(item?.short_name)
    } else {
      getSellOrder(item?.short_name)
    }
  };


  const refreshData = () => {
    if (currentTab === 'BUY') {
      getBuyOrder(selectedCoin)
    } else {
      getSellOrder(selectedCoin)
    }
  };

  const handleTabChange = (type) => {
    setCurrentTab(type)
    setSelectedOrder(null)
    setSelectedCoin(coinList?.[0]?.short_name);
    if (type === "BUY") {
      getBuyOrder(coinList?.[0]?.short_name)
    } else {
      getSellOrder(coinList?.[0]?.short_name)
    }
  };

  const handleInput = (e) => {
    switch (e.target.name) {
      case 'payableAmount':
        setPayableAmount(e.target.value)
        setReceivableAmount(e.target.value / selectedOrder?.fixed_price)
        break;
      case 'receivableAmount':
        setReceivableAmount(e.target.value)
        setPayableAmount(e.target.value * selectedOrder?.fixed_price)
        break;
      case 'filterAmount':
        setFilterInp(data => ({ ...data, amount: e.target.value }));
        break;
      case 'filterFiat':
        setFilterInp(data => ({ ...data, fiat: e.target.value }))
        break;
      case 'filterPayment':
        setFilterInp(data => ({ ...data, paymentMethod: e.target.value }))
        break;

      default:
        break;
    }
  };


  // const debounce = (func, delay) => {
  //   let timer;
  //   return function (...args) {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       func.apply(this, args);
  //     }, delay);
  //   };
  // };
  // const delayedLog = debounce(() => { console.log('Check'); }, 3000);
  // useEffect(() => {
  //   delayedLog()
  // }, []);

  const filterBuyOrders = () => {
    let filteredData = allData?.filter((item) => {
      let payments = item?.payment_method?.map((item) => item?.type);
      return ((item?.quote_short_name === filterInp?.fiat || filterInp?.fiat === "ALL") && (payments?.includes(filterInp?.paymentMethod) || filterInp?.paymentMethod === "ALL") && ((+filterInp?.amount >= item?.min_amount && (+filterInp?.amount <= item?.max_amount)) || filterInp?.amount === ""))
    })
    setbuyOrders(filteredData);
  };

  const filterSellOrders = () => {
    let filteredData = allData?.filter((item) => {
      return ((item?.quote_short_name === filterInp?.fiat || filterInp?.fiat === "ALL") && (item?.payment_method?.includes(filterInp?.paymentMethod) || filterInp?.paymentMethod === "ALL") && ((+filterInp?.amount >= item?.min_amount && (+filterInp?.amount <= item?.max_amount)) || filterInp?.amount === ""))
    })
    setSellOrders(filteredData);
  };

  const handleResetinput = () => {
    setPayableAmount('');
    setReceivableAmount('');
    setCheckAgreement(false);
  };


  console.log(buyOrders, "0000")
  console.log(selectedOrder, "777")

  const handleOrder = () => {
    if(currentTab == "BUY"){
      if (receivableAmount < selectedOrder?.min_amount || receivableAmount > selectedOrder?.max_amount || !checkAgreement) {
        alertErrorMessage('Please fill all required details')
        return
      };
    } else{
      if (payableAmount < selectedOrder?.min_amount || payableAmount > selectedOrder?.max_amount || !checkAgreement) {
        alertErrorMessage('Please fill all required details')
        return
      };
    }
    
    // $("#t_requirements").modal("show");
    buyCurrency()
  };


  const buyCurrency = async () => {
    LoaderHelper?.loaderStatus(true);
    let timestamp = Math.floor(Date.now() / 1000)
    console.log(Date.now(), "parull")
    await AuthService.buyCurrency(payableAmount, receivableAmount, selectedOrder?._id, selectedOrder?.postAd_user, timestamp, currentTab == "BUY" ?sellerSelectedPayment : buyerSelectedPayment, currentTab == "BUY" ? (sellerMethod == "P2P_Wallet" ?  [{ type :"P2P_Wallet"}] : sellerMethod ): (buyerMethod == "P2P_Wallet" ?  [{ type :"P2P_Wallet"}] : buyerMethod)).then(async (result) => {
      LoaderHelper?.loaderStatus(false);
      if (result?.success && result?.data?.order_id) {
        buyP2pPage(result?.data?.order_id);
        alertSuccessMessage('Order placed successfully!!')
      }
    });
  };

  const buyP2pPage = (id) => {
    // $("#t_requirements").modal("hide");
    navigate(`/buyPTwop_details/${id}`)
  };

  useEffect(() => {
    if (currentTab === 'SELL') {
      filterSellOrders();
    } else {
      filterBuyOrders();
    }
  }, [filterInp]);


  
  const p2pCounterCurrency = async (short_name) => {
    LoaderHelper?.loaderStatus(true);
    await AuthService.p2pCounterCurrency(short_name).then(async (result) => {
      LoaderHelper?.loaderStatus(false);
      if (result?.success) {
        handleUserDetials()
       console.log (result.message)
      }
    });
  };


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    p2pCoinList();
    fiatCurrencyList();
    fetchPaymentMethods()
    // p2pNotify()
  }, []);

  return (
    <>
      <section className="page_banner" >
        <div className="container" >
          <div className="css-wcquo4">
            <h2 className="css-akh7ai">Trade USDT Easily - Buy and Sell Using Your Favorite Payment Method</h2>
          </div>
        </div>
      </section>
      <div className="page_title_bar" >
        <div className="container" >
          <div className="flex_rows" >
            <nav className="  pt_bar_left" >
              <div className="nav nav-tabs pt_bar_nav" id="nav-tab" role="tablist">
                {/* <Link to='/p2p_trading' className="nav-link ps-0" id="nav-buy_crypto-tab" data-bs-toggle="tab" data-bs-target="#nav-buy_crypto" type="button" role="tab" aria-controls="nav-buy_crypto" aria-selected="false" >Buy Crypto</Link> */}
                <Link to='/p2p_trading' className="nav-link active" id="nav-p2p-tab" data-bs-toggle="tab" data-bs-target="#nav-p2p" type="button" role="tab" aria-controls="nav-buy_crypto" aria-selected="false" >P2P Trading</Link>
              </div>
            </nav>
            <nav className="  text-end" >
              <div className="pt_bar_right" >
                <Link className="pbr_link  d-none d-md-flex  " to="/my_orders" >
                  <img src="/images/order_icon.svg" width="18" className="imng-fluid me-2" alt="" />
                  Orders
                </Link>
                <div className="dropdown pbr_link_dropdown">
                  <a className=" pbr_link pe-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="ri-more-2-fill me-2"></i>
                    <span className=" d-none d-md-flex "  >More</span>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                    {(firstName && lastName && email && mobile) ?
                                            <Link to="/add_post" className="dropdown-item" >  <img src="/images/my_post.svg" width="18" className="img-fluid me-2" alt="" /> Add New post</Link>
                                            :
                                            <Link to="#" className="dropdown-item"  onClick={()=>alertErrorMessage("Please update your profile")}>  <img src="/images/my_post.svg" width="18" className="img-fluid me-2" alt="" /> Add New post</Link>}

                      </li>
                    <li><Link className="dropdown-item" to="/MyPost"> <img src="/images/add_post.svg" width="18" className="imng-fluid me-2" alt="" /> My Posts</Link></li>
                    {/* <li><Link className="dropdown-item d-block" to="/PaymentMethod"> <i className="ri-bank-card-line mr-2" style={{ color: '#fda52f' }}></i> Payment Method </Link></li> */}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="pp_filter_bar" >
        <div className="container" >
          <div className="card-filter" >
            <div className="card-body-top card-body-top-riverse_mobile" >
              <div className="body_left" >
                <div className="mrt_row">
                  <div className="scroll-subtabs">
                    {coinList ? coinList?.map((item, index) => {
                      return (
                        <a key={index} href="#" className={`subtabs_link curser-pointer ${item?.short_name === selectedCoin && 'active'}`} onClick={() => selectCoin(item)}>{item?.short_name}</a>
                      )

                    }) : null}

                  </div>
                </div>
              </div>
              <div className="body_right body_right_w_filter" >
                <ul className="buy_sell_btns nav nav-tabs" id="myTab" role="tablist" >
                  <li className="nav-item" role="presentation">
                    <button className="btn btn-shadow btn-success_inner active" id="p_two_p_buy-tab" data-bs-toggle="tab" data-bs-target="#p_two_p_buy" type="button" role="tab" aria-controls="p_two_p_buy" aria-selected="true" onClick={() => handleTabChange('BUY')} >
                      BUY
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="btn btn-shadow btn-danger_inner" id="p_two_p_sell-tab" data-bs-toggle="tab" data-bs-target="#p_two_p_sell" type="button" role="tab" aria-controls="p_two_p_sell" aria-selected="false" onClick={() => handleTabChange("SELL")} >
                      Sell
                    </button>
                  </li>
                </ul>
                <div className="mob_filter d-md-none shadow-soft" >
                  <i className="ri-filter-2-fill"></i>
                </div>
              </div>
            </div>
            <div className="custom_drop_modal"  >
              <div className="card-body-bottom" >
                <div className="body_left" >
                  <div className="row gx-1 form_filter" >
                    <div className=" col mb-2 mb-md-0 " >
                      <div className="form-group" >
                        <label>Amount</label>
                        <div className="froup_feild mt-1" >
                          <input type="number" className="feild_input" placeholder="Enter amount" name="filterAmount" onWheel={(e) => e.target.blur()} onChange={handleInput} value={filterInp?.amount} />

                        </div>
                      </div>
                    </div>
                    <div className=" col mb-2 mb-md-0 " >
                      <div className="form-group" >
                        <label>Fiat</label>
                        <div className="froup_feild mt-1" >
                          <select className="no-border w-100 ps-2" name="filterFiat" onWheel={(e) => e.target.blur()} onChange={handleInput} value={filterInp?.fiat} >
                            <option hidden>Select Fiat </option>
                            <option value='ALL'> ALL</option>
                            {fiatCurrency ? fiatCurrency?.map((item, index) => <option key={item?.short_name} value={item?.short_name}>{item?.short_name}</option>) : null}
                            
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col mb-2 mb-md-0 " >
                      <div className="form-group" >
                        <label>Payment</label>
                        <div className="froup_feild mt-1" >
                          <select className="no-border w-100 ps-2" name="filterPayment" onChange={handleInput} value={filterInp?.paymentMethod}>
                            <option hidden>Select Payment Method </option>
                            <option value='ALL'> ALL</option>
                            {/* {paymentMethod?.map((item) => <option key={item?.payment_type} value={item?.payment_type}>{item?.payment_type}</option>)} */}
                            
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col mb-2 mb-md-0 " >
                      <div className="form-group" >
                        <label>Filter </label>
                        <div className="froup_feild filter_group mt-1" >
                          <div className="dropdown">
                            <Link className=" pbr_link pe-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              <i className="ri-filter-2-line"></i>
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li className="form-check">
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                  Show tradable only
                                </label>
                              </li>
                              <li className="form-check">
                                <input className="form-check-input" type="checkbox" id="flexCheckChecked" />
                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                  Traded Before
                                </label>
                              </li>
                              <li className="form-check">
                                <input className="form-check-input" type="checkbox" id="flexCheckChecked" />
                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                  Following Merchant
                                </label>
                              </li>
                            </ul>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="body_right d-none d-md-flex" >
                  <button className="btn btn-gradient mt-md-3" type="button" onClick={resetInput}>
                    <span> Clear Filter </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pp_data ant-table" >
        <div className="container" >
          <div className="tab-content" id="myTabContent">
            {/* buy data tab */}
            <div className="tab-pane fade show active" id="p_two_p_buy" role="tabpanel" aria-labelledby="p_two_p_buy-tab">
              <div className="ant-table-content">
                <div className="table" >
                  <div className="ant-table-thead thead d-none d-md-block">
                    <div className="tr" >
                      <div className="ant-table-cell th">Advertiser</div>
                      <div className="ant-table-cell th">Price</div>
                      <div className="ant-table-cell th">Limit</div>
                      <div className="ant-table-cell th">Payment Method</div>
                      <div className="ant-table-cell th">
                        <div className="advertiseList_tradeTitle__ae016">Trade
                          <div className="advertiseList_fee__TDbjz">0 Fee</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tbody ant-table-tbody">
                    {buyOrders?.length > 0 ? buyOrders?.map((item) => {
                      return (
                        <React.Fragment key={item?._id} >
                          <div className=" tr ant-table-row ant-table-row-level-0">
                            <div className=" td ant-table-cell patr_data1 ">
                              <div className="advertiseList_avatarContent__uAQHP">
                                <div className="advertiseList_avatar__rEIGv shadow-soft">💎</div>
                                <div className="advertiseList_adAvatarRight__SbOZP"><span className="advertiseList_nickName__VI9tJ">{item?.post_name}</span>
                                {item?.successfullOrders == 0 ? null :
                                  <p className="advertiseList_merchantOrder__aO2o_">{item?.successfullOrders }<span className="advertiseList_tradingOrder__DjoPA">successfull orders</span>
                                  {/* <span className="advertiseList_tradingLine__swVrO"></span>99.59%<span className="advertiseList_tradingOrder__DjoPA">completion</span> */}
                                  </p> }
                                  <div className="advertiseList_firstTrade__Z5OC5">
                                    <p className="advertiseList_merchantOrder__aO2o_">
                                      <span className="text-warning" >
                                        {item?.ratings == 1 ? 
                                        <i className="ri-star-fill me-1"></i>
                                        : item?.ratings == 2 ?
                                        <>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        </>
                                        : item?.ratings == 3 ?
                                        <>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        </>
                                       : item?.ratings == 4 ?
                                       <>
                                       <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        </>
                                        : item?.ratings == 5 ?
                                        <>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        </>
                                        : "No Rating Available"
                                      }
                                      </span>
                                      {item?.ratings == 0 ? " " : <span className="advertiseList_tradingLine__swVrO"></span>}
                                      <span className="text-warning">{item?.ratings == 0 ? "" :item?.ratings}</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className=" td ant-table-cell patr_data2">
                              <div className="advertiseList_adPrice__fKc28"> <small className="d-md-none" >Price</small> <span className="advertiseList_price__ZL3g1">{item?.fixed_price.toFixed(3)}</span><span className="advertiseList_unit__FXgOK">{item?.quote_short_name}</span></div>
                            </div>
                            <div className=" td ant-table-cell patr_data3">
                              <div className="advertiseList_limit__vjRP2">
                                <div>
                                  
                                </div>
                                <div>
                                  
                                  <p className="advertiseList_limitInfo__kSoM7">{item?.min_amount} <span className="advertiseList_adspacing__w6PAR">- </span>{item?.max_amount} <span className="advertiseList_expanCoin__FZDED">{item?.base_short_name}</span></p>
                                </div>
                              </div>
                            </div>
                            <div className=" td ant-table-cell patr_data4">
                              <div className="advertiseList_paymentContent__1oElX">
                                {item?.payment_method?.map((item, index) => {
                                  return (
                                    <span key={index} className={`advertiseList_paymentList__IageJ badge-${index % 3 === 0 ? 'success' : index % 3 === 1 ? 'danger' : 'primary'}`}>
                                      <span>
                                        <span >{item?.type}</span>
                                      </span>
                                    </span>

                                  )
                                })}
                              </div>
                            </div>
                            {/* kyc, counterCurrency, trades, register */}
                            <div className=" td ant-table-cell ant-table-row-expand-icon-cell patr_data5">
                              {/* {(item?.currencyAmount <= counterCurrencyAmount && item?.counterCurrency == counterCurrency) && (item?.totalTransactions <= trades || item?.totalTransaction == 0) &&((item?.kyc == true && kycStatus == "2") || item?.kyc == false ) && (item?.registered_days <= registeredDays || item?.registered_days == 0)} */}
                              {(item?.currencyAmount <= counterCurrencyAmount) && (item?.totalTransactions <= trades || item?.totalTransaction == 0) &&((item?.kyc == true && kycStatus == "2") || item?.kyc == false ) && (item?.registered_days <= registeredDays || item?.registered_days == 0) ?
                              <button className="btn advertiseList_expandIcon__3jNNF advertiseList_sellExpandIcon__F22Dj" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" type="button" onClick={() => {showExpandedRow(item)}}> Buy  {item?.base_short_name}  </button>
                                :
                                <button className="btn btn-outline-danger" type="button" title="update your balance"> Restricted </button>
                             }  


                            </div>
                          </div>
                          {selectedOrder === item &&
                            <div className="ant-table-expanded-row ant-table-expanded-row-level-1 collapse show shadow-soft" id="collapseExample" >
                              <div className="td w-100 ant-table-cell">
                                <div className="advertiseList_expandableContent___v_pW">
                                  <div className="advertiseList_placeAnOrderLeftContent__LgV_2">
                                    <div className="advertiseList_avatarContent__uAQHP">
                                      <div className="advertiseList_avatar__rEIGv shadow-soft">💎</div>
                                      <div className="advertiseList_adAvatarRight__SbOZP">
                                        <div className="d-flex align-items-center" >
                                          <span className="advertiseList_nickName__VI9tJ"> {selectedOrder?.post_name}  </span>
                                          <p className="advertiseList_merchantOrder__aO2o_ ms-2 "><span className="text-warning">

                                        {selectedOrder?.ratings == 1 ? 
                                        <i className="ri-star-fill me-1"></i>
                                        : selectedOrder?.ratings == 2 ?
                                        <>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        </>
                                        : selectedOrder?.ratings == 3 ?
                                        <>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        </>
                                       : selectedOrder?.ratings == 4 ?
                                       <>
                                       <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        </>
                                        : selectedOrder?.ratings == 5 ?
                                        <>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        </>
                                        : "No Rating Available"
                                      }
                                            </span>
                                            {selectedOrder?.ratings == 0 ? " " : <span className="advertiseList_tradingLine__swVrO"></span>}
                                      <span className="text-warning">{selectedOrder?.ratings == 0 ? "" :selectedOrder?.ratings}</span></p>
                                        </div>
                                        {selectedOrder?.successfullOrders == 0 ? null :
                                        <p className="advertiseList_merchantOrder__aO2o_">{selectedOrder?.successfullOrders}<span className="advertiseList_tradingOrder__DjoPA"> successfull orders</span>
                                        {/* <span className="advertiseList_tradingLine__swVrO"></span>99.59%<span className="advertiseList_tradingOrder__DjoPA">completion</span> */}
                                        </p>}

                                      </div>
                                    </div>

                                    <div className="advertiseList_adLimitInfo__e8oAL">
                                      <div className="row" >
                                        <p className="col-6 col-md-3" >
                                          <span className="text-success advertiseList_expandPrice__Bgj3_">
                                            <span>{selectedOrder?.fixed_price} {selectedOrder?.quote_short_name}</span> </span>
                                          <span className="advertiseList_adLimitTitle__sGUqF d-block">Price</span>
                                        </p>
                                        <p className="col-6  col-md-3" >
                                          <span className="text-success advertiseList_expandPrice__Bgj3_">
                                            <span>{selectedOrder?.payment_time} Minutes</span> </span>
                                          <span className="advertiseList_adLimitTitle__sGUqF d-block">Payment Time Limit</span>
                                        </p>
                                        {/* <p className="col-6  col-md-3" >
                                          <span className="text-success advertiseList_expandPrice__Bgj3_">
                                            <span>{selectedOrder?.volume} {selectedOrder?.base_short_name} </span> </span>
                                          <span className="advertiseList_adLimitTitle__sGUqF d-block">Available</span>
                                        </p> */}
                                        <p className="col-12  col-md-12" >
                                          <span className="advertiseList_paymentContent__1oElX">
                                            {item?.payment_method?.map((item, index) => {
                                              return (
                                                <span key={index} className={`advertiseList_paymentList__IageJ badge-${index % 3 === 0 ? 'success' : index % 3 === 1 ? 'danger' : 'primary'}`}>
                                                  <span>
                                                    <span >{item?.type}</span>
                                                  </span>
                                                </span>

                                              )
                                            })}
                                           
                                          </span>
                                          <span className="advertiseList_adLimitTitle__sGUqF d-block mt-1">{selectedOrder?.side === "BUY" ? "Buyer's": "Seller's"} payment method</span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="advertiseList_adRemark__afWVG">
                                      <div className="advertiseList_adRemarkTitle__ky3Rq">Merchant Remarks</div>
                                      <div className="advertiseList_remarkInfo__zSuXX">
                                      Minimum {selectedOrder?.currencyAmount + " " + selectedOrder?.counterCurrency} is required in {selectedOrder?.side === "BUY" ? "Seller's": "Buyer's"} P2P Wallet 
                                      </div>
                                    </div>
                                  </div>
                                  <div className="advertiseList_expandableLine__CDGum"></div>
                                  <div className="advertiseList_placeAnOrderRightContent__tE8rt">
                                    <div className="advertiseList_placeAnOrder__XRO8U mb-3">
                                      <p className="advertiseList_placeAnOrderPrompnt__0x9m7">I want to pay</p>
                                      <div className="ant-form-item">
                                        <span className="froup_feild  ant-input-affix-wrapper advertiseList_coinNameInput__1l54d">
                                          <input  placeholder="0" className="ant-input" id="control-hooks_amount" aria-required="true" type="number" onWheel={(e) => e.target.blur()} name="payableAmount" onChange={handleInput} value={payableAmount} />
                                          <span className="ant-input-suffix">
                                            <span className="ant-input-clear-icon ant-input-clear-icon-hidden ant-input-clear-icon-has-suffix" role="button" tabIndex="-1">
                                            </span>
                                            <div className="advertiseList_suffixContent__s3Fce"><span className="advertiseList_all__aTtrH"></span>{selectedOrder?.quote_short_name}</div>
                                          </span>
                                        </span>
                                      </div>
                                      {/* <p className={`  mt-1 mb-0 text-${(payableAmount < selectedOrder?.min_amount || payableAmount > selectedOrder?.max_amount) ? 'danger' : 'success'}`} > <small>Order Limits: {`${selectedOrder?.min_amount} - ${selectedOrder?.max_amount}`}</small> </p> */}
                                    </div>
                                    <div className="advertiseList_placeAnOrder__XRO8U mb-3">
                                      <p className="advertiseList_placeAnOrderPrompnt__0x9m7">I will receive</p>
                                      <div className="ant-form-item">
                                        <span className="froup_feild  ant-input-affix-wrapper advertiseList_coinNameInput__1l54d">
                                          <input placeholder={`${selectedOrder?.min_amount} - ${selectedOrder?.max_amount}`} className="ant-input" id="control-hooks_amount" aria-required="true" type="number" onWheel={(e) => e.target.blur()} name="receivableAmount" onChange={handleInput} value={receivableAmount} />
                                          <span className="ant-input-suffix">
                                            <span className="ant-input-clear-icon ant-input-clear-icon-hidden ant-input-clear-icon-has-suffix" role="button" tabIndex="-1">
                                            </span>
                                            <div className="advertiseList_suffixContent__s3Fce"><span className="advertiseList_all__aTtrH"></span>{selectedOrder?.base_short_name}</div>
                                          </span>
                                        </span>
                                      </div>
                                      <p className={`  mt-1 mb-0 text-${(receivableAmount < selectedOrder?.min_amount || receivableAmount > selectedOrder?.max_amount) ? 'danger' : 'success'}`} > <small>Order Limits: {`${selectedOrder?.min_amount} - ${selectedOrder?.max_amount}`}</small> </p>
                                    </div>
                                  
                                    <div className="advertiseList_placeAnOrder__XRO8U mb-3">
                                     
                                      <div className="ant-form-item">
                                        <span className="froup_feild  ant-input-affix-wrapper advertiseList_coinNameInput__1l54d">
                                          <input placeholder="Select Payment Method" className="ant-input" id="control-hooks_amount" aria-required="true" name="receivableAmount" data-bs-toggle="modal" data-bs-target="#payment_method_list" style={{cursor:"pointer", color:"white"}} onClick={()=>setSellerPaymentMethods(item?.payment_method)} value={sellerSelectedPayment} />
                                          
                                        </span>
                                      </div>
                                    </div>
                                    <div className="advertiseList_tradingAgreement__5tQwy">
                                      <div className="form-check mb-4">
                                        <input className="form-check-input" type="checkbox" id="flexcheckbox" onClick={(e) => e.target.checked ? setCheckAgreement(true) : setCheckAgreement(false)} />I have read and agree to Inoutbit Peer-to-Peer (P2P) Service Agreement
                                      </div>
                                    </div>
                                    <div className="advertiseList_placeAnOrderBtnContent__L7B0w row gx-1">
                                      <div className="col-6 col-md-4" >
                                        <button type="button" className="btn text-white shadow-soft btn-block w-100" onClick={() => setSelectedOrder(null)}><span>Cancel</span></button>
                                      </div>
                                      <div className="col-6  col-md-8" >
                                        <button type="button" className="btn btn-success btn-block w-100 btn-shadow-soft" disabled={receivableAmount < selectedOrder?.min_amount || receivableAmount > selectedOrder?.max_amount || !checkAgreement || !sellerSelectedPayment} onClick={handleOrder} >Buy <span className="advertiseList_expandableCoin__lMp3q">{selectedOrder?.base_short_name}</span></button>
                                      </div>
                                    </div>
                                    <div className="advertiseList_friendlyReminder__SAA_V"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        </React.Fragment>)
                    }) : <p className="text-center p-3 pb-4 no-data h-100 mb-0" >
                      <img src="/images/no-data.png" className='img-fluid ' alt="no data" width="52" />
                      No Data Available
                    </p>}

                  </div>
                </div>
              </div>
            </div>

            {/* sell data tab */}
            <div className="tab-pane fade" id="p_two_p_sell" role="tabpanel" aria-labelledby="p_two_p_sell-tab">
              <div className="ant-table-content">
                <div className="table" >
                  <div className="ant-table-thead thead d-none d-md-block">
                    <div className="tr" >
                      <div className="ant-table-cell th">Advertiser</div>
                      <div className="ant-table-cell th">Price</div>
                      <div className="ant-table-cell th">Limit</div>
                      <div className="ant-table-cell th">Payment Method</div>
                      <div className="ant-table-cell th">
                        <div className="advertiseList_tradeTitle__ae016">Trade
                          <div className="advertiseList_fee__TDbjz">0 Fee</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" tbody ant-table-tbody">
                    {sellOrders?.length > 0 ? sellOrders?.map((item) => {
                      return (
                        <React.Fragment key={item?._id} >
                          <div className=" tr ant-table-row ant-table-row-level-0">
                            <div className=" td ant-table-cell patr_data1 ">
                              <div className="advertiseList_avatarContent__uAQHP">
                                <div className="advertiseList_avatar__rEIGv shadow-soft">💎</div>
                                <div className="advertiseList_adAvatarRight__SbOZP"><span className="advertiseList_nickName__VI9tJ">{item?.post_name}</span>
                                {item?.successfullOrders == 0 ? null :
                                        <p className="advertiseList_merchantOrder__aO2o_">{item?.successfullOrders}<span className="advertiseList_tradingOrder__DjoPA"> successfull orders</span>
                                        {/* <span className="advertiseList_tradingLine__swVrO"></span>99.59%<span className="advertiseList_tradingOrder__DjoPA">completion</span> */}
                                        </p>}
                                  <div className="advertiseList_firstTrade__Z5OC5">
                                    <p className="advertiseList_merchantOrder__aO2o_">
                                      <span className="text-warning" >
                                      {item?.ratings == 1 ? 
                                        <i className="ri-star-fill me-1"></i>
                                        : item?.ratings == 2 ?
                                        <>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        </>
                                        : item?.ratings == 3 ?
                                        <>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        </>
                                       : item?.ratings == 4 ?
                                       <>
                                       <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        </>
                                        : item?.ratings == 5 ?
                                        <>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        </>
                                        : "No Rating Available"
                                      }
                                      </span>
                                      {item?.ratings == 0 ? " " : <span className="advertiseList_tradingLine__swVrO"></span>}
                                      <span className="text-warning">{item?.ratings == 0 ? "" :item?.ratings}</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className=" td ant-table-cell patr_data2">
                              <div className="advertiseList_adPrice__fKc28"> <small className="d-md-none" >Price</small> <span className="advertiseList_price__ZL3g1">{item?.fixed_price.toFixed(3)}</span><span className="advertiseList_unit__FXgOK">{item?.base_short_name}</span></div>
                            </div>
                            <div className=" td ant-table-cell patr_data3">
                              <div className="advertiseList_limit__vjRP2">
                                <div>
                                  {/* <p className="advertiseList_limitTitle__g8wUq">Available</p>
                                  <p className="advertiseList_limitTitle__g8wUq">Limit</p> */}
                                </div>
                                <div>
                                  {/* <p className="advertiseList_limitInfo__kSoM7">{item?.volume} <span className="advertiseList_expanCoin__FZDED">{item?.base_short_name}</span></p> */}
                                  <p className="advertiseList_limitInfo__kSoM7">{item?.min_amount} <span className="advertiseList_adspacing__w6PAR">- </span>{item?.max_amount} <span className="advertiseList_expanCoin__FZDED">{item?.quote_short_name}</span></p>
                                </div>
                              </div>
                            </div>
                            <div className=" td ant-table-cell patr_data4">
                              <div className="advertiseList_paymentContent__1oElX">
                                {item?.payment_method?.map((item, index) => {
                                  return (
                                    <span key={index} className={`advertiseList_paymentList__IageJ badge-${index % 3 === 0 ? 'success' : index % 3 === 1 ? 'danger' : 'primary'}`}>
                                      <span>
                                        <span >{item?.type}</span>
                                      </span>
                                    </span>
                                  )
                                })}
                              </div>
                            </div>
                            <div className=" td ant-table-cell ant-table-row-expand-icon-cell patr_data5">
                            {(item?.currencyAmount <= counterCurrencyAmount) && (item?.totalTransactions <= trades || item?.totalTransaction == 0) &&((item?.kyc == true && kycStatus == "2") || item?.kyc == false ) && (item?.registered_days <= registeredDays || item?.registered_days == 0) ?
                              <button className="btn advertiseList_expandIcon__3jNNF advertiseList_sellExpandIcon__F22Dj sell_btn" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" type="button" onClick={() => showExpandedRow(item)}> Sell  {item?.base_short_name}  </button>
                               :
                                <button className="btn btn-outline-danger" type="button"> Restricted  </button>
                             }  


                            </div>
                          </div>
                          {selectedOrder === item &&
                            <div className="ant-table-expanded-row ant-table-expanded-row-level-1 collapse show shadow-soft" id="collapseExample" >
                              <div className="td w-100 ant-table-cell">
                                <div className="advertiseList_expandableContent___v_pW">
                                  <div className="advertiseList_placeAnOrderLeftContent__LgV_2">
                                    <div className="advertiseList_avatarContent__uAQHP">
                                      <div className="advertiseList_avatar__rEIGv shadow-soft">💎</div>
                                      <div className="advertiseList_adAvatarRight__SbOZP">
                                        <div className="d-flex align-items-center" >
                                          <span className="advertiseList_nickName__VI9tJ"> {selectedOrder?.post_name}  </span>
                                          <p className="advertiseList_merchantOrder__aO2o_ ms-2 "><span className="text-warning">
                                          {selectedOrder?.ratings == 1 ? 
                                        <i className="ri-star-fill me-1"></i>
                                        : selectedOrder?.ratings == 2 ?
                                        <>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        </>
                                        : selectedOrder?.ratings == 3 ?
                                        <>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        </>
                                       : selectedOrder?.ratings == 4 ?
                                       <>
                                       <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        </>
                                        : selectedOrder?.ratings == 5 ?
                                        <>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        </>
                                        : "No Rating Available"
                                      }
                                        </span>{selectedOrder?.ratings == 0 ? " " : <span className="advertiseList_tradingLine__swVrO"></span>}
                                      <span className="text-warning">{selectedOrder?.ratings == 0 ? "" :selectedOrder?.ratings}</span></p>
                                        </div>
                                        {selectedOrder?.successfullOrders == 0 ? null :
                                        <p className="advertiseList_merchantOrder__aO2o_">{selectedOrder?.successfullOrders}<span className="advertiseList_tradingOrder__DjoPA"> successfull orders</span>
                                        {/* <span className="advertiseList_tradingLine__swVrO"></span>99.59%<span className="advertiseList_tradingOrder__DjoPA">completion</span> */}
                                        </p>}

                                      </div>
                                    </div>

                                    <div className="advertiseList_adLimitInfo__e8oAL">
                                      <div className="row" >
                                        <p className="col-6 col-md-3" >
                                          <span className="text-success advertiseList_expandPrice__Bgj3_">
                                            <span>{selectedOrder?.fixed_price} {selectedOrder?.quote_short_name}</span>
                                             </span>
                                          <span className="advertiseList_adLimitTitle__sGUqF d-block">Price</span>
                                        </p>
                                        <p className="col-6  col-md-3" >
                                          <span className="text-success advertiseList_expandPrice__Bgj3_">
                                            <span>{selectedOrder?.payment_time}</span> </span>
                                          <span className="advertiseList_adLimitTitle__sGUqF d-block">Payment Time Limit</span>
                                        </p>
                                        {/* <p className="col-6  col-md-3" >
                                          <span className="text-success advertiseList_expandPrice__Bgj3_">
                                            <span>{selectedOrder?.volume} {selectedOrder?.base_short_name} </span> </span>
                                          <span className="advertiseList_adLimitTitle__sGUqF d-block">Available</span>
                                        </p> */}
                                        <p className="col-12  col-md-12" >
                                          <div className="advertiseList_paymentContent__1oElX">
                                            {item?.payment_method?.map((item, index) => {
                                              return (
                                                <span key={index} className={`advertiseList_paymentList__IageJ badge-${index % 3 === 0 ? 'success' : index % 3 === 1 ? 'danger' : 'primary'}`}>
                                                  <span>
                                                    <span >{item?.type}</span>
                                                  </span>
                                                </span>
                                              )
                                            })}

                                          </div>
                                          <span className="advertiseList_adLimitTitle__sGUqF d-block mt-1">{selectedOrder?.side === "BUY" ? "Buyer's": "Seller's"} payment method</span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="advertiseList_adRemark__afWVG">
                                      <div className="advertiseList_adRemarkTitle__ky3Rq">Merchant Remarks</div>
                                      <div className="advertiseList_remarkInfo__zSuXX">
                                      Minimum {selectedOrder?.currencyAmount + " " + selectedOrder?.counterCurrency} is required in {selectedOrder?.side === "BUY" ? "Seller's": "Buyer's"} P2P Wallet 
                                      </div>
                                    </div>
                                  </div>
                                  <div className="advertiseList_expandableLine__CDGum"></div>
                                  <div className="advertiseList_placeAnOrderRightContent__tE8rt">
                                    <div className="advertiseList_placeAnOrder__XRO8U mb-3">
                                      <p className="advertiseList_placeAnOrderPrompnt__0x9m7">I want to pay</p>
                                      <div className="ant-form-item">
                                        <span className="froup_feild  ant-input-affix-wrapper advertiseList_coinNameInput__1l54d">
                                          <input placeholder={`${selectedOrder?.min_amount} - ${selectedOrder?.max_amount}`} className="ant-input" id="control-hooks_amount" aria-required="true" type="number" onWheel={(e) => e.target.blur()} name="payableAmount" onChange={handleInput} value={payableAmount} />
                                          <span className="ant-input-suffix">
                                            <span className="ant-input-clear-icon ant-input-clear-icon-hidden ant-input-clear-icon-has-suffix" role="button" tabIndex="-1">
                                            </span>
                                            <div className="advertiseList_suffixContent__s3Fce"><span className="advertiseList_all__aTtrH"></span>{selectedOrder?.base_short_name}</div>
                                          </span>
                                        </span>
                                      </div>
                                      <p className={`  mt-1 mb-0 text-${(payableAmount < selectedOrder?.min_amount || payableAmount > selectedOrder?.max_amount) ? 'danger' : 'success'}`} > <small>Order Limits: {`${selectedOrder?.min_amount} - ${selectedOrder?.max_amount}`}</small> </p>
                                    </div>
                                    <div className="advertiseList_placeAnOrder__XRO8U mb-3">
                                      <p className="advertiseList_placeAnOrderPrompnt__0x9m7">I will receive</p>
                                      <div className="ant-form-item">
                                        <span className="froup_feild  ant-input-affix-wrapper advertiseList_coinNameInput__1l54d">
                                          <input placeholder="0" className="ant-input" id="control-hooks_amount" aria-required="true" type="number" onWheel={(e) => e.target.blur()} name="receivableAmount" onChange={handleInput} value={receivableAmount} />
                                          <span className="ant-input-suffix">
                                            <span className="ant-input-clear-icon ant-input-clear-icon-hidden ant-input-clear-icon-has-suffix" role="button" tabIndex="-1">
                                            </span>
                                            <div className="advertiseList_suffixContent__s3Fce"><span className="advertiseList_all__aTtrH"></span>{selectedOrder?.quote_short_name}</div>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="advertiseList_placeAnOrder__XRO8U mb-3">
                                      {/* <p className="advertiseList_placeAnOrderPrompnt__0x9m7">Select payment method</p> */}
                                      <div className="ant-form-item">
                                        <span className="froup_feild  ant-input-affix-wrapper advertiseList_coinNameInput__1l54d">
                                          <input placeholder="Select Payment Method" className="ant-input" id="control-hooks_amount" aria-required="true" name="receivableAmount" data-bs-toggle="modal" data-bs-target="#payment_method_list" style={{cursor:"pointer", color:"white"}} onClick={()=>setBuyerPaymentMethods(item?.payment_method)} value={buyerSelectedPayment} />
                                          {/* <span className="ant-input-suffix">
                                            <span className="ant-input-clear-icon ant-input-clear-icon-hidden ant-input-clear-icon-has-suffix" role="button" tabIndex="-1">
                                            </span>
                                           
                                          </span> */}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="advertiseList_tradingAgreement__5tQwy">
                                      <div className="form-check mb-4">
                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault" onClick={(e) => e.target.checked ? setCheckAgreement(true) : setCheckAgreement(false)} />I have read and agree to Inoutbit Peer-to-Peer (P2P) Service Agreement
                                      </div>
                                    </div>
                                    <div className="advertiseList_placeAnOrderBtnContent__L7B0w row gx-1">
                                      <div className="col-6 col-md-4" >
                                        <button type="button" className="btn text-white shadow-soft btn-block w-100" onClick={() => setSelectedOrder(null)}><span>Cancel</span></button>
                                      </div>
                                      <div className="col-6  col-md-8" >
                                        <button type="button" className="btn btn-danger  btn-block w-100 btn-shadow-soft" disabled={payableAmount < selectedOrder?.min_amount || payableAmount > selectedOrder?.max_amount || !checkAgreement} onClick={() => {handleOrder()}}>Sell <span className="advertiseList_expandableCoin__lMp3q">{selectedOrder?.base_short_name}</span></button>
                                      </div>
                                    </div>
                                    <div className="advertiseList_friendlyReminder__SAA_V"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        </React.Fragment>)
                    }) : <p className="text-center p-3 pb-4 no-data h-100 mb-0" >
                      <img src="/images/no-data.png" className='img-fluid ' alt="no data" width="52" />
                      No Data Available
                    </p>}



                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      {/* Trading Requirement Modal */}
      <div className="modal fade modal_requirements" id="t_requirements" tabIndex="-1" role="dialog" aria-labelledby="t_requirementsLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered end_in_mobile">
          <div className="modal-content">
            <div className="modal-header flex-column ">
              <h3 className="modal-title" id="placeBitLaebl"> Trading Requirement </h3>
              {/* <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i
                                className="ri-close-fill"></i></button> */}
            </div>
            <div className="modal-body p-0">
              <div className="css-ag510y">
                <div className="css-joa6mv">
                  <div className="css-1c6ljq6">
                    <div className="css-vurnku">Complete KYC Verification</div>
                    <div className="css-vurnku">
                      <button data-bn-type="button" className="btn btn-sm btn-warning css-stohmr ">Verify</button>
                    </div>
                  </div>
                  <div className="css-1c6ljq6">
                    <div className="css-vurnku">Enable SMS authentication</div>
                    <div className="css-vurnku">
                      <div className="css-9fayl6">Done
                        <i className="ri-checkbox-circle-fill ms-1"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="css-1bwopy1 row gx-1">
                  <div className="col-6 col-md-4" >
                    <button type="button" className="btn text-white shadow-soft btn-block w-100" data-bs-dismiss="modal"><span>Cancel</span></button>
                  </div>
                  <div className="col-6  col-md-8" >
                    <button type="button" className="btn btn-gradient btn-block w-100" onClick={buyP2pPage}  > <span>Get Verified</span> </button>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="payment_method_form" tabIndex="-1" aria-labelledby="payment_method_formLaebl" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-2 pb-5" style={{ backgroundColor: 'rgb(34 36 38)' }}>
                        <div className="modal-header flex-column px-8">
                            {/* <h3 className="modal-title" id="placeBitLaebl">Set my payment method</h3> */}
                            <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i className="ri-close-fill"></i></button>
                        </div>
                        <div className="modal-body px-md-4 custom-form">
                        <form >
                        {currentTab == "BUY" ? buyOrders?.map((item, index) => {
                                    return (
                                        item?.payment_method.map(item => item.type == sellerSelectedPayment  &&
                                          <div key={item} className="form-group mb-4">
                                            {item.type == "UPI" ?
                                            <>
                                            <label for="bit" >UPI ID : {item?.upi_id} <button type="button" className="shadow-soft_inner" style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                        .writeText(item?.upi_id).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                        <i className="ri-file-copy-line text-gradient"></i>
                      </button></label>

                      <label for="bit" ><img src={ApiConfig?.baseUrl + item?.upi_image}/> </label> </> 
                      :
                       <>
                       <label for="bit" >Bank Name: {item?.bank_name} <button type="button" className="shadow-soft_inner" style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                        .writeText(item?.upi_id).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                        <i className="ri-file-copy-line text-gradient"></i>
                      </button></label>
                      <label for="bit" >Account type: {item?.account_type} <button type="button" className="shadow-soft_inner" style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                        .writeText(item?.upi_id).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                        <i className="ri-file-copy-line text-gradient"></i>
                      </button></label>
                      <label for="bit" >Account Holder Name: {item?.account_holder_name} <button type="button" className="shadow-soft_inner" style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                        .writeText(item?.upi_id).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                        <i className="ri-file-copy-line text-gradient"></i>
                      </button></label>
                      <label for="bit" >Account Number: {item?.account_number} <button type="button" className="shadow-soft_inner" style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                        .writeText(item?.upi_id).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                        <i className="ri-file-copy-line text-gradient"></i>
                      </button></label>
                      <label for="bit" >IFSC Code: {item?.ifsc_code} <button type="button" className="shadow-soft_inner" style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                        .writeText(item?.upi_id).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                        <i className="ri-file-copy-line text-gradient"></i>
                      </button></label>
                      <label for="bit" >Branch Address: {item?.branch_name} <button type="button" className="shadow-soft_inner" style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                        .writeText(item?.upi_id).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                        <i className="ri-file-copy-line text-gradient"></i>
                      </button></label> </>}
                                                      
                                                  </div>
                      //                     Object.entries(item)?.map((item) => {
                      //                         return (
                      //                             <div key={item} className="form-group mb-4">
                      //                                 <label for="bit" >UPI ID : {item?.upi_id} <button type="button" className="shadow-soft_inner" style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                      //   .writeText(item?.upi_id).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                      //   <i className="ri-file-copy-line text-gradient"></i>
                      // </button></label>
                                                      
                      //                             </div>
                      //                         )
                      //                     })
                                          ) 
                                    )
                                }) : sellOrders?.map((item, index) => {
                                  return (
                                      item?.payment_method.map(item => item.type == buyerSelectedPayment  &&
                                        <div key={item} className="form-group mb-4">
                                            {item.type == "UPI" ?
                                            <>
                                            <label for="bit" >UPI ID : {item?.upi_id} <button type="button" className="shadow-soft_inner" style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                        .writeText(item?.upi_id).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                        <i className="ri-file-copy-line text-gradient"></i>
                      </button></label>

                      <label for="bit" ><img src={ApiConfig?.baseUrl + item?.upi_image}/> </label> </> 
                      :
                       <>
                       <label for="bit" >Bank Name: {item?.bank_name} <button type="button" className="shadow-soft_inner" style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                        .writeText(item?.upi_id).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                        <i className="ri-file-copy-line text-gradient"></i>
                      </button></label>
                      <label for="bit" >Account type: {item?.account_type} <button type="button" className="shadow-soft_inner" style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                        .writeText(item?.upi_id).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                        <i className="ri-file-copy-line text-gradient"></i>
                      </button></label>
                      <label for="bit" >Account Holder Name: {item?.account_holder_name} <button type="button" className="shadow-soft_inner" style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                        .writeText(item?.upi_id).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                        <i className="ri-file-copy-line text-gradient"></i>
                      </button></label>
                      <label for="bit" >Account Number: {item?.account_number} <button type="button" className="shadow-soft_inner" style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                        .writeText(item?.upi_id).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                        <i className="ri-file-copy-line text-gradient"></i>
                      </button></label>
                      <label for="bit" >IFSC Code: {item?.ifsc_code} <button type="button" className="shadow-soft_inner" style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                        .writeText(item?.upi_id).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                        <i className="ri-file-copy-line text-gradient"></i>
                      </button></label>
                      <label for="bit" >Branch Address: {item?.branch_name} <button type="button" className="shadow-soft_inner" style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                        .writeText(item?.upi_id).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                        <i className="ri-file-copy-line text-gradient"></i>
                      </button></label> </>}
                                                      
                                                  </div>
                    //                     Object.entries(item)?.map((item) => {
                    //                         return (
                    //                             <div key={item} className="form-group mb-4">
                    //                                 <label for="bit" >UPI ID : {item?.upi_id} <button type="button" className="shadow-soft_inner" style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                    //   .writeText(item?.upi_id).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                    //   <i className="ri-file-copy-line text-gradient"></i>
                    // </button></label>
                                                    
                    //                             </div>
                    //                         )
                    //                     })
                                        ) 
                                  )
                              }) }
                              </form>
                             </div>

                        {/* <div className="modal-footer px-8">
                            {addType === 'ADD' ? <button type="button" className="  btn btn-gradient btn-medium justify-content-center w-100" onClick={() => addPaymentMethod()} ><span>Add {selectedPaymentMode}</span></button> : <button type="button" className="  btn btn-gradient btn-medium justify-content-center w-100" onClick={() => editPaymentMethod()} ><span>Edit {selectedPaymentMode}</span></button>}


                        </div> */}

                    </div>
                </div>
            </div>

    {/* Payment Methods Modal */}
      <div className="modal fade" id="payment_method_list" tabIndex="-1" aria-labelledby="payment_method_listLaebl" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '500px' }}>
                    <div className="modal-content p-2 pb-5" style={{ backgroundColor: 'rgb(34 36 38)' }}>
                        <div className="modal-header flex-column px-8">
                            <h3 className="modal-title" id="placeBitLaebl">Select Payment Method</h3>
                            <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i className="ri-close-fill"></i></button>
                        </div>
                        <div className="modal-footer p-1">
                          {(traderPaymentMethod?.UpiDetails.length == 0 && traderPaymentMethod?.BankDetails.length == 0 &&  currentTab == "BUY") ? <button type="button" onClick={()=> {$("#payment_method_list").modal('hide'); navigate("/profile/BankAccount")}}>Add Payment method</button> :
                          currentTab == "BUY" &&
                          <>
                           {traderPaymentMethod?.UpiDetails.length > 0 && traderPaymentMethod?.UpiDetails.map((item, index) =>{
                            return(
                              <>
                            <button key={index} type="button" className="btn btn-gradient btn-medium justify-content-between w-100 m-2" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setSellerSelectedPayment(item?.upi_id); console.log(item, "itemm") ; setSellerMethod(item)}}><span>{item?.type} ({item?.upi_id})</span> 
                          </button>
                              </>
                            )
                          })}
                           {traderPaymentMethod?.BankDetails.length > 0 && traderPaymentMethod?.BankDetails.map((item, index) =>{
                            return(
                              <>
                            <button key={index} type="button" className="btn btn-gradient btn-medium justify-content-between w-100 m-2" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setSellerSelectedPayment(item?.bank_name + " " + item?.type); console.log(item, "itemm") ; setSellerMethod(item)}}><span>{item?.type} ({item?.bank_name})</span> 
                          </button>
                              </>
                            )
                          })}
                          </>
                          // : 
                          //  <>
                          //  {traderPaymentMethod?.UpiDetails.length > 0 && traderPaymentMethod?.UpiDetails.map((item, index) =>{
                          //   return(
                          //     <>
                          //   <button key={index} type="button" className="btn btn-gradient btn-medium justify-content-between w-100 m-2" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setBuyerSelectedPayment( item?.upi_id); console.log(item, "itemm") ; setSellerMethod(item)}}><span>{item?.type} ({item?.upi_id})</span> 
                          // </button>
                          //     </>
                          //   )
                          // })}
                          //  {traderPaymentMethod?.BankDetails.length > 0 && traderPaymentMethod?.BankDetails.map((item, index) =>{
                          //   return(
                          //     <>
                          //   <button key={index} type="button" className="btn btn-gradient btn-medium justify-content-between w-100 m-2" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setBuyerSelectedPayment(item?.bank_name + " " + item?.type); console.log(item, "itemm") ; setBuyerMethod(item)}}><span>{item?.type} ({item?.bank_name})</span> 
                          // </button>
                          //     </>
                          //   )
                          // })}
                          // </> 
                          }

                          {currentTab == "SELL" &&   <div className="btn btn-gradient btn-medium justify-content-between w-100 m-2" data-bs-dismiss="modal" aria-label="Close" onClick={() => { 
                            // if(currentTab == "BUY"){
                            //   setSellerSelectedPayment("p2p");
                            //   setSellerMethod("p2p")
                            // } else{
                              setBuyerSelectedPayment("P2P_Wallet");
                              setBuyerMethod("P2P_Wallet")
                            // }
                          }
                          }
                            ><span>P2P Wallet</span></div>

                         }

                         
                         </div>
                    </div>
                </div>
            </div>




                           {/* {tradersPaymentMethod.bankDetails.length > 0 && tradersPaymentMethod.bankDetails.map((item, index) =>{ */}
                        {/* //     return(
                        //       <>
                        //     <button key={index} type="button" className="btn btn-gradient btn-medium justify-content-between w-100 m-2" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#payment_method_form" onClick={() => { setSellerSelectedPayment(item.type); console.log(item, "itemm") ; setSellerMethod(item)}}><span>{item?.type}</span> <i className="ri-add-box-fill "></i>
                        //   </button>
                        //       </>
                        //     )
                        //   }) : 
                        //  } */}
                        
                            {/* {currentTab == "BUY" ? sellerPaymentMethods?.map((item, index) => {
                                return (
                                  <>
                                    <button key={index} type="button" className="btn btn-gradient btn-medium justify-content-between w-100 m-2" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#payment_method_form" onClick={() => { setSellerSelectedPayment(item.type); console.log(item, "itemm") ; setSellerMethod(item)}}><span>{item?.type}</span> <i className="ri-add-box-fill "></i></button>       
                                    </>
                                )
                            }) : buyerPaymentMethods?.map((item, index) => {
                              return (
                                <>
                                  <button key={index} type="button" className="btn btn-gradient btn-medium justify-content-between w-100 m-2" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#payment_method_form" onClick={() => { setBuyerSelectedPayment(item.type); console.log(item, "itemm"); setBuyerMethod(item) }}><span>{item?.type}</span> <i className="ri-add-box-fill "></i></button>
                                  </>
                              )
                          }) } */}
                             {/* <div className="btn btn-gradient btn-medium justify-content-between w-100 m-2" data-bs-dismiss="modal" aria-label="Close" onClick={() => { currentTab == "BUY" ? setSellerSelectedPayment("p2p") : setBuyerSelectedPayment("p2p")}}><span>P2P Wallet</span></div> */}
                      

    </>
  );
};

export default PTwoP;
