import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import { alertErrorMessage, alertSuccessMessage } from "../../UtilityComponent/CustomAlertMessage";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import DefaultInput from "../../UtilityComponent/DefaultInput";
import { matchPassword, notEqualsZero, validPassword, validPasswordLength, validateEmail, validateNumber } from "../../UtilityComponent/Validation";
import { CountryList } from "../../UtilityComponent/CountryList";

const SignupPage = () => {
  const navigate = useNavigate();
  const ref = window.location.href.split("=")[1];
  const [signId, setSignId] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCpassword] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [invitation, setInvitation] = useState(ref);
  const [otp, setOtp] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedCondition, setIsCheckedCondition] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [reEnterPasswordType, setReEnterPasswordType] = useState("password");
  const [disableBtn, setDisbaleBtn] = useState(false);
  const [validations, setValidations] = useState(false);
  const [numberValidation, setNumberValidation] = useState(false);
  const [specialValidation, setSpecialValidation] = useState(false);
  const [minLength, setMinLength] = useState(false);

  const handleRegister = async (countryCode, signId, password, cPassword, otp, invitation) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.register(countryCode, signId, password, cPassword, otp, invitation).then(async (result) => {
      if (result?.success) {
        alertSuccessMessage('Registration Successfull!!')
        sessionStorage.setItem("token", result?.data?.token);
        sessionStorage.setItem("userId", result?.data?.userId);
        navigate("/profile/SettingsPage");
        window.location.reload()
      }
    });
  };

  useEffect(() => {
    password && /(?=.*?[0-9])/.test(password)
      ? setNumberValidation(true)
      : setNumberValidation(false);
  }, [password]);

  useEffect(() => {
    password && /(?=.*?[#?!@$%^&*-])/.test(password)
      ? setSpecialValidation(true)
      : setSpecialValidation(false);
  }, [password]);

  useEffect(() => {
    password && /.{6,}/.test(password)
      ? setMinLength(true)
      : setMinLength(false);
  }, [password]);

  const handleGetOtp = async (signId, resend) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getOtp(signId, resend).then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        alertSuccessMessage(result?.message);
        setDisbaleBtn(true);
      }
    });
  };

  const handleResetInput = () => {
    setDisbaleBtn(false)
    setSignId("");
    setPassword("");
    setCpassword("");
    setCountryCode("+91");
    setOtp("");
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      return setPasswordType("text");
    }
    return setPasswordType("password");
  };

  const toggleConfirmPassword = () => {
    if (reEnterPasswordType === "password") {
      return setReEnterPasswordType("text");
    }
    return setReEnterPasswordType("password");
  };

  return (
    <section className="inner-page-banner pb-0">
      <section className="">
        <div className="container">
          <div className="row align-items-center   ">
            <div className="col-xl-7 col-lg-12 ">
              <div className="poster_slider poster_slider_signup">
                <img alt="" src="/images/main_img.svg" className="img-fluid" />
              </div>
            </div>
            <div className="col-xl-5 col-lg-12 ">
              <div className="signup-wrapper p-0  shadow-soft">
                <div
                  className="signup-content signin-content"
                  onClick={(e) => { setValidations(false) }}>
                  <div className="sign-in_tab" id="qwerty">
                    <h3 className="mb-2">Register to Inoutbit</h3>
                    <div className="mb-3">
                      <ul className="nav custom-tabs my-1 custom-tabs_full  shadow-soft">
                        <li>
                          <a className="active" data-bs-toggle="tab" href="#login_Mobile" onClick={handleResetInput}>
                            Mobile
                          </a>
                        </li>
                        <li>
                          <a data-bs-toggle="tab" href="#login_email" className="" onClick={handleResetInput}>
                            Email
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content">
                      <div className="tab-pane show  px-0 container active" id="login_Mobile">
                        <div>
                          <div className="row">
                            <div className="col-12">
                              <div className="fleld-box">
                                <label htmlFor="Code" className="form-label">
                                  Mobile
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                              </div>
                            </div>
                            <div className="col-12 mb-3">
                              <div className="field-box ">
                                <select name="countryCode" value={countryCode} onChange={(e) => setCountryCode(e.target.value)}                                >
                                  <optgroup label="Other countries">
                                    <option value="+91">
                                      India (+91)
                                    </option>
                                    {CountryList.map(value => {
                                      return <option value={value} key={value}>
                                        {value}
                                      </option>
                                    })}
                                  </optgroup>
                                </select>
                              </div>
                            </div>
                            <div className="col-12 mb-3">
                              <div className="field-box field-otp-box">
                                <input id="mobile" name="mobile" type="number" placeholder="Enter mobile number"
                                  value={signId} onChange={(e) => setSignId(e.target.value)} onWheel={(e) => e.target.blur()} />
                                <button type="btn" className="btn btn-sm btn-gradient btn-gradient_inner" onClick={() => handleGetOtp(signId, disableBtn)}>
                                  <span>{disableBtn ? 'Resend OTP' : 'GET OTP '} </span>
                                </button>
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="field-box">
                                <label htmlFor="emailotp" className="form-label">
                                  Verification Code<span style={{ color: "red" }}>*</span>
                                </label>
                                <input id="emailotp" name="otp" type="number" placeholder="Enter verification code"
                                  value={otp} onChange={(e) => setOtp(e.target.value)} />
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="field-box mb-4">
                                <label htmlFor="password" className="form-label">
                                  Password<span style={{ color: "red" }}>*</span>
                                </label>
                                <div className="field-otp-box">
                                  <DefaultInput type={passwordType} name="password" value={password} placeholder="Enter password"
                                    autoComplete="off"
                                    errorStatus={validPassword(password) !== undefined &&
                                      notEqualsZero(password) && validPasswordLength(password)}
                                    errorMessage={!validations ? validPassword(password) && validPasswordLength(password) : ""}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onClick={(e) => e.stopPropagation()}
                                    onFocus={(e) => { setValidations(true) }} />
                                  <a  className="show_password opt_btn btn-sm" onClick={togglePassword}>
                                    {passwordType === "password" ? <i className="ri-eye-off-line" />
                                      : <i className="ri-eye-line" />}
                                  </a>
                                </div>
                                <div
                                  className={!validations ? "display-none" : "pass_conditions"}>
                                  <div>
                                    {validations &&
                                      (numberValidation ?
                                        <div className="d-flex align-items-center">
                                          <i className="ri-check-double-line text-success"></i>
                                          <span style={{ fontSize: "small" }}>
                                            At least 1 number
                                          </span>
                                        </div>
                                        :
                                        <div className="d-flex align-items-center">
                                          <i className="ri-close-fill text-danger"></i>
                                          <span style={{ fontSize: "small" }}>
                                            At least 1 number
                                          </span>
                                        </div>)}
                                  </div>

                                  <div>
                                    {validations &&
                                      (specialValidation ?
                                        <div className="d-flex align-items-center">
                                          <i className="ri-check-double-line text-success"></i>
                                          <span style={{ fontSize: "small" }}>
                                            At least 1 special character
                                          </span>
                                        </div>
                                        :
                                        <div className="d-flex align-items-center">
                                          <i className="ri-close-fill text-danger"></i>
                                          <span style={{ fontSize: "small" }}>
                                            At least 1 special character
                                          </span>
                                        </div>
                                      )}
                                  </div>

                                  <div>
                                    {validations &&
                                      (minLength ?
                                        <div className="d-flex align-items-center">
                                          <i className="ri-check-double-line text-success"></i>
                                          <span style={{ fontSize: "small" }}>
                                            Minimum 6 characters
                                          </span>
                                        </div>
                                        :
                                        <div className="d-flex align-items-center">
                                          <i className="ri-close-fill text-danger"></i>
                                          <span style={{ fontSize: "small" }}>
                                            Minimum 6 characters
                                          </span>
                                        </div>)}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <div className="field-box">
                                  <label htmlFor="cPassword" className="form-label">
                                    Confirm Password<span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="field-otp-box">
                                    <DefaultInput type={reEnterPasswordType} name="cPassword" value={cPassword} placeholder="Enter confirm password" autoComplete="off" errorStatus={notEqualsZero(cPassword) && matchPassword(password, cPassword)}
                                      errorMessage={matchPassword(password, cPassword)} onChange={(e) => setCpassword(e.target.value)} />
                                    <a  className="show_password opt_btn btn-sm " onClick={toggleConfirmPassword} >
                                      {reEnterPasswordType === "password" ? <i className="ri-eye-off-line" />
                                        : <i className="ri-eye-line" />}
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <div className="field-box">
                                  <label htmlFor="password" className="form-label" >
                                    Invitation code(Optional)
                                  </label>

                                  <input id="Invitation" name="mInvitation" type="text" placeholder="Enter invitation code" disabled={ref} value={invitation}
                                    onChange={(e) => setInvitation(e.target.value)} />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-check mb-3">
                                  <input className="form-check-input" type="checkbox" id="flexCheckDefault"
                                    value={isChecked} onClick={() => setIsChecked((isChecked) => !isChecked)} />
                                  I agree to accept
                                  <Link to="/TermsOfUsePage" className="btn-link" target="_blank" >
                                    {' '} Terms of Use <span style={{ color: "red" }}>*</span>
                                  </Link>
                                  {!isChecked && showMessage ? <div style={{ color: "red", fontWeight: "bold" }} >
                                    Please accept Term of Use by clicking on checkbox
                                  </div> : " "}
                                </div>
                              </div>
                              <div className="col-md-12 mb-3 mt-3">
                                <div className="field-box">
                                  <button
                                    className="btn btn-gradient w-100 justify-content-center btn-medium"
                                    type="button"
                                    disabled={!signId || !password || !cPassword || !otp || password !== cPassword ||
                                      !(validPassword(password) === undefined ||
                                        !notEqualsZero(password)) || !(validateNumber(signId) === undefined || !notEqualsZero(signId)
                                        ) || !(validPassword(cPassword) === undefined || !notEqualsZero(cPassword))}
                                    onClick={() => {
                                      isChecked ? handleRegister(countryCode, signId, password, cPassword, otp, invitation,)
                                        : setShowMessage((showMessage) => !showMessage)
                                    }}>
                                    <span>Register</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane  px-0   container" id="login_email" >
                        <div>
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <div className="field-box">
                                <label htmlFor="email" className="form-label">
                                  Email<span style={{ color: "red" }}>*</span>
                                </label>
                                <div className=" field-otp-box">
                                  <input id="email" name="email" type="email" placeholder="Enter email" value={signId} onChange={(e) => setSignId(e.target.value)} />
                                  <button type="btn" className="btn btn-sm btn-gradient btn-gradient_inner"
                                    onClick={() => {
                                      validateEmail(signId) !== undefined && notEqualsZero(signId) ? alertErrorMessage("Invalid emailId") : handleGetOtp(signId, disableBtn)
                                    }} >
                                    <span>{disableBtn ? 'Resend OTP' : 'GET OTP '}</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mb-4">
                              <div className="field-box">
                                <label htmlFor="emailotp" className="form-label">
                                  Verification Code<span style={{ color: "red" }}>*</span>
                                </label>
                                <input id="emailotp" name="otp" type="number" placeholder="Enter verification code" value={otp}
                                  onChange={(e) => setOtp(e.target.value)} />
                              </div>
                            </div>
                            <div className="col-md-12 mb-4">
                              <div className="field-box">
                                <label htmlFor="password" className="form-label">
                                  Password<span style={{ color: "red" }}>*</span>
                                </label>
                                <div className="field-otp-box">
                                  <DefaultInput type={passwordType} name="password" value={password} placeholder="Enter password"
                                    autoComplete="off" errorStatus={validPassword(password) !== undefined && notEqualsZero(password) &&
                                      validPasswordLength(password)}
                                    errorMessage={!validations ? validPassword(password) && validPasswordLength(password) : ""}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onClick={(e) => e.stopPropagation()}
                                    onFocus={(e) => { setValidations(true) }} />
                                  <a className="show_password opt_btn btn-sm" onClick={togglePassword}                                  >
                                    {passwordType === "password" ? (
                                      <i className="ri-eye-off-line" />
                                    ) : (
                                      <i className="ri-eye-line" />
                                    )}
                                  </a>
                                </div>
                                <div className={!validations ? "display-none" : "pass_conditions"} >
                                  <div>
                                    {validations &&
                                      (numberValidation ?
                                        <div className="d-flex align-items-center">
                                          <i className="ri-check-double-line text-success"></i>
                                          <span style={{ fontSize: "small" }}>
                                            At least 1 number
                                          </span>
                                        </div>
                                        :
                                        <div className="d-flex align-items-center">
                                          <i className="ri-close-fill text-danger"></i>
                                          <span style={{ fontSize: "small" }}>
                                            At least 1 number
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                  <div>
                                    {validations &&
                                      (specialValidation ?
                                        <div className="d-flex align-items-center">
                                          <i className="ri-check-double-line text-success"></i>
                                          <span style={{ fontSize: "small" }}>
                                            At least 1 special character
                                          </span>
                                        </div>
                                        :
                                        <div className="d-flex align-items-center">
                                          <i className="ri-close-fill text-danger"></i>
                                          <span style={{ fontSize: "small" }}>
                                            At least 1 special character
                                          </span>
                                        </div>
                                      )}
                                  </div>

                                  <div>
                                    {validations && (minLength ?
                                      <div className="d-flex align-items-center">
                                        <i className="ri-check-double-line text-success"></i>
                                        <span style={{ fontSize: "small" }}>
                                          Minimum 6 characters
                                        </span>
                                      </div>
                                      :
                                      <div className="d-flex align-items-center">
                                        <i className="ri-close-fill text-danger"></i>
                                        <span style={{ fontSize: "small" }}>
                                          Minimum 6 characters
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mb-4">
                              <div className="field-box">
                                <label htmlFor="cPassword" className="form-label">
                                  Confirm Password<span style={{ color: "red" }}>*</span>
                                </label>
                                <div className="field-otp-box">
                                  <DefaultInput type={reEnterPasswordType} name="cPassword" value={cPassword} placeholder="Enter confirm password" autoComplete="off" errorStatus={notEqualsZero(cPassword) &&
                                    matchPassword(password, cPassword)} errorMessage={matchPassword(password, cPassword)}
                                    onChange={(e) => setCpassword(e.target.value)} />
                                  <a className="show_password opt_btn btn-sm " onClick={toggleConfirmPassword}  >
                                    {reEnterPasswordType === "password" ? <i className="ri-eye-off-line" /> : <i className="ri-eye-line" />}
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mb-4">
                              <div className="field-box">
                                <label htmlFor="password" className="form-label">
                                  Invitation code(Optional)
                                </label>
                                <input id="Invitation" name="Invitation" type="text" placeholder="Enter invitation code" value={invitation}
                                  onChange={(e) => setInvitation(e.target.value)} />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-check mb-4">
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" value={isCheckedCondition}
                                  onClick={() => setIsCheckedCondition((isCheckedCondition) => !isCheckedCondition)} />
                                <label className="form-check-label" htmlFor="flexCheckDefault" >
                                  I agree to accept{" "}
                                  <Link to="/TermsOfUsePage" className="btn-link" target="_blank">
                                    Terms of Use
                                    <span style={{ color: "red" }}>*</span>
                                  </Link>
                                  {!isCheckedCondition ? (
                                    showMessage ? (
                                      <div style={{ color: "red", fontWeight: "bold", }}   >
                                        Please accept Term of Use by clicking on checkbox
                                      </div>
                                    ) : " "
                                  ) : " "
                                  }
                                </label>
                              </div>
                            </div>
                            <div className="col-md-12 mb-4 mt-4">
                              <div className="field-box">
                                <button
                                  className="btn btn-gradient w-100 justify-content-center btn-medium"
                                  type="button"
                                  disabled={!signId || !password || !cPassword || password !== cPassword || !(
                                    validPassword(password) === undefined ||
                                    !notEqualsZero(password)
                                  ) || !(validateEmail(signId) === undefined || !notEqualsZero(signId)) ||
                                    !(validPassword(cPassword) === undefined || !notEqualsZero(cPassword))}
                                  onClick={() => { isCheckedCondition ? handleRegister(countryCode, signId, password, cPassword, otp, invitation) : setShowMessage((showMessage) => !showMessage) }}>
                                  <span>Register</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-center text-center">
                        <div className="col-lg-12">
                          Already have an account?{" "}
                          <Link to="/login" className="color-primary">
                            Login
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default SignupPage;
