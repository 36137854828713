import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper"
import Header from "./Header";
import { alertErrorMessage, alertSuccessMessage } from "../../UtilityComponent/CustomAlertMessage";
import { $ } from "react-jquery-plugin";

const MyOrders = () => {
  const navigate = useNavigate();
  const [ordersList, setOrdersList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [coinList, setCoinList] = useState([]);
  const [fiatCurrency, setFiatCurrency] = useState([]);
  const [filterInp, setFilterInp] = useState({ crypto: 'ALL', fiat: 'ALL', status: 'ALL', date: '' });
  const [rating, setRating] = useState(0);

  const userId = sessionStorage.getItem("userId")

  const handleRatingChange = (newRating) => {
    console.log(newRating, "ratingsssss")
    setRating(newRating);
  };

  const p2pCoinList = async () => {
    await AuthService.p2pCoinList().then(async (result) => {
      if (result?.success) {
        setCoinList(result?.data)
      }
    });
  };

  const myOrders = async () => {
    LoaderHelper?.loaderStatus(true);
    await AuthService.myOrders().then(async (result) => {
      if (result?.success) {
        LoaderHelper?.loaderStatus(false);
        setOrdersList(result?.data?.PostedOrders.concat(result?.data?.PurchedOrder));
        //  setAllData(result?.data?.PostedOrders.concat(result?.data?.PurchedOrder));
      }
    });
  };

  const fiatCurrencyList = async () => {
    await AuthService.fiatCurrencyList().then(async (result) => {
      if (result?.success) {
        setFiatCurrency(result?.data)
      }
    });
  };

  const filterBuyOrders = () => {
    let filteredData = allData?.filter((item) => {
      const createdAtDate = new Date(item.createdAt);
      return ((item?.quote_short_name === filterInp?.fiat || filterInp?.fiat === "ALL") && (item?.base_short_name === filterInp?.crypto || filterInp?.crypto === "ALL") && (item?.status === filterInp?.status || filterInp?.status === "ALL") && (createdAtDate >= new Date(filterInp?.date) || filterInp?.date === ""))
    })
    setOrdersList(filteredData);
  };

  const handleInput = (e) => {
    switch (e.target.name) {
      case 'filterCrypto':
        setFilterInp(data => ({ ...data, crypto: e.target.value }));
        break;
      case 'filterFiat':
        setFilterInp(data => ({ ...data, fiat: e.target.value }))
        break;
      case 'filterStatus':
        setFilterInp(data => ({ ...data, status: e.target.value }))
        break;
      case 'filterDate':
        setFilterInp(data => ({ ...data, date: e.target.value }))
        break;

      default:
        break;
    }
  };

  const resetInput = () => {
    setFilterInp({ crypto: 'ALL', fiat: 'ALL', status: 'ALL', date: '' })
  };

  const buyP2pPage = (id) => {
    navigate(`/buyPTwop_details/${id}`)
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    p2pCoinList()
    myOrders()
    fiatCurrencyList()
  }, []);

  useEffect(() => {
    filterBuyOrders();
  }, [filterInp]);


  const [updatedOrders, setUpdatedOrders] = useState([]);
  const calculateReverseTime = (item) => {
    const timestampInSeconds = parseInt(item.payment_timestamp, 10);
    const paymentTimeInMinutes = parseInt(item.payment_time, 10);
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const elapsedTimeInSeconds = currentTimeInSeconds - timestampInSeconds;
    const remainingTimeInSeconds = Math.max(paymentTimeInMinutes * 60 - elapsedTimeInSeconds, 0);
    const minutes = Math.floor(remainingTimeInSeconds / 60);
    const seconds = remainingTimeInSeconds % 60;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    return { formattedMinutes, formattedSeconds };
  };

  const updateReverseTime = () => {
    const updatedOrdersList = ordersList.map((item) => {
      const { formattedMinutes, formattedSeconds } = calculateReverseTime(item);
      return { ...item, reverseTime: { formattedMinutes, formattedSeconds } };
    });
    setUpdatedOrders(updatedOrdersList?.reverse());
  };

  useEffect(() => {
    updateReverseTime();
    const intervalId = setInterval(updateReverseTime, 1000);
    return () => clearInterval(intervalId);
  }, [ordersList]);

  const handleRunTime = async(status, orderId) =>{
    LoaderHelper.loaderStatus(true);
    const result = await AuthService.runtime(status, orderId)
    if(result.success){
    LoaderHelper.loaderStatus(false);
      if(status == "DISPUTE"){
        alertErrorMessage("Your order is in dispute, please raise a ticket.")
        navigate("/profile/Support")
      } else{
        $("#rating_modal").modal('show')
        navigate("/p2p_trading")
      }
      //  alertErrorMessage("Transaction does not completed on time due to which order is in dispute")
    } else{
    LoaderHelper.loaderStatus(false);
      alertErrorMessage(result.message)
    }
   }

   const rateHandler = async(rating) =>{
    const result = await AuthService.rateOrder(rating)
    if(result.success){
      alertSuccessMessage(result.message)
      navigate("/p2p_trading")
    } else{
      alertErrorMessage(result.message)
    }
    }


  const Statement = updatedOrders.filter(item=> item?.status == "CONFIRMED")

  


  return (
    <>
      <Header />

      <div className="pp_filter_bar" >
        <div className="container" >
          <div className="card-filter" >
            <div className="card-body-top" >
              <div className="body_left body_right_w_filter" >
                <ul className="nav nav-pills  mrt_row" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link  subtabs_link   text-white active" id="pills-Orders-tab" data-bs-toggle="pill" data-bs-target="#pills-Orders"
                      type="button" role="tab" aria-controls="pills-Orders" aria-selected="true">P2P Orders</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link subtabs_link text-white" id="pills-Statement-tab" data-bs-toggle="pill"
                      data-bs-target="#pills-Statement" type="button" role="tab" aria-controls="pills-Statement" aria-selected="false">Statement</button>
                  </li>
                </ul>
                <div className="mob_filter d-md-none shadow-soft" >
                  <i className="ri-filter-2-fill"></i>
                </div>
              </div>
            </div>
            {/* <div className="custom_drop_modal" >
              <div className="card-body-bottom" >
                <div className="body_left" >
                  <div className="row gx-1 form_filter" >
                    <div className="col" >
                      <div className="form-group" >
                        <label> Crypto </label>
                        <div className="froup_feild mt-1" >
                          <select className="no-border w-100 ps-3" onChange={handleInput} name='filterCrypto' value={filterInp?.crypto}>
                            <option value='ALL'>All</option>
                            {coinList?.map((item, index) => {
                              return (
                                <option key={index} value={item?.short_name}>{item?.short_name}</option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col" >
                      <div className="form-group" >
                        <label>Order Type</label>
                        <div className="froup_feild mt-1" >
                          <select className="no-border w-100 ps-3" onChange={handleInput} name='filterFiat' value={filterInp?.fiat}>
                            <option value='ALL'>All</option>
                            {fiatCurrency?.map((item, index) => {
                              return (
                                <option key={index} value={item?.short_name}>{item?.short_name}</option>
                              )
                            })}

                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col" >
                      <div className="form-group" >
                        <label>Status</label>
                        <div className="froup_feild mt-1" >
                          <select className="no-border w-100 ps-3" onChange={handleInput} name='filterStatus' value={filterInp?.status} >
                            <option value='ALL'>All</option>
                            <option value='SUCCESS'>Success</option>
                            <option value='FAILED'>Failed</option>
                            <option value='IN PROGRESS'>Pending</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col" >
                      <div className="form-group" >
                        <label>From Date</label>
                        <div className="froup_feild mt-1" >
                          <input className="feild_input" type="date" name="filterDate" onChange={handleInput} value={filterInp?.date} />
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div className="body_right" >
                  <button className="btn btn-gradient  mt-md-3" type="button" onClick={resetInput}>
                    <span> Clear Filter </span>
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="pp_data cst-table" >
        <div className="container" >

          <div className="tab-content" id="myTabContent">

            {/* buy data tab */}
            <div className="tab-pane fade show active" id="pills-Orders" role="tabpanel" aria-labelledby="ills-Orders-tab-tab">
              <div className="cst-table-content shadow-soft table-responsive ">
                <table className="table table-striped " >
                  <thead className="cst-table-thead">
                    <tr>
                      <th className=" ">Type</th>
                      {/* <th className=" ">Currency</th> */}
                      <th className=" ">Crypto Amount</th>
                      <th className=" ">Receiving Amount</th>
                      <th className=" ">Payment Method</th>
                      <th className=" ">Status </th>
                      <th className=" ">Action </th>
                      {/* <th className=" ">Time Left </th> */}
                    </tr>
                  </thead>
                  {updatedOrders?.length > 0 ? updatedOrders?.map((item) => {
                    return (
                      <tbody className="cst-table-tbody active_order" key={item?._id} onClick={() => { if(item?.status !== "CONFIRMED" && item?.status !== "DISPUTE") {
                        buyP2pPage(item?.order_id)
                      } }}>
                        <tr className=" ">
                          <td className=" ">
                            <div className="advertiseList_avatarContent__uAQHP">
                              <div className="advertiseList_adAvatarRight__SbOZP"><span className={`advertiseList_nickName__VI9tJ text-${item?.side === 'BUY' ? 'danger' : 'success'}`}>  {item?.side === 'BUY' ? 'SELL' : 'BUY'}</span>
                                <p className="advertiseList_merchantOrder__aO2o_"> Date:  <Moment format="DD:MM:YY hh:mm A">{item?.createdAt}</Moment> </p>
                                <div className="advertiseList_firstTrade__Z5OC5">
                                  <p className="advertiseList_merchantOrder__aO2o_">
                                    <span className="text-white" >
                                      {item?.order_id}
                                    </span>
                                    <span className="advertiseList_tradingLine__swVrO"></span>
                                    <span className="text-white">
                                    <button type="button" className="shadow-soft_inner" style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                        .writeText(item?.order_id).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                        <i className="ri-file-copy-line text-gradient"></i>
                      </button>
                                      {/* <i className="ri-file-copy-line"></i>  */}
                                      </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>
                          {/* <td className=" ">
                            {item?.base_short_name}
                          </td> */}
                          <td className=" ">
                            {item?.amount} {item?.base_short_name}
                          </td>
                          <td className=" ">
                            {item?.receiving_amount} {item?.quote_short_name}
                          </td>
                          <td className=" ">
                            {item?.payment_method[0]?.type}
                          </td>
                          <td className="  text-danger">
                            {item?.status}
                          </td>
                          <td className="text-danger">
                            <button type="button" disabled = {item?.status == "DISPUTE" || item?.status == "CONFIRMED"} onClick={()=>handleRunTime("CONFIRMED", item?.order_id)}>{(item?.side == "SELL" && item?.postAd_user == userId )? "I have recieved the payment " : (item?.side == "SELL" && item?.trader_id == userId )? "I have recieved the funds " : (item?.side == "BUY" && item?.trader_id == userId )? "I have recieved the payment" :(item?.side == "BUY" && item?.postAd_user == userId )?"I have recieved the funds " : null}</button>
                            <button type="button" disabled = {item?.status == "DISPUTE" || item?.status == "CONFIRMED"} onClick={()=>handleRunTime("DISPUTE", item?.order_id)}>Raise Dispute</button>
                          </td>
                          {/* <td className=" ">
                            {item?.reverseTime?.formattedMinutes}:{item?.reverseTime?.formattedSeconds}
                          </td> */}
                        </tr>
                      </tbody>
                    )
                  }) :  <p className="text-center p-3 pb-4 no-data h-100 mb-0" >
                  <img src="/images/no-data.png" className='img-fluid ' alt="no data" width="52" />
                  No Data Available
                </p>}

                </table>
              </div>
            </div>

            {/* sell data tab */}
            <div className="tab-pane fade" id="pills-Statement" role="tabpanel" aria-labelledby="pills-Statement-tab">
              <div className="cst-table-content shadow-soft table-responsive ">
              <table className="table table-striped " >
                  <thead className="cst-table-thead">
                    <tr>
                      <th className=" ">Type</th>
                      {/* <th className=" ">Currency</th> */}
                      <th className=" ">Crypto Amount</th>
                      <th className=" ">Receiving Amount</th>
                      <th className=" ">Payment Method</th>
                      <th className=" ">Status </th>
                    
                      {/* <th className=" ">Time Left </th> */}
                    </tr>
                  </thead>
                  {Statement?.length > 0 ? Statement?.map((item) => {
                    return (
                      <tbody className="cst-table-tbody active_order" key={item?._id} onClick={()=>buyP2pPage(item?.order_id)}>
                        <tr className=" ">
                          <td className=" ">
                            <div className="advertiseList_avatarContent__uAQHP">
                              <div className="advertiseList_adAvatarRight__SbOZP"><span className={`advertiseList_nickName__VI9tJ text-${item?.side === 'BUY' ? 'danger' : 'success'}`}>  {item?.side === 'BUY' ? 'SELL' : 'BUY'}</span>
                                <p className="advertiseList_merchantOrder__aO2o_"> Date:  <Moment format="DD:MM:YY hh:mm A">{item?.createdAt}</Moment> </p>
                                <div className="advertiseList_firstTrade__Z5OC5">
                                  <p className="advertiseList_merchantOrder__aO2o_">
                                    <span className="text-white" >
                                      {item?.order_id}
                                    </span>
                                    <span className="advertiseList_tradingLine__swVrO"></span>
                                    <span className="text-white"><button type="button" className="shadow-soft_inner" style={{ cursor: "pointer" }} value="copy" onClick={() => navigator.clipboard
                        .writeText(item?.order_id).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })}>
                        <i className="ri-file-copy-line text-gradient"></i>
                      </button></span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>
                          {/* <td className=" ">
                            {item?.base_short_name}
                          </td> */}
                          <td className=" ">
                            {item?.amount} {item?.base_short_name}
                          </td>
                          <td className=" ">
                            {item?.receiving_amount} {item?.quote_short_name}
                          </td>
                          <td className=" ">
                            {item?.payment_method[0]?.type}
                          </td>
                          <td className="  text-danger">
                            {item?.status}
                          </td>
                         
                          {/* <td className=" ">
                            {item?.reverseTime?.formattedMinutes}:{item?.reverseTime?.formattedSeconds}
                          </td> */}
                        </tr>
                      </tbody>
                    )
                  }) :  <p className="text-center p-3 pb-4 no-data h-100 mb-0" >
                  <img src="/images/no-data.png" className='img-fluid ' alt="no data" width="52" />
                  No Data Available
                </p>}

                </table>
              </div>

            </div>
          </div>

        </div>
      </div>





      {/* Trading Requirement Modal */}
      <div className="modal fade modal_requirements" id="t_requirements" tabIndex="-1" role="dialog" aria-labelledby="t_requirementsLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column ">
              <h3 className="modal-title" id="placeBitLaebl"> Trading Requirement </h3>
              {/* <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i
                                className="ri-close-fill"></i></button> */}
            </div>
            <div className="modal-body p-0">

              <div className="css-ag510y">
                <div className="css-joa6mv">
                  <div className="css-1c6ljq6">
                    <div className="css-vurnku">Complete KYC Verification</div>
                    <div className="css-vurnku">
                      <button data-bn-type="button" className="btn btn-sm btn-warning css-stohmr ">Verify</button>
                    </div>
                  </div>
                  <div className="css-1c6ljq6">
                    <div className="css-vurnku">Enable SMS authentication</div>
                    <div className="css-vurnku">
                      <div className="css-9fayl6">Done
                        <i className="ri-checkbox-circle-fill ms-1"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="css-1bwopy1 row gx-1">
                  <div className="col-6 col-md-4" >
                    <button type="button" className="btn text-white shadow-soft btn-block w-100" data-bs-dismiss="modal"><span>Cancel</span></button>
                  </div>
                  <div className="col-6  col-md-8" >
                    <a href="/buyPTwop_details" className="btn btn-gradient btn-block w-100  "  > <span>Get Verified</span> </a>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>


      {/* Rating Modal */}
      <div className="modal fade modal_requirements" id="rating_modal" tabIndex="-1" role="dialog" aria-labelledby="rating_modalLaebl" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '500px' }}>
                    <div className="modal-content p-2 pb-5" style={{ backgroundColor: 'rgb(34 36 38)',display:"flex", alignItems: "center" }}>
                        <div className="modal-header flex-column px-8" style={{borderBottom: "none"}}>
                            <h3 className="modal-title" id="placeBitLaebl">Please Rate the order experience</h3>
                            {/* <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">OK</button> */}
                        </div>
                        <div>
                        {[1, 2, 3, 4, 5].map((star) => (
        <span
          key={star}
          style={{ cursor: 'pointer', color: star <= rating ? 'gold' : 'gray'}}
          onClick={() => handleRatingChange(star)}
         
        >
          ★
        </span>
      ))}
      </div>

<p>You rated: {rating}</p>
<button type="button" className="btn btn-gradient" onClick={()=>rateHandler(rating)}>Submit</button>
                                      

                    </div>
                </div>
            </div>

    </>
  );
};

export default MyOrders;
