import React, { useState, useEffect } from "react";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import { alertErrorMessage } from "../../UtilityComponent/CustomAlertMessage";
import Moment from "react-moment";
import { Link } from "react-router-dom";

const ReferralList = () => {
  const [historyData, setHistoryData] = useState([]);

  useEffect(() => {
    // getRewardsList();
  }, []);

  const getRewardsList = async () => {
    await AuthService.rewardsList().then(async (result) => {
      if (result?.success) {
        try {
          setHistoryData(result?.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result?.message);
      }
    });
  };

  return (
    <>
      <section className="inner-page-banner">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title text-gradient" data-bs-toggle="modal" data-bs-target="#popup_success">
              Referal List
            </h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <Link to="/ReferralPage">Referral Program</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Referral List
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container">
          <div className="d-flex-between mb-5">
            <ul className="nav custom-tabs">
              <li><a className=" active" data-bs-toggle="tab" href="#funds">Reward History</a></li>
              <li><a data-bs-toggle="tab" href="#tt_history"> Referred Friends</a> </li>
            </ul>
          </div>
          <div className="tab-content custom-tab-content p-0">
            <div className="tab-pane fade show container active form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="funds">
              <div className="table-responsive">
                <table className="table ">
                  <thead style={{ backgroundColor: "#252f30" }}>
                    <tr>
                      {/* <th>Sr No.</th>
                      <th>ASSETS</th>
                      <th>Available Balance</th>
                      <th>Locked</th>
                      <th>Total</th>
                      <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {historyData.length > 0 ? (
                      historyData.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item?.short_name}</td>
                          <td>{item?.balance.toFixed(8)}</td>
                          <td>{item?.locked_balance.toFixed(8)}</td>
                          <td>
                            {parseFloat(
                              item?.balance + item?.locked_balance
                            ).toFixed(8)}
                          </td>
                          <td>
                            {item.short_name === 'LTC' ?
                              <a href="#/" className=" tb_btn badge badge-success mx-1" onClick={() => { alertErrorMessage('Depost is not available on this Chain') }}>
                                Deposit
                              </a> : <a href="#/" className=" tb_btn badge badge-success mx-1" data-bs-toggle="modal"
                                data-bs-target="#Deposit_modal" >
                                Deposit
                              </a>}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr rowSpan="5">
                        <td colSpan="12">
                          <p className="text-center" style={{ textAlign: "center" }}>
                            No Data Available
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

              </div>
            </div>
            <div className="tab-pane container fade form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="tt_history">
              <div className="table-responsive">
                <table className="table ">
                  <thead style={{ backgroundColor: "#252f30" }} >
                    <tr>
                      {/* <th>Sr No.</th>
                      <th>Date/Time</th>
                      <th>Pair</th>
                      <th>Amount</th>
                      <th>Quantity</th>
                      <th>TDS Amount</th>
                      <th>Transaction Type</th>
                      <th>Status </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {historyData.length > 0 ?
                      historyData.map((item, index) => (
                        <tr key={index} className="cursor_pointer" data-bs-toggle="modal" data-bs-target="#transfer_history">
                          <td className="color-grey">{index + 1}</td>
                          <td className="color-grey">
                            {<Moment date={item?.createdAt} format="DD/MM/ YYYY hh:mm" />}
                          </td>
                          <td className="color-grey">{item?.coin}</td>
                          <td className="color-grey">
                            {parseFloat(item?.amount).toFixed(5)}
                          </td>
                          <td className="color-grey">{item?.quantity}</td>
                          <td className="color-grey">{!item?.tdsAmount ? "0" : parseFloat(item?.tdsAmount).toFixed(5)}</td>
                          <td className="color-grey">{item?.transType}</td>
                          <td>
                            <span className={`${item?.status === "Completed" ? "badge badge-success" : item?.status === "Cancel"
                              ? "badge badge-danger" : item?.status === "Cancelled" ? "badge badge-danger" : item?.status === "Pending"
                                ? "badge badge-warning" : ""}`}>
                              {item?.status}
                            </span>
                          </td>
                        </tr>
                      ))
                      :
                      <tr rowSpan="5">
                        <td colSpan="12">
                          <p style={{ textAlign: "center" }}>
                            No Data Available
                          </p>
                        </td>
                      </tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReferralList;
