import moment from 'moment';
import React from 'react';
import { ApiConfig } from '../../Api_Module/Api_Config/ApiEndpoints';

const Message = ({message,currentfriend,scrollRef,typingMessage}) => {
const userId = sessionStorage.getItem("userId")


  return (
       <>
      <div className="chat_body scroll_y" >
      <div className="msg_history scroll_bar">
            {
                message && message.length > 0 ? message.map((m, index) => 
                    m.senderId == userId ?
                    <div ref={scrollRef} className='outgoing_msg'>
                    <div className="sent_msg" >
                         
 <p> {m?.message?.text == '' ? <img src={ ApiConfig?.baseUrl + m?.message?.image} />  : m?.message?.text } </p>

     {/* {
          index === message.length -1 && m.senderId === userId ? m.status === 'seen' ?  <img className='img avatar shadow-soft' src={ApiConfig.baseUrl + currentfriend?.profilepicture} alt='' /> : m.status === 'delivared' ? <span> <FaRegCheckCircle /> </span> : <span> <FaRegCheckCircle /> </span> : ''
     }
  */}

                         
                    </div>
                    <div>
        {moment(m.createdAt).startOf('mini').fromNow() }                
                    </div>
                 </div> 
                 : 

                 <div ref={scrollRef} className='incoming_msg'>
                  <div className="incoming_msg_img avatar shadow-soft "><span>
                   <img  src={ApiConfig.baseUrl + currentfriend?.profilepicture} alt='' />
                   </span></div>
                   <div className='received_msg'>
                        <div className='received_withd_msg'>
                        
               <p> {m?.message?.text === '' ? <img src={(ApiConfig.baseUrl +  m?.message?.image)} />  : m?.message?.text }</p>
                        </div>
                        <div className='time'>
              {moment(m.createdAt).startOf('mini').fromNow() }             
                        </div>
                        </div>
                        
                   </div>
                    ) : <div className='incoming_msg'>
              <img className='avatar shadow-soft' src={ApiConfig.baseUrl + currentfriend?.profilepicture} alt='' />
              <h3>{currentfriend?.firstName} Connect You </h3>
              {/* <span> {moment(currentfriend?.createdAt).startOf('mini').fromNow() } </span> */}
                          </div>
            }
 

       </div>
       </div>
       {
            typingMessage && typingMessage.msg && typingMessage.senderId === currentfriend._id ? <div className='typing-message'>
            <div className='fd-message'>
                        <div className='image-message-time'>
                        <img className='avatar shadow-soft' src={ApiConfig.baseUrl + currentfriend?.profilepicture} alt='' />
                        <div className='message-time'>
                             <div className='fd-text'>
                    <p className='time'>Typing Message.... </p>
                             </div>
                             
                        </div>
                        </div>
                   </div>
     
            </div> : ''
       }

       

       </>
  )
};

export default Message;

