import React, { useEffect } from "react";

const Career = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}, []);
  return (
    <>
      <section className="inner-page-banner ">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title"> Career  </h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Career
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="pb-90">
        <div className="container">
          <div className="create-item-wrapper plicy_sec career_sec" >
            <div className="row align-items-center">
              <div className="col-lg-6 ">
                <div>
                  <h2 className="title text-white mb-3"> Career  With Us </h2>
                  <p>
                  Do you want to work with us? If yes, then send us your CV at <a className="text-gradient" href="mailto:career@Inoutbit.io">career@Inoutbit.io</a> .We will be happy to welcome you in Inoutbit family. All the best!
                  </p>
                  <p>
                  Inoutbit exchange offers you an exciting opportunity to work with cutting edge technology in a rapidly evolving industry, with potential and significant growth and impactful roles.
                  </p>
                  <a href="mailto:career@Inoutbit.io" className="btn btn-gradient" > Join us   </a>
                </div>
              </div>
              <div className="col-lg-6" >
                <img src="/images/career_img.svg" className="img-fluid career_img show_web_dark" />
                <img src="/images/career_img_light.svg" className="img-fluid career_img show_web_light" />
              </div>

            </div>
          </div>
          
        </div>
      </section>
    </>
  );
};

export default Career;
