import React, { useContext, useEffect, useState } from "react"
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../UtilityComponent/CustomAlertMessage";
import { ProfileContext } from "../../Context";
import { ApiConfig } from "../../Api_Module/Api_Config/ApiEndpoints";
import { $ } from "react-jquery-plugin";

const SettingsPage = () => {
  const [localImage, setlocalImage] = useState();
  const [profileImage, setprofileImage] = useState();
  const [otp, setOtp] = useState('');

  // ********* Context ********** //
  const { firstName, setFirstName, email, setEmail, lastName, setLastName, setMobile, mobile, userImage, handleUserDetials, registerdBy } = useContext(ProfileContext);

  // ********* Update User Details ********** //
  const updateUserDetails = async (myfile, firstName, lastName, emailId, mobile, otp) => {
    var formData = new FormData();
    formData.append("profilepicture", myfile);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("emailId", emailId);
    formData.append("mobileNumber", mobile);
    formData.append("verification_code", otp);
    LoaderHelper.loaderStatus(true);
    await AuthService.updateSettings(formData).then(async (result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        try {
          alertSuccessMessage(result?.message);
          sessionStorage.setItem("name", result?.data?.firstName + result?.data?.lastName )
          $("#otp").modal('hide');
          handleUserDetials();
          setOtp('');
          window.scrollTo({ top: 100, behavior: 'smooth' });
        } catch {
          alertErrorMessage(result?.message);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result?.message);
      }
    });
  };


  // ********* Handle Image Change ********** //
  const handleImageChange = (e) => {
    const fileUploaded = e.target.files[0];
    if (fileUploaded) {
      setlocalImage(URL.createObjectURL(fileUploaded));
      setprofileImage(fileUploaded)
    }
    else {
      setlocalImage();
      setprofileImage()
    }
  };

  // ********* Scroll Upside ********** //
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleGetOtp = async (signId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getOtp(signId).then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        try {
          alertSuccessMessage(result?.message);
          $("#otp").modal('show');
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result?.message);
      }
    });
  };


  return (
    <>
      <div className="tab-pane" id="SecurityPill" role="tabpanel" aria-labelledby="Security-pill">
      <div className="upload-formate mb-16 d-flex justify-content-center align-items-center"></div>
        <div className="row">
          <div className="col-md-12 col-lg-8 m-auto">
            <div className="form-field-wrapper switch_btn  border-dashed border-gray-300 bg-lighten card-rounded p-4 shadow-soft">
              <div className="row">
                <div className="col-md-12">
                  <div className="avatar-upload">
                    <div className="avatar-edit ">
                      <input type="file" id="imageUpload" name="myfile" onChange={handleImageChange} />
                      <label htmlFor="imageUpload">
                        <i className="ri-camera-line"></i>
                      </label>
                    </div>
                    <div className="avatar-preview shadow-soft">
                      <img alt="user" src={!userImage && !localImage ? "/images/profilelogo.png" : userImage && !localImage ? `${ApiConfig.baseUrl + userImage}` : localImage} />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-4">
                  <div className="field-box">
                    <label htmlFor="text" className="form-label">
                      First Name
                    </label>
                    <input type="text" name="firstName" placeholder="Enter Your First Name" value={firstName} onChange={(e) => { setFirstName(e.target.value) }} />
                  </div>
                </div>
                <div className="col-md-12 mb-4">
                  <div className="field-box">
                    <label htmlFor="text" className="form-label">
                      Last Name
                    </label>
                    <input type="text" name="lastName" placeholder="Enter Last Name" value={lastName} onChange={(e) => { setLastName(e.target.value) }} />
                  </div>
                </div>
                <div className="col-md-12 mb-4">
                  <div className="field-box">
                    <label htmlFor="text" className="form-label">
                      Email
                    </label>
                    <div className=" field-otp-box" >
                      <input type="email" name="Email" placeholder="Enter Email" value={email} disabled={registerdBy === 'email'} onChange={(e) => { registerdBy !== 'email' && setEmail(e.target.value) }}
                      />
                      {registerdBy !== 'email' &&
                        <button type="button" className="btn btn-sm btn-gradient btn-gradient_inner" onClick={() => handleGetOtp(email)} ><span>GET OTP</span></button>}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-4">
                  <div className="field-box">
                    <label htmlFor="text" className="form-label">
                      Mobile Number
                    </label>
                    <div className=" field-otp-box" >
                      <input type="number" name="Mobile" placeholder="Enter Mobile Number" disabled={registerdBy === 'phone'} value={mobile} onChange={(e) => { registerdBy !== 'phone' && setMobile(e.target.value) }} onWheel={(e) => { e.target.blur() }} />
                      {registerdBy !== 'phone' &&
                        <button type="button" className="btn btn-sm btn-gradient btn-gradient_inner" onClick={() => handleGetOtp(mobile)} ><span>GET OTP</span></button>}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-4 mt-4">
                  <div className="field-box">
                    <button className="btn btn-gradient w-100 justify-content-center btn-medium" type="button"
                      onClick={() => updateUserDetails(profileImage, firstName, lastName, email, mobile)} >
                      <span>Submit</span>
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="otp" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">{registerdBy === 'phone' ? 'Verify Email OTP' : 'Verify Mobile OTP'}</h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i
                className="ri-close-fill"></i></button>
            </div>
            <div className="modal-body conin_purchase">
              <div className="step_2 " >
                <div className="col-md-8 m-auto mb-5 text-center" >
                  <div className="pt-5" >
                    <input type="number" className="mb-3" placeholder="Enter OTP" onWheel={(e) => e.target.blur()} value={otp} onChange={(e) => { setOtp(e.target.value) }} />
                  </div>
                  <button type="button" className="next_btn btn-gradient m-auto w-100 btn btn-block" onClick={() => updateUserDetails(profileImage, firstName, lastName, email, mobile, otp)}> <span>Submit</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
