import React, { useContext, useEffect, useState } from "react";
import { Link ,Outlet,useNavigate} from "react-router-dom";
import { ProfileContext } from "../../Context";
import { ApiConfig } from "../../Api_Module/Api_Config/ApiEndpoints";

const AccountPage = () => {
  const [activeTab, setActiveTab] = useState("SettingsPage");
  const navigate = useNavigate();

  // ********* Context ********** //
  const { firstName, email, lastName, userImage, kycStatus, handleUserDetials } = useContext(ProfileContext);

  useEffect(() =>{
      handleUserDetials();
  }, []);

  useEffect(() => {
    let URL = window.location.href?.split('/');
    let route = URL.pop();
    if (URL?.length < 4 || !route) {
      navigate('/profile/SettingsPage')
    }else{
      setActiveTab(route)
    }
  }, []);

  return (
    <>
      <section className="inner-page-banner">
        <div className="container">
          <div className="inner text-center">

            <h1 className="title">Account Settings</h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <Link to={activeTab === 'SettingsPage'? '/':"SettingsPage"} onClick={() => setActiveTab('SettingsPage')} >Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Account Settings
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="pb-90 account_sec ">
        <div className=" container-custom">
          <form>
            <div className="create-item-wrapper my_acc ">
              <div className="row">
                <div className="col-lg-3 col-md-12 col-md-4">
                  <div className=" d-flex align-items-center ">
                    <img className="img-account-profile rounded-circle me-3" alt="UserImage"
                      src={userImage ? `${ApiConfig.baseUrl + userImage}` : "/images/profilelogo.png"}
                    />
                    <div>
                      <h5 className="fw-bolder pp-name fs-4 mb-0">
                        {`${firstName + ' ' + lastName}`}
                        <small className="text-success ms-2">
                          {kycStatus === 2 &&
                            <i className="ri-checkbox-circle-line"></i>
                          }
                        </small>
                      </h5>
                      <div className=" text-gray">
                        <small>{email && email}</small>
                      </div>
                    </div>
                  </div>
                  <div className="acc_tabs form-field-wrapper mt-5 mb-5 shadow-soft">
                    <ul className="nav nav-pills flex-column" id="cardTab" role="tablist">
                    <Link to="SettingsPage"  onClick={() => setActiveTab('SettingsPage')} className={`menu-link nav-link ${activeTab === 'SettingsPage' ? 'active' : ' '}`}>
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span className="menu-title"> My Profile </span>
                        </Link>
                   
                    <Link to="TwofactorPage"  onClick={() => setActiveTab('TwofactorPage')} className={`menu-link nav-link ${activeTab === 'TwofactorPage' ? 'active' : ' '}`}>
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span className="menu-title"> Two Factor Authentication </span>
                        </Link>
                    <Link to="/KycPage"  className='menu-link nav-link'>
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span className="menu-title"> KYC Verification</span>
                        </Link>
 
                        <Link to="ActivitylogPage"  onClick={() => setActiveTab('ActivitylogPage')} className={`menu-link nav-link ${activeTab === 'ActivitylogPage' ? 'active' : ' '}`}>
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span className="menu-title">  Activity Logs</span>
                        </Link>
                        <Link to="CurrencyPrefrence"  onClick={() => setActiveTab('CurrencyPrefrence')} className={`menu-link nav-link ${activeTab === 'CurrencyPrefrence' ? 'active' : ' '}`}>
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span className="menu-title">  Currency Preference</span>
                        </Link>
                        <Link to="SecurityPage"  onClick={() => setActiveTab('SecurityPage')} className={`menu-link nav-link ${activeTab === 'SecurityPage' ? 'active' : ' '}`}>
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span className="menu-title">Security</span>
                        </Link>
                        <Link to="NotificationPage"  onClick={() => setActiveTab('NotificationPage')} className={`menu-link nav-link ${activeTab === 'NotificationPage' ? 'active' : ' '}`}>
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span className="menu-title">Notification</span>
                        </Link>
                        <Link to="BankAccount"  onClick={() => setActiveTab('BankAccount')} className={`menu-link nav-link ${activeTab === 'BankAccount' ? 'active' : ' '}`}>
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span className="menu-title">Bank Account Details</span>
                        </Link>
                        <Link to="Support"  onClick={() => setActiveTab('Support')} className={`menu-link nav-link ${activeTab === 'Support' ? 'active' : ' '}`}>
                          <span className="menu-bullet">
                            <span className="bullet"></span>
                          </span>
                          <span className="menu-title">Support</span>
                        </Link>
   
                    </ul>
                  </div>
                </div>
                <div className="col-md-9">
                  <Outlet/>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default AccountPage;
