import React, { useEffect, useState } from "react";
import { $ } from "react-jquery-plugin";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import { alertSuccessMessage, alertWarningMessage } from "../../UtilityComponent/CustomAlertMessage";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import { v4 as uuidv4 } from 'uuid';
import Header from "./Header";

const PaymentMethod = () => {
    const [selectedPaymentMode, setSelectedPaymentMode] = useState('');
    const [paymentInputs, setPaymentInputs] = useState({});
    const [addType, setAddType] = useState('');
    const [paymentMethod, setPaymentMethod] = useState([])

    const [paymentList, setPaymentList] = useState([]);
    const availPaymentMode = [
        { type: 'Bank', input: ['Account Type', 'Bank Name', 'Account Holder Name', 'Account Number', 'IFSC Code', 'Branch Address'] },
        { type: 'UPI', input: ['Name', 'UPI Id'] },
        { type: 'Paytm', input: ['Name', 'Paytm UPI'] },
        { type: 'Phonepay', input: ['Name', 'Phonepay UPI'] },
        { type: 'IMPS', input: ['Account Type', 'Bank Name', 'Account Holder Name', 'Bank Account/Card Number', 'IFSC Code', 'Branch Address'] },
    ];

    const handlePaymentInput = (event) => {
        const { name, value } = event.target;
        const updatedName = name.replace(/\s/g, '_');
        setPaymentInputs((prevFormData) => ({
            ...prevFormData,
            [updatedName]: value,
            type: selectedPaymentMode
        }));
    };

    const addPaymentMethod = async () => {
        const isAnyInputEmpty = availPaymentMode
            ?.filter((item) => item.type === selectedPaymentMode)
            ?.flatMap((item) => item.input)
            ?.some((inputItem) => !paymentInputs[inputItem.replace(/\s/g, '_')]);
        if (isAnyInputEmpty) {
            alertWarningMessage('Please fill all required details')
            return;
        }
        LoaderHelper.loaderStatus(true);
        const uniqueId = uuidv4();
        const userId = sessionStorage.getItem('userId');
        let payment = [{ ...paymentInputs, id: uniqueId }]
        await AuthService.addPaymentMethod(payment, userId).then(async (result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                alertSuccessMessage(result?.message)
                fetchPaymentMethods()
                $('#payment_method_form')?.modal('hide');
            }
        });
    };

    const editPaymentMethod = async () => {
        const isAnyInputEmpty = availPaymentMode
            ?.filter((item) => item.type === selectedPaymentMode)
            ?.flatMap((item) => item.input)
            ?.some((inputItem) => !paymentInputs[inputItem.replace(/\s/g, '_')]);
        if (isAnyInputEmpty) {
            alertWarningMessage('Please fill all required details')
            return;
        }
        LoaderHelper.loaderStatus(true);
        await AuthService.editPaymentMethod(paymentInputs).then(async (result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                alertSuccessMessage(result?.message)
                fetchPaymentMethods()
                $('#payment_method_form')?.modal('hide');
            }
        });
    };

    const fetchPaymentMethods = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.fetchPaymentMethods().then(async (result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                setPaymentList(result?.data)
            }
        });
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        fetchPaymentMethods();
        getPaymentMethods()
    }, []);

    const getPaymentMethods = async () => {
        LoaderHelper?.loaderStatus(true);
        await AuthService.paymentMethod().then(async (result) => {
          LoaderHelper?.loaderStatus(false);
          if (result?.success) {
            setPaymentMethod(result?.data);
          }
        });
      };
    

    return (
        <>
            <Header />
            <div className="buy_bar pb-5" >
                <div className="container" >
                    <div className="bar_row" >
                        <div className="bar_left" >
                            <div className="bar_title" >
                                <h3> Payment Method </h3>
                                <p className="mb-0" >Add, Edit or Delete Payment method  </p>
                            </div>
                        </div>
                        <h6 className="badge rounded-pill badge-success mb-0 p-3 cursor-pointer edit_payment" data-bs-toggle="modal" data-bs-target="#payment_method_list" onClick={() => { setAddType('ADD') }}><i className="ri-add-circle-fill mx-1 ri-xl"></i>Add Payment Method</h6>
                    </div>
                </div>
            </div>
            <div className="pp_data cst-table" >
                <div className="container" >
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active mt-3" id="pills-Orders" role="tabpanel" aria-labelledby="ills-Orders-tab-tab">
                            <div className="cst-table-content shadow-soft table-responsive gx-3 row ">
                                {paymentList?.length > 0 ? paymentList?.map((item) => {
                                    return (
                                        <div className='col-md-6' key={item?.id}>
                                            <div className="acc_details form-field-wrapper bank-acc mb-3 shadow-soft active_payment mt-5 p-3">
                                                <div className="row " >
                                                    <div className="col-md-12 mb-3">
                                                        <div className="d-flex align-items-center justify-content-between mb-4 " >
                                                            <h6 className="badge rounded-pill badge-primary mb-0 p-2">{item?.type}</h6>
                                                            <div>
                                                                <h6 className="badge rounded-pill badge-success mb-0 p-2 cursor-pointer edit_payment mx-2" data-bs-toggle="modal" data-bs-target="#payment_method_form" onClick={() => { setSelectedPaymentMode(item?.type); setPaymentInputs(item); setAddType('EDIT') }} ><i className="ri-edit-2-fill mx-1"  ></i>Edit</h6>
                                                                <h6 className="badge rounded-pill badge-danger mb-0 p-2 cursor-pointer cancel_payment " ><i className="ri-delete-bin-line mx-1"></i>Delete</h6>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    {Object.entries(item)
                                                        .filter(([key]) => key !== 'type' && key !== 'id')
                                                        .map(([key, value]) => {
                                                            const updatedName = key.replace(/_/g, ' ');
                                                            return (
                                                                <div className="col-md-4" key={key}>
                                                                    <small>{updatedName}</small>
                                                                    <h6 className="text-start">{value}</h6>
                                                                </div>
                                                            )
                                                        })}
                                                </div>
                                                <hr />
                                            </div>
                                        </div>
                                    )
                                }) : <div className="favouriteData mt-5">
                                    <img src="/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                                    <p className="mt-2 mb-4" >No payment method added</p>
                                </div>}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="modal fade" id="payment_method_form" tabIndex="-1" aria-labelledby="payment_method_formLaebl" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-2 pb-5" style={{ backgroundColor: 'rgb(34 36 38)' }}>
                        <div className="modal-header flex-column px-8">
                            <h3 className="modal-title" id="placeBitLaebl">Set my payment method</h3>
                            <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i className="ri-close-fill"></i></button>
                        </div>
                        <div className="modal-body px-md-4 custom-form  ">
                            <form >
                                {paymentMethod?.map((item, index) => {
                                    return (
                                        item?.payment_type === selectedPaymentMode &&
                                        item?.input?.map((item) => {
                                            let updatedName = item.replace(/\s/g, '_');
                                            return (
                                                <div key={item} className="form-group mb-4">
                                                    <label for="bit" >{item} </label>
                                                    <input type="text" name={item} placeholder={`Please Enter ${item}`} onChange={(e) => handlePaymentInput(e, index)} value={paymentInputs[updatedName] !== undefined ? paymentInputs[updatedName] : ''} />
                                                </div>
                                            )
                                        })
                                    )
                                })}
                            </form>
                        </div>
                        <div className="modal-footer px-8">
                            {addType === 'ADD' ? <button type="button" className="  btn btn-gradient btn-medium justify-content-center w-100" onClick={() => addPaymentMethod()} ><span>Add {selectedPaymentMode}</span></button> : <button type="button" className="  btn btn-gradient btn-medium justify-content-center w-100" onClick={() => editPaymentMethod()} ><span>Edit {selectedPaymentMode}</span></button>}


                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade" id="payment_method_list" tabIndex="-1" aria-labelledby="payment_method_listLaebl" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '500px' }}>
                    <div className="modal-content p-2 pb-5" style={{ backgroundColor: 'rgb(34 36 38)' }}>
                        <div className="modal-header flex-column px-8">
                            <h3 className="modal-title" id="placeBitLaebl">Select Payment Method</h3>
                            <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i className="ri-close-fill"></i></button>
                        </div>
                        <div className="modal-footer p-1">
                            {paymentMethod?.map((item, index) => {
                                return (
                                    <button key={index} type="button" className="btn btn-gradient btn-medium justify-content-between w-100 m-2" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#payment_method_form" onClick={() => { setSelectedPaymentMode(item?.payment_type); setPaymentInputs({}) }}><span>{item?.payment_type}</span> <i className="ri-add-box-fill "></i></button>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default PaymentMethod;

