import React, { useEffect, createContext, useState } from 'react';
import AuthService from '../Api_Module/Api_Services/AuthService';


export const ProfileContext = createContext();

export function ProfileProvider(props) {
  const [updateRouting, setUpdateRouting] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [userImage, setUserImage] = useState();
  const [authType, setAuthType] = useState();
  const [kycStatus, setKycStatus] = useState();
  const [currency, setCurrency] = useState();
  const [buySellSkip, setbuySellSkip] = useState(false);
  const [registerdBy, setregisterdBy] = useState('');
  const [trades, setTrades] = useState()
  const [registeredDays, setRegisteredDays] = useState()
  const [counterCurrency, setCounterCurrency] = useState()
  const [counterCurrencyAmount, setCounterCurrencyAmount] = useState()
 

  let token = sessionStorage.getItem('token');

  useEffect(() => {
    token && handleUserDetials()
  }, []);


  // ********* User Profile Details ********** //
  const handleUserDetials = async () => {
    await AuthService.getDetails().then(async (result) => {
      if (result?.success) {
        setEmail(result.data.emailId);
        setMobile(result.data?.mobileNumber);
        setFirstName(result.data?.firstName);
        setLastName(result.data?.lastName);
        setUserImage(result.data.profilepicture);
        setAuthType(result?.data?.["2fa"]);
        setKycStatus(result?.data?.kycVerified);
        setCurrency(result?.data?.currency_prefrence);
        setbuySellSkip(result?.data?.buy_sell_model_skip);
        setregisterdBy(result?.data?.registerdBy);
        setTrades(result?.data?.p2p_trades)
        setRegisteredDays(result?.data?.registeredDays)
        setCounterCurrencyAmount(result?.data?.currencyAmount)
        setCounterCurrency(result?.data?.counterCurrency)
      }
    });
  };

  // kycStatus, p2p_trades, handleUserDetials,registeredDays


  // *********Button Scroll Upside ********** //
  useEffect(() => {
    const btnScrollToTop = document.querySelector("#btnScrollToTop");
    if (btnScrollToTop) {
      btnScrollToTop.addEventListener("click", e => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      });
      window.addEventListener('scroll', e => {
        window.scrollY > 100 ? btnScrollToTop.style.display = "block" : btnScrollToTop.style.display = "none";
      });
    }
  }, []);

  return (
    <>
      <button id='btnScrollToTop' className='shadow-soft_inner' type='button'><i className=" text-gradient ri-arrow-up-line"></i></button>
      <ProfileContext.Provider value={{ handleUserDetials, setUpdateRouting, updateRouting, firstName, setFirstName, email, setEmail, lastName, setLastName, setMobile, mobile, userImage, setUserImage, authType, kycStatus, currency, setCurrency, buySellSkip,registerdBy, registeredDays, trades, counterCurrency, counterCurrencyAmount}}>{props.children}</ProfileContext.Provider>
    </>
  );
}


