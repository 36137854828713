import React,{ useEffect,useState,useRef, useContext } from 'react';
import {useDispatch ,useSelector } from 'react-redux';
import { getFriends,messageSend,getMessage,ImageMessageSend } from '../../store/actions/messengerAction';
import {io} from 'socket.io-client';
import useSound from 'use-sound';
import notificationSound from './audio/notification.mp3';
import sendingSound from './audio/sending.mp3';
import { alertSuccessMessage } from '../../UtilityComponent/CustomAlertMessage';
import { ApiConfig } from '../../Api_Module/Api_Config/ApiEndpoints';
import { ProfileContext } from '../../Context';
import Message from './Message';
import MessageSend from './MessageSend';

const Messenger = ({frndId, orderId, orderStatus}) => {

     console.log(frndId, "frnd")

 const [notificationSPlay] = useSound(notificationSound);   
 const [sendingSPlay] = useSound(sendingSound); 
 const [msgImage, setMsgImage] = useState() 
 const dispatch = useDispatch();
 const scrollRef = useRef();


 const {friends,message} = useSelector(state => state.messenger );
 const { firstName,  email,  lastName,   mobile} = useContext(ProfileContext);

 const userInfo = [{
     name : firstName + lastName,
     email : email,
     mobile : mobile
 }]

 const userId = sessionStorage.getItem("userId")
 const username = sessionStorage.getItem("name")

 const [currentfriend, setCurrentFriend] = useState('');
 const [newMessage, setNewMessage] = useState('');
const [typingMessage, setTypingMessage] = useState('');
 const [socketMessage, setSocketMessage] = useState('');
 
const [socket, setSocket] = useState();



 useEffect(() => {
     const newSocket = io(`${ApiConfig?.webSocketUrl}`, {
         transports: ['websocket'],
         upgrade: false,
         rejectUnauthorized: false,
         reconnectionAttempts: 3,
         debug: true
     });

     setSocket(newSocket)

     if(newSocket){
          let payload = {
               'message': 'chat',
           }
           newSocket.emit('message', payload);
           newSocket.emit('addUser', userId, userInfo);
          
           newSocket.on('getMessage',(data) => {
               setSocketMessage(data);
               console.log(data, "getMsg")
           })
           newSocket.on('typingMessageGet',(data) => {
               console.log(data, "typing...")
                setTypingMessage(data);
           })
     }

     
     return () => {
         newSocket.disconnect();
     };
 }, []);


 useEffect(()=>{
     if(socket){
          socket.on('getUser',(users)=>{
               console.log(users, "userss")
          })
     }

 },[socket])

 useEffect(()=>{
      if (Object.keys(friends).length > 0){
          if(friends?.data?.length > 0){
               console.log(friends?.data[0]?.friendInfo[0]?._id, "hello")
               setCurrentFriend(friends?.data[0]?.friendInfo[0])
               dispatch(getMessage(friends?.data[0]?.friendInfo[0]?._id, orderId));
          }
      } 
      }
 ,[friends])


useEffect(() => {
    if(socketMessage && currentfriend){
      if(socketMessage?.senderId === currentfriend?._id && socketMessage?.recieverId === userId)
         {
              dispatch({
                   type: 'SOCKET_MESSAGE',
                   payload : {
                        message: socketMessage
                   }
              })
         }
    }
   setSocketMessage('')
 },[socketMessage, friends]);


 useEffect(() => {
      if(socketMessage && socketMessage.senderId === currentfriend._id && socketMessage.recieverId === userId){
           notificationSPlay();
           alertSuccessMessage(`${socketMessage.senderName} Send a New Message`)
      }
 },[socketMessage, friends]);


 

 const inputHendle = (e) => {
     setNewMessage(e.target.value);

     socket.emit('typingMessage',{
          senderId : userId,
          recieverId : currentfriend._id,
          order_id: orderId,
          msg : e.target.value,
     })

 }
 
 const sendMessage = (e) => {
     e.preventDefault();
     sendingSPlay();
     const data = {
          senderName : firstName == null ? " " : firstName + " " + lastName,
          recieverId : currentfriend._id,
          order_id: orderId,
          message : newMessage ? newMessage : '❤',
     }

     socket.emit('sendMessage',{
          senderId : userId,
          senderName : firstName + " " + lastName,
          recieverId : currentfriend._id,
          order_id: orderId,
          time : new Date(),
          message : {
               text : newMessage ? newMessage : '❤',
               image : ''
          }
     })
     socket.emit('typingMessage',{
          senderId : userId,
          recieverId : currentfriend._id,
          order_id: orderId,
          msg : ''
     })

     dispatch(messageSend(data));
     setNewMessage('')
 }



  



     useEffect(() => {
          dispatch(getFriends(frndId, orderId));
     },[frndId]);

      useEffect(() => {
          scrollRef.current?.scrollIntoView({behavior: 'smooth'}) 
      },[ message]);
 

     const emojiSend = (emu) => {
          setNewMessage(`${newMessage}`+  emu);
          socket.emit('typingMessage',{
               senderId : userId,
               recieverId : currentfriend._id,
               order_id: orderId,
               msg : emu
          })
     }

useEffect(()=>{
     {msgImage && 
          socket.emit('sendMessage',{
               senderId: userId,
               senderName: firstName + " " + lastName,
               recieverId: currentfriend._id,
               order_id: orderId,
               time: new Date(),
               message : {
                    text : '',
                    image : msgImage
               }
          })
     }
},[msgImage])

     const ImageSend = (e) => {
console.log(e.target.files[0], "target")
          if(e.target.files.length !== 0){
               sendingSPlay();
               const imagename = e.target.files[0].name;
               const formData = new FormData();
               formData.append('senderName', firstName + " " + lastName);
               formData.append('recieverId',currentfriend._id);
               formData.append('chat_image', e.target.files[0]);
               formData.append('image', imagename);
               formData.append('order_id', orderId)
               dispatch(ImageMessageSend(formData,setMsgImage));
               
               console.log(formData, "formdata")
                
          } 
         
     }

  return (
     <>

{currentfriend && 
     <div className="chat_box card">
          <div className="chat-header">
                    <div className="avatar-box  d-flex align-items-start justify-content-">
                      <div><span className="avatar shadow-soft"> <span><img src={ApiConfig.baseUrl + currentfriend?.profilepicture} alt=''/></span></span>
                        <div>
                          <span className="nickName d-block">{currentfriend?.firstName + " " +currentfriend?.lastName }</span>
                          <p className="advertiseList_merchantOrder__aO2o_ ms-2 "><span className="text-warning">
                                          {currentfriend?.userRatings == 1 ? 
                                        <i className="ri-star-fill me-1"></i>
                                        : currentfriend?.userRatings == 2 ?
                                        <>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        </>
                                        : currentfriend?.userRatings == 3 ?
                                        <>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        </>
                                       : currentfriend?.userRatings == 4 ?
                                       <>
                                       <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        </>
                                        : currentfriend?.userRatings == 5 ?
                                        <>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        <i className="ri-star-fill me-1"></i>
                                        </>
                                        : "No Rating Available"
                                      }
                                        </span>{currentfriend?.userRatings == 0 ? " " : <span className="advertiseList_tradingLine__swVrO"></span>}
                                      <span className="text-warning">{currentfriend?.userRatings == 0 ? "" :currentfriend?.userRatings}</span></p>

                        </div>
                      </div>
                      <span className=" text-white chat_close_icon d-md-none" > <i className="ri-close-fill"></i> </span>
                    </div>
                    {currentfriend?.p2p_trades == 0 ? "" :
                    <div className="other-box">
                      <div>
                        <div className="line-info">
                          <div>
                            <div className="value">{currentfriend?.p2p_trades} successfull trades</div>
                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
         <Message
          message = {message}
          currentfriend = {currentfriend}
          scrollRef = {scrollRef}
          typingMessage = {typingMessage}
          />

{orderStatus == "CONFIRMED" ? "" :
          <MessageSend
          inputHendle = {inputHendle}
          newMessage = {newMessage}
          sendMessage = {sendMessage}
          emojiSend = {emojiSend}
          ImageSend = {ImageSend}
          />
                         }
</div>
                
 } 
</>
            
  )
};

export default Messenger;
