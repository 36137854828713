import React, { useEffect, useState } from "react";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import AuthService from "../../Api_Module/Api_Services/AuthService";

const NotificationPage = () => {
    useEffect(() => {
        handleNotifications();
    }, []);

    const [notifications, setNotifications] = useState([]);

    const handleNotifications = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.notificationList().then(async (result) => {
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                try {
                    setNotifications(result?.data?.reverse());
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    return (
        <>
         <div class="tab-pane" id="SecurityPill" role="tabpanel" aria-labelledby="Security-pill">
                <div class="upload-formate mb-6 d-flex justify-content-center align-items-center">
                    <div>
                        <h3 class="mb-1 text-center">
                            Notification
                        </h3>
                        <p class="formate mb-0">
                            Latest Notification Here...
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-lg-8 m-auto">
            <div className="nofti_list form-field-wrapper switch_btn  border-dashed border-gray-300 bg-lighten card-rounded shadow-soft m-0" >
                {notifications?.length > 0 ? notifications?.map((item, index) => {
                    return (

                        <div class="notification-list notification-list--unread  px-0">
                            <div class="notification-list_content">
                                <div class="notification-list_img">
                                    {/* <img src={ApiConfig?.appUrl + item?.notification_img} alt="user" /> */}
                                </div>
                                <div class="notification-list_detail">

                                    <p className="text-full" >
                                        {index + 1}  <b>{item?.title}</b>
                                    </p>
                                    <p class="text-muted text-full">{item?.message} <a href={item?.link} target="_blank" rel="noreferrer">   View more...</a></p>
                                </div>
                            </div>
                            <div class="notification-list_feature-img">
                                {/* <a href={item?.link} target="_blank"><p class="text-muted">Click here...</p></a>   */}
                            </div>
                        </div>

                    )
                }) : <div className="favouriteData mt-5">
                    <img src="/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                    <p className="mt-2 mb-4" >No notification found </p>
                </div>}




            </div>
            </div>
            </div>
            </div>

        </>
    );
};

export default NotificationPage;
