const appUrl = "https://api.inoutbit.com";
/* http://103.175.163.162:5002"; */

export const ApiConfig = {
  // =========EndPoints==========
  getcode: "verify-otp",
  getOtp: "send-otp",
  login: "login",
  register: "register",
  getDetails: "profile",
  updateSettings: "edit-profile",
  googleAuth: "generate-google-qr",
  update2fa: "enable-2fa",
  getHistoricalData:'historical-data',
  placeOrder:'place-order',
  pastOrder:'past-order',
  cancelOrder:'cancel-order',
  categoryList:'coin-category-list',
  coinDetails:'coin-details',
  favoriteCoin:'favorite-coin',
  favoriteList:'favorite-list',
  tradeHistory:'trade-history',
  addFavourite: "exch/addfavcoin",
  getFavouriteList: "exch/getallfavcoin",
  setCurrency: "currency-preference",
  userfunds: "user-wallet",
  generateAddress: "generate-address",
  estimatedPortfolio: "estimated-portfolio",
  forgotpassword: "forgot_password",
  totalrefercount: "total_refer_count",
  referalcode: "user_refer_code",
  withdrawalCurrency: "withdrawal",
  verifyDeposit:'verify-deposit',
  walletDepositHistory: "wallet-deposit-history",
  swapHistory: "swapping_history",
  walletWithdrawalHistory: "wallet-withdrawal-history",
  addkyc: "submit-kyc",
  activityLogs: "get_logs",
  skipModel: "skip-model",
  userTradeHistory: "user-trade-history",
  notificationList: "notification-list",
  addOrderNotification: "add_order_notification",
  p2pBuyOrder: "buy_order",
  p2pSellOrder: "sell_order",
  p2pCounterCurrency: "check_balance",
  // paymentMethods: "payment_type_list",
  p2pCoinList: "p2p_coin_list",
  p2pRuntime: "runtime_api",
  createNewPost: "create_new_post",
  change_password: "change_password",
  getBankDetails: 'get-bank-details',
  getUpiDetails: "user_upi_details",
  editBankDetails: 'edit-bank-details',
  addBankDetails: 'add-bank-details',
  addUpiDetails: 'add_user_upi',
  editUpiDetails: 'edit_user_upi',
  adminBankDetails: 'get_user_bank_details',
  depositInr: 'deposit_inr',
  adminUpiDetails: "admin_upi_details",
  withdrawInr: 'withdraw_inr',
  getMaintenance: 'get-maintenance',
  getPairs: "get-pairs",
  fiatCurrencyList: "fiat_currency_list",
  currentPrice: "current_price",
  myAds: "my_ads",
  buyCurrency: "buy_currency",
  myOrders: "my_orders",
  buyRequest: "buy_request",
  notify: "notify_trader",
  notifySeller: "merchant_confirmation",
  notifyMerchent: "trader_confirmation",
  addPaymentMethod: "add_payment_method",
  fetchPaymentMethods: "fetch_payment_methods",
  editPaymentMethod: "edit_payment_method",
  getCoinList: "get-coin",
  submitTicket: "submit-ticket",
  getUserTickets: "get-user-tickets",
  replyTicket: "reply-ticket",
  transferFunds: "swaping_wallets",
  rateOrder : "rate_user",



  // ============URLs================ //
  baseUrl: `${appUrl}/`,
  appUrl: `${appUrl}/`,
  baseAuth: `${appUrl}/v1/user/`,
  baseAdmin: `${appUrl}/v1/admin/`,
  baseWallet: `${appUrl}/v1/wallet/`,
  baseExchange: `${appUrl}/v1/exchange/`,
  baseTrans: `${appUrl}/v1/transaction/`,
  baseKyc: `${appUrl}/kyc/`,
  p2p: `${appUrl}/v1/p2p/`,
  baseCoin: `${appUrl}/v1/coin/`,
  baseSupport: `${appUrl}/v1/support/`,


  // ============webSocketUrl================ //
  webSocketUrl: "wss://api.inoutbit.com",

};
