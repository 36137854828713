import { useState, useEffect } from 'react';

const useCountdownTimer = (targetTimestamp, timelimit) => {
  const calculateRemainingTime = () => {
    const currentTime = Math.floor(Date.now() / 1000);
    const elapsedTime = currentTime - targetTimestamp;
    return Math.max(0, timelimit - elapsedTime);
  };

  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime);

  useEffect(() => {
    setRemainingTime(calculateRemainingTime());

    const timerId = setInterval(() => {
      setRemainingTime(calculateRemainingTime());
    }, 1000);

    return () => clearInterval(timerId);
  }, [targetTimestamp, timelimit]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return { remainingTime, formattedTime: formatTime(remainingTime) };
};

export default useCountdownTimer;
