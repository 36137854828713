import {FRIEND_GET_SUCCESS,MESSAGE_GET_SUCCESS,MESSAGE_SEND_SUCCESS,SOCKET_MESSAGE} from "../types/messengerType";

const messengerState = {
     friends : {},
     message : [],
     mesageSendSuccess : false,
     message_get_success : false,
}

export const messengerReducer = (state=messengerState,action) => {
     const {type,payload} = action;


     if(type === FRIEND_GET_SUCCESS){
          return {
               ...state,
               friends : payload.friends,

          }     }

          console.log(state, "22")

     if(type === MESSAGE_GET_SUCCESS){
          return {
               ...state,
               message_get_success : true,
               message : payload.message
          }
     }

     console.log(state, "00000")

     if(type === MESSAGE_SEND_SUCCESS){
          console.log(payload.message.image, "payload")
          sessionStorage.getItem("image", payload.message.image )
          return {
               ...state,
               // mesageSendSuccess : true,
               message : [...state.message, payload.message]
          }
     }

     if(type === SOCKET_MESSAGE){
          return {
               ...state,
               message : [...state.message, payload.message]
          }
     }


     return state;
}