import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { alertSuccessMessage } from "../../UtilityComponent/CustomAlertMessage";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import { ProfileContext } from "../../Context";

const LoginPage = () => {
  const navigate = useNavigate();
  const [isAuthEnabled, setIsAuthEnabled] = useState(false);
  const [verification_code, setverification_code] = useState('');
  const [userDetails, setUserDetails] = useState();
  const [authType, setAuthType] = useState();
  const [signId, setSignId] = useState('');
  const [mobileNumber, setmobileNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  window.scrollTo({ top: 0, behavior: 'smooth' });

  // ********* Context ********** //
  const { setUpdateRouting, updateRouting } = useContext(ProfileContext)

  // ********* Country Code/List ********** //
  const countryCodes = require('country-codes-list')
  const myCountryCodesObject = countryCodes.customList('countryCode', '{countryNameEn} : (+{countryCallingCode})  ');

  // ********* Login Function ********** //
  const handleLogin = async (signId, password) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.login(signId, password).then(async (result) => {
      if (result?.success) {
        if (result?.data?.['2fa'] === 0) {
          alertSuccessMessage('Login Successfull !!');
          sessionStorage.setItem("token", result?.data?.token);
          sessionStorage.setItem("userId", result?.data?.userId);
          navigate("/profile/SettingsPage");
          setUpdateRouting(!updateRouting)
        } else {
          setAuthType(result?.data?.['2fa'])
          setIsAuthEnabled(true);
          setUserDetails(result?.data)
        }
      }
    });
  };

  // ********* Auth Verificatioon Function ********** //
  const handleAuthVerify = async (signId, authType, vCode) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getCode(signId, authType, vCode).then(async (result) => {
      if (result?.success) {
        sessionStorage.setItem("token", result?.data?.token);
        sessionStorage.setItem("userId", result?.data?.userId);
        alertSuccessMessage('Login Successfull!!');
        navigate("/profile/SettingsPage");
        setUpdateRouting(!updateRouting)
      }
    });
  };

  // ********* Reset Form Inputs ********** //
  const handleReset = () => {
    setSignId('');
    setmobileNumber('');
    setPassword('');
    setShowPassword(false);
  };

  return (
    <section className="inner-page-banner pb-0  ">
      <section className="login-bg ">
        <div className="container">
          <div className="">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-7 col-lg-12 ">
                <img src="/images/main_img.svg" className="img-fluid" />
              </div>
              <div className="col-xl-5 col-lg-12 ">
                <div className="signup-wrapper p-0 shadow-soft">
                  <div className="signup-content signin-content">
                    <div className={`sign-in_tab ${isAuthEnabled && "d-none"}`}>
                      <h3 className="mb-2">Login to Inoutbit</h3>
                      <div className="mb-3">
                        <ul className="nav custom-tabs my-1 shadow-soft custom-tabs_full">
                          <li>
                            <a className="active" data-bs-toggle="tab" href="#login_Mobile" onClick={handleReset}
                            > Mobile </a>
                          </li>
                          <li>
                            <a data-bs-toggle="tab" href="#login_email" onClick={handleReset}>
                              Email
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content">

                        {/* *******Login Mobile********** */}

                        <div className="tab-pane show  px-0 container active" id="login_Mobile">
                          <form action="#">
                            <div className="row">
                              <div className="col-12">
                                <div className="fleld-box">
                                  <label htmlFor="Code" className="form-label">
                                    Mobile
                                  </label>
                                </div>
                              </div>
                              <div className="col-12 mb-4">
                                <div className="field-box ">
                                  <select name="countryCode" defaultValue="+91">
                                    <optgroup label="Other countries">
                                      <option value="+91" >
                                        India (+91)
                                      </option>
                                      {Object.values(myCountryCodesObject).map(value => {
                                        return <option value={value} key={value}>
                                          {value}
                                        </option>
                                      })}
                                    </optgroup>
                                  </select>
                                </div>
                              </div>
                              <div className="col-12 mb-4">
                                <div className="field-box">
                                  <input
                                    id="mobile"
                                    type="number"
                                    placeholder="Enter mobile number"
                                    value={mobileNumber}
                                    onChange={(e) => { setmobileNumber(e.target.value) }}
                                    onWheel={(e)=>e.target.blur()} 
                                  />
                                </div>
                              </div>
                              <div className="col-md-12 mb-4">
                                <div className="field-box">
                                  <label htmlFor="password" className="form-label">
                                    Password
                                  </label>
                                  <div className="field-otp-box">
                                    <input
                                      name="mpassword"
                                      placeholder="Enter Password"
                                      type={showPassword ? 'text' : "password"}
                                      value={password}
                                      onChange={(e) => { setPassword(e.target.value) }}
                                    />
                                    <a className="show_password opt_btn btn-sm" onClick={() => { setShowPassword(!showPassword) }}>
                                      {showPassword ? <i className="ri-eye-off-line" /> : <i className="ri-eye-line" />}

                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 mb-4">
                                <div className="field-box">
                                  <Link to="/ForgotPassPage">
                                    Forgot Password?
                                  </Link>
                                </div>
                              </div>
                              <div className="col-md-12 mb-4 mt-4">
                                <div className="field-box">
                                  <button
                                    className="btn btn-gradient w-100 justify-content-center btn-medium"
                                    type="button" onClick={() => { handleLogin(mobileNumber, password) }}>
                                    <span>Login</span>
                                  </button>
                                </div>
                              </div>

                            </div>
                          </form>
                        </div>

                        {/* *******Login Email********** */}

                        <div className="tab-pane  px-0   container" id="login_email">
                          <form action="#">
                            <div className="row">
                              <div className="col-md-12 mb-4">
                                <div className="field-box">
                                  <label htmlFor="email" className="form-label">
                                    Email
                                  </label>
                                  <input id="email" name="email" type="email" placeholder="Enter Email"
                                    value={signId} onChange={(e) => { setSignId(e.target.value) }} />
                                </div>
                              </div>
                              <div className="col-md-12 mb-4">
                                <div className="field-box">
                                  <label htmlFor="password" className="form-label"
                                  >
                                    Password
                                  </label>
                                  <div className="field-otp-box">
                                    <input name="password" placeholder="Enter Password" type={showPassword ? 'text' : "password"} value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                    <a className="show_password opt_btn btn-sm "  onClick={() => { setShowPassword(!showPassword) }}>
                                      {showPassword ? <i className="ri-eye-off-line" /> : <i className="ri-eye-line" />}

                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 mb-4">
                                <div className="field-box">
                                  <Link to="/ForgotPassPage">
                                    Forgot Password?
                                  </Link>
                                </div>
                              </div>
                              <div className="col-md-12 mb-4 mt-4">
                                <div className="field-box">
                                  <button className="btn btn-gradient w-100 justify-content-center btn-medium" type="button" onClick={() => { handleLogin(signId, password) }}>
                                    <span>Login</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="row justify-content-center text-center">
                          <div className="col-lg-12">
                            Don't have an account?  <Link to="/signup" className="color-primary"> Register   </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`otb_tab  ${!isAuthEnabled && "d-none"} `}>
                      <div className="mb-3">
                        <h3 className="mb-2 text-center">Two Factor Verification</h3>
                        <p className="medium text-center">
                          {authType === 2
                            ? "Your Code will be sent to Google Authenticator App"
                            : authType === 1 ? `Your Code will be sent ${userDetails?.emailId}` : authType === 3 ? `Your Code will be sent ${userDetails?.mobileNumber}` : null}
                        </p>
                      </div>
                        <div className="row">
                          <div className="col-md-12 mb-4">
                            <div className="field-box">
                              <label htmlFor="Code" className="form-label">
                                Enter Code
                              </label>
                              <input
                                placeholder="Enter Verification Code"
                                id="Verification"
                                type="text"
                                value={verification_code}
                                onChange={(e) => { setverification_code(e.target.value) }}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 mb-4">
                            <div className="field-box">
                            </div>
                          </div>
                          <div className="col-md-12 mb-4 mt-4">
                            <div className="field-box">
                              <button className="btn btn-gradient w-100 justify-content-center btn-medium" type="button"
                                onClick={() => handleAuthVerify(mobileNumber ? mobileNumber : signId, authType, verification_code)}
                              >
                                <span>Verify</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      <div className="row justify-content-center text-center">
                        <div className="col-lg-12">
                          Back to{"  "}
                          <Link to="#" className={"color-primary"} onClick={() => setIsAuthEnabled(false)}>
                            Login
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </section >
    </section >
  );
};

export default LoginPage;
