import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ProfileContext } from "../../Context";
import { ApiConfig } from "../../Api_Module/Api_Config/ApiEndpoints";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import { alertErrorMessage } from "../../UtilityComponent/CustomAlertMessage";

const AuthHeader = () => {
  const navigate = useNavigate();
  const { setUpdateRouting, updateRouting, userImage, } = useContext(ProfileContext)
  const { firstName, email, lastName,  mobile } = useContext(ProfileContext);

  const { pathname } = useLocation();

  console.log(window.location)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const logout = () => {
    sessionStorage.clear();
    navigate("/");
    setUpdateRouting(!updateRouting)
  }
  const ShowMobileSidebar = () => {
    let button = document.getElementById('qwert');
    button.classList.add("active");
  }
  const HideMobileSidebar = () => {
    let button = document.getElementById('qwert');
    button.classList.remove("active");
  };

  useEffect(() => {
    handleNotifications();
  }, []);

  const [notifications, setNotifications] = useState([]);

  const handleNotifications = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.notificationList().then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        try {
          setNotifications(result?.data?.reverse()?.[0]);
        } catch (error) {
          console.log(error);
        }
      }
    });
  };


  return (
    <>
      <header className="ib-header header-default header-fixed header--sticky fluid-header">
        <div className="container-fluid">
          <div className="header-inner d-flex align-items-center justify-content-between">
            <div className="header-left d-flex align-items-center">
              <div>
                <div className="logo-wrapper show_web">
                  <Link to="/" className="logo logo-light">
                    <img src="/images/logo-white.svg" alt="brand" />
                  </Link>
                </div>
                <div className="logo-wrapper show_mobile">
                  <Link to="/" className="logo logo-light">
                    <img src="/images/logo-icon.svg" alt="brand" />
                  </Link>
                </div>
              </div>
              <div className="mainmenu-wrapper ms-md-5">
                <nav id="sideNav" className="mainmenu-nav d-none d-xl-block">
                  <ul className="mainmenu">
                    <li>
                      <Link to="/">
                        Home
                      </Link>
                    </li>
                    <li >
                      <Link to="/MarketPage">
                        Market
                      </Link>
                    </li>
                    <li >
                      <Link to="/trade">
                        Trade
                      </Link>
                    </li>
                    <li >
                      <Link to="/FundPage">
                        Wallet
                      </Link>
                    </li>
                    <li>
                    {/* {(firstName && lastName && email && mobile) ? */}
                      <Link to ="/p2p_trading">
                        P2P Trading
                      </Link>
                       {/* :<Link to="#" onClick={()=>alertErrorMessage("Please update your profile")}>
                       P2P Trading
                     </Link> } */}
                    </li>
                    <li>
                      <Link to="/P2PWallet">
                        P2P Wallet
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            <div className="header-right d-flex align-items-center">
              <ul className="header-right-inner">
                <li className="setting-option mobile-menu-bar d-block d-xl-none">
                  <button className="hamberger-button" type="button" onClick={ShowMobileSidebar}>
                    <i className="ri-menu-2-fill"></i>
                  </button>
                </li>

                <li className="avatar-info">
                  <span className="cursor_pointer">
                    <img src={userImage ? ApiConfig.baseUrl + userImage : "/images/profilelogo.png"} alt="user avatar" />

                  </span>
                  <ul className="submenu">
                    <li>
                      <Link to="/profile">
                        <i className="ri-user-line"></i> My Account
                      </Link>
                    </li>
                    <li>
                      <Link to="/ReferralPage">
                        <i className="ri-share-line"></i>Invite & Earn
                      </Link>
                    </li>
                    <li>
                      <a href="#/"
                        style={{ cursor: "pointer" }}
                        onClick={logout}
                      >
                        <i className="ri-logout-box-r-line"></i>Log out
                      </a>
                    </li>
                  </ul>
                </li>

                {/* notification  */}
                <li className="avatar-info notifications">
                  <span className="cursor_pointer   btn-icon">
                    <i className="ri-notification-3-line me-0"></i>
                    <span className="nofti_alert"></span>
                  </span>
                  <ul className="submenu pb-0">
                    <h6 className="dropdown-header d-flex align-items-center pb-3 ">
                      <i className="ri-notification-3-line me-3"></i>New
                      Notificatons
                    </h6>
                    <hr />

                    <div className="py-2" style={{ textAlign: "center" }}>{notifications ? notifications?.title?.length > 15 ? notifications?.title?.slice(0, 10) + '...' : notifications?.title : "No Data Available"} </div>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      <div className="popup-mobile-menu" id="qwert" onClick={HideMobileSidebar}>
        <div className="inner">
          <div className="header-top">
            <div className="logo logo-custom-css">
              <a className="logo-light" href="/">
                <img src="/images/logo-white.svg" alt="nft-logo" />
              </a>
              <Link className="logo-dark" to="/">
                <img src="/images/logo-dark.svg" alt="nft-logo" />
              </Link>
            </div>
            <div className="close-menu">
              <button className="close-button shadow-soft" >
                <i className="ri-close-fill"></i>
              </button>
            </div>
          </div>
          <nav>
            <ul className="mainmenu">

              <li><Link to="/" > <i className="ri-home-line me-2 "></i> Home </Link> </li>
              <li><Link to="/MarketPage"> <i className="ri-file-list-3-line me-2"></i>  Market</Link></li>
              <li> <Link to="/trade" > <i className="ri-line-chart-line me-2"></i> Trade  </Link></li>
              <li> <Link to="/FundPage"> <i className="ri-wallet-line me-2"></i> Wallet </Link></li>
              <li> <Link to="/P2PWallet"> <i className="ri-pantone-line me-2"></i>  P2P Wallet </Link></li>
              <li> 
              {/* {(firstName && lastName && email && mobile) ? */}
                <Link to="/p2p_trading"> <i className="ri-pantone-line me-2"></i>  P2P Trading </Link>
                {/* :<Link to="#" onClick={()=>alertErrorMessage("Please update your profile")}><i className="ri-pantone-line me-2"></i>  P2P Trading </Link>} */}
                </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default AuthHeader;