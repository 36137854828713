import { ApiConfig } from "../Api_Config/ApiEndpoints";
import { ApiCallGet, ApiCallPost, ApiCallPut } from "../Api_Config/ApiCall";

const AuthService = {
  /*** Calling Api's **/

  login: async (signId, password, captchaCode) => {
    const { baseAuth, login } = ApiConfig;
    const url = baseAuth + login;
    const params = {
      email_or_phone: signId,
      password: password,
      token: captchaCode,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  register: async (countryCode, signId, password, cPassword, otp, invitation, captchaCode) => {
    const { baseAuth, register } = ApiConfig;
    const url = baseAuth + register;
    const params = {
      country_code: +countryCode.trim(),
      email_or_phone: signId,
      password: password,
      confirm_password: cPassword,
      referal: invitation,
      verification_code: otp,
      token: captchaCode,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  getCode: async (signId, type, otp) => {
    const { baseAuth, getcode } = ApiConfig;
    const url = baseAuth + getcode;
    const params = {
      "email_or_phone": signId,
      "type": type,
      "otp": otp
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  getOtp: async (signid, resend) => {
    const { baseAuth, getOtp } = ApiConfig;
    const url = baseAuth + getOtp;
    const params = {
      email_or_phone: signid,
      "resend": true
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  otpVerify: async (otp, userId, emailId, first, last, type) => {
    const { baseUrl, otpVerify } = ApiConfig;
    const url = baseUrl + otpVerify;
    const params = {
      userId: userId,
      emailId: emailId,
      firstName: first,
      lastName: last,
      motp: otp,
      otp: true,
      otpType: type,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  referralLink: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, referalcode } = ApiConfig;
    const url = baseAuth + referalcode;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };
    return ApiCallGet(url, headers);
  },


  getDetails: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, getDetails } = ApiConfig;
    const url = baseAuth + getDetails;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  activityLogs: async (skip, limit) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, activityLogs } = ApiConfig;
    const url = baseAuth + activityLogs + `?skip=${skip}&limit=${limit}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  updateSettings: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, updateSettings } = ApiConfig;
    const url = baseAuth + updateSettings;
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': token
    };
    return ApiCallPut(url, formData, headers);
  },

  getCoinList: async () => {
    const { baseCoin, getCoinList } = ApiConfig;
    const url = baseCoin + getCoinList;
  
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },

  googleAuth: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, googleAuth } = ApiConfig;
    const url = baseAuth + googleAuth;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  update2fa: async (authType, code, verifyType) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, update2fa } = ApiConfig;
    const url = baseAuth + update2fa;
    const params = {
      type: authType,
      verification_code: +code,
      email_or_phone: verifyType
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  getHistoricalData: async (base, quote, from, to) => {
    const token = sessionStorage.getItem('token');
    const { baseExchange, getHistoricalData } = ApiConfig;
    const url = baseExchange + getHistoricalData;
    const params = {
      base_currency: base,
      quote_currency: quote,
      from: from,
      to: to,
      limit: 2000,
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  cryptoCompareApi: async (base, quote, to, resolution) => {
    const token = sessionStorage.getItem('token');
    const url = `https://min-api.cryptocompare.com/data/v2/${resolution}?fsym=${base}&tsym=${quote}&toTs=${to}&limit=2000`
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  getPairs: async () => {
    const token = sessionStorage.getItem('token');
    const { baseCoin, getPairs } = ApiConfig;
    const url = baseCoin + getPairs;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },



  placeOrder: async (infoPlaceOrder, buyprice, buyamount, base_currency_id, quote_currency_id, side) => {
    const token = sessionStorage.getItem('token');
    const { baseExchange, placeOrder } = ApiConfig;
    const url = baseExchange + placeOrder;
    const params = {
      "order_type": infoPlaceOrder,
      "base_currency_id": base_currency_id,
      "quote_currency_id": quote_currency_id,
      "side": side,
      "price": +buyprice,
      "quantity": +buyamount
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },


  getUserfunds: async () => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, userfunds } = ApiConfig;
    const url = baseWallet + userfunds;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  transferToSpot: async (selectedCurrency, withdrawAmount) => {
    const token = sessionStorage.getItem("token");
    const { p2p, transferFunds } = ApiConfig;
    const url = p2p + transferFunds;
    const params = {
    spot_wallet : true,
    amount: +withdrawAmount,
    short_name : selectedCurrency
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  transferToP2P: async (selectedCurrency, withdrawAmount) => {
    const token = sessionStorage.getItem("token");
    const { p2p, transferFunds } = ApiConfig;
    const url = p2p + transferFunds;
    const params = {
    funding_wallet : true,
    amount: +withdrawAmount,
    short_name : selectedCurrency
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  


  generateAddress: async (currID, chain) => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, generateAddress } = ApiConfig;
    const url = baseWallet + generateAddress;
    const params = {
      "currency_id": currID,
      "chain": chain
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },
  withdrawalCurrency: async (currencyId, selectedChain, withdrawAmount, walletAddress, otp, signId, selectedCurrency) => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, withdrawalCurrency } = ApiConfig;
    const url = baseWallet + withdrawalCurrency;
    const params = {
      "withdrawal_address": walletAddress,
      "amount": +withdrawAmount,
      "chain": selectedChain,
      "currency_id": currencyId,
      "email_or_phone": signId,
      "verification_code": otp,
      "coinName": selectedCurrency,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },


  getBankDetails: async () => {
    const token = sessionStorage.getItem('token');
    const { baseAuth, getBankDetails } = ApiConfig;
    const url = baseAuth + getBankDetails;
    const params = {};
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  getUpiDetails: async () => {
    const token = sessionStorage.getItem('token');
    const { baseAuth, getUpiDetails } = ApiConfig;
    const url = baseAuth + getUpiDetails;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },



  getMaintenance: async () => {
    const token = sessionStorage.getItem('token');
    const { baseAdmin, getMaintenance } = ApiConfig;
    const url = baseAdmin + getMaintenance;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },


  withdrawInr: async (withdrawAmount, withdrawBank) => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, withdrawInr } = ApiConfig;
    const url = baseWallet + withdrawInr;
    const params = {
      "amount": +withdrawAmount,
      'user_bank': withdrawBank && JSON.parse(withdrawBank)
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },


  editBankDetails: async (accountType, bankName, holderName, accountNumber, ifscCode, branchAddress, id) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, editBankDetails } = ApiConfig;
    const url = baseAuth + editBankDetails;
    const params = {
      _id: id,
      account_type: accountType,
      bank_name: bankName,
      account_holder_name: holderName,
      account_number: accountNumber,
      ifsc_code: ifscCode,
      branch_name: branchAddress,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  addBankDetails: async (accountType, bankName, holderName, accountNumber, ifscCode, branchAddress) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, addBankDetails } = ApiConfig;
    const url = baseAuth + addBankDetails;
    const params = {
      account_type: accountType,
      bank_name: bankName,
      account_holder_name: holderName,
      account_number: accountNumber,
      ifsc_code: ifscCode,
      branch_name: branchAddress,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  addUpiDetails: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, addUpiDetails } = ApiConfig;
    const url = baseAuth + addUpiDetails;
    
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };
    return ApiCallPost(url, formData, headers);
  },

  editUpiDetails: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, editUpiDetails } = ApiConfig;
    const url = baseAuth + editUpiDetails;
    
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };
    return ApiCallPut(url, formData, headers);
  },


  adminUpiDetails: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAdmin, adminUpiDetails } = ApiConfig;
    const url = baseAdmin + adminUpiDetails;

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  depositInr: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, depositInr } = ApiConfig;
    const url = baseWallet + depositInr;

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };
    return ApiCallPost(url, formData, headers);
  },
  adminBankDetails: async () => {
    const token = sessionStorage.getItem('token');
    const { baseAdmin, adminBankDetails } = ApiConfig;
    const url = baseAdmin + adminBankDetails;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  verifyDeposit: async () => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, verifyDeposit } = ApiConfig;
    const url = baseWallet + verifyDeposit;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  walletDepositHistory: async (skip, limit) => {
    const token = sessionStorage.getItem("token");
    const { baseTrans, walletDepositHistory } = ApiConfig;
    const url = baseTrans + walletDepositHistory;
    const params = {
      skip: skip,
      limit: limit
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);

  },

  swapHistory: async (skip, limit) => {
    const token = sessionStorage.getItem("token");
    const { p2p, swapHistory } = ApiConfig;
    const url = p2p + swapHistory;
    const params = {
      skip: skip,
      limit: limit
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);

  },

  walletWithdrawalHistory: async (skip, limit) => {
    const token = sessionStorage.getItem("token");
    const { baseTrans, walletWithdrawalHistory } = ApiConfig;
    const url = baseTrans + walletWithdrawalHistory;
    const params = {
      skip: skip,
      limit: limit
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);

  },
  skipModel: async (skip) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, skipModel } = ApiConfig;
    const url = baseAuth + skipModel;
    const params = {
      check: skip,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);

  },

  estimatedPortfolio: async () => {
    const token = sessionStorage.getItem("token");
    const { baseWallet, estimatedPortfolio } = ApiConfig;
    const url = baseWallet + estimatedPortfolio;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  pastOrder: async (base_currency_id, quote_currency_id) => {
    const token = sessionStorage.getItem('token');
    const { baseExchange, pastOrder } = ApiConfig;
    const url = baseExchange + pastOrder;
    const params = {
      "base_currency_id": base_currency_id,
      "quote_currency_id": quote_currency_id,
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  getUserTickets: async () => {
    const token = sessionStorage.getItem("token");
    const { baseSupport, getUserTickets } = ApiConfig;
    const url = baseSupport + getUserTickets;
    const headers = {
      "Content-Type": "application/json",
      'Authorization': token
    };

    return ApiCallGet(url, headers);
  },

  submitTicket: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseSupport, submitTicket } = ApiConfig;
    const url = baseSupport + submitTicket;
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': token
    };

    return ApiCallPost(url, formData, headers);
  },

  replyTicket: async (messagerply, id) => {
    const token = sessionStorage.getItem("token");
    const { baseSupport, replyTicket } = ApiConfig;
    const url = baseSupport + replyTicket;
    const params = {
      replyBy: 1,
      query: messagerply,
      ticket_id: id,

    }
    const headers = {
      "Content-Type": "application/json",
      'Authorization': token
    };

    return ApiCallPost(url, params, headers);
  },


  userTradeHistory: async (base_currency_id, quote_currency_id) => {
    const token = sessionStorage.getItem("token");
    const { baseAdmin, userTradeHistory } = ApiConfig;
    const url = baseAdmin + userTradeHistory;
    const params = {
      "base_currency_id": base_currency_id,
      "quote_currency_id": quote_currency_id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);

  },
  addkyc: async (formData) => {
    const token = sessionStorage.getItem('token');
    const { baseAuth, addkyc } = ApiConfig;
    const url = baseAuth + addkyc;

    const headers = {
      'Content-Type': 'multipart/form-data',
      "Authorization": token,
    };
    return ApiCallPost(url, formData, headers);
  },
  tradeHistory: async (skip, limit) => {
    const token = sessionStorage.getItem('token');
    const { baseTrans, tradeHistory } = ApiConfig;
    const url = baseTrans + tradeHistory;
    const params = {
      skip: skip,
      limit: limit
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },


  cancelOrder: async (orderId) => {
    const token = sessionStorage.getItem('token');
    const { baseExchange, cancelOrder } = ApiConfig;
    const url = baseExchange + cancelOrder;
    const params = {
      "order_id": orderId
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },


  getCode2: async (signid) => {
    const { baseAuth, getOtp } = ApiConfig;
    const url = baseAuth + getOtp;
    const params = {
      signId: signid,
      type: "forgot",
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  forgotPassword: async (signId, otp, password, cPassword) => {
    const { baseAuth, forgotpassword } = ApiConfig;
    const url = baseAuth + forgotpassword;
    const params = {
      email_or_phone: signId,
      verification_code: otp,
      new_password: password,
      confirm_password: cPassword,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  totalrefercount: async () => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, totalrefercount } = ApiConfig;
    const url = baseAuth + totalrefercount;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };
    return ApiCallGet(url, headers);
  },

  categoryList: async () => {
    const token = sessionStorage.getItem('token');
    const { baseAdmin, categoryList } = ApiConfig;
    const url = baseAdmin + categoryList;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  notificationList: async () => {
    const token = sessionStorage.getItem('token');
    const { baseAdmin, notificationList } = ApiConfig;
    const url = baseAdmin + notificationList;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  addOrderNotification: async (orderId) => {
    const token = sessionStorage.getItem('token');
    const { p2p, addOrderNotification } = ApiConfig;
    const url = p2p + addOrderNotification;

const params={
  order_id : orderId
}

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },


  coinDetails: async (currency_id) => {
    const token = sessionStorage.getItem('token');
    const { baseExchange, coinDetails } = ApiConfig;
    const url = baseExchange + coinDetails;
    const params = {
      "currency_id": currency_id
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  p2pBuyOrder: async (type) => {
    // const token = sessionStorage.getItem('token');
    const { p2p, p2pBuyOrder } = ApiConfig;
    const url = p2p + p2pBuyOrder;
    const params = {
      "short_name": type
    }
    const headers = {
      'Content-Type': 'application/json',
      // "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  // paymentMethod: async () => {
  //   const token = sessionStorage.getItem('token');
  //   const { p2p, paymentMethods } = ApiConfig;
  //   const url = p2p + paymentMethods;
  //   const headers = {
  //     'Content-Type': 'application/json',
  //     "Authorization": token,
  //   };
  //   return ApiCallGet(url, headers);
  // },

  p2pSellOrder: async (type) => {
    // const token = sessionStorage.getItem('token');
    const { p2p, p2pSellOrder } = ApiConfig;
    const url = p2p + p2pSellOrder;
    const params = {
      "short_name": type
    }
    const headers = {
      'Content-Type': 'application/json',
      // "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  p2pCounterCurrency: async(short_name) =>{
    const token = sessionStorage.getItem('token');
    const { p2p, p2pCounterCurrency } = ApiConfig;
    const url = p2p + p2pCounterCurrency;
    const params = {
      "short_name": short_name,
    }
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

 



  buyCurrency: async (payableAmount, receivableAmount, id, postAdId, timestamp, sellerSelectedPayment, method) => {
    const token = sessionStorage.getItem('token');
    const { p2p, buyCurrency } = ApiConfig;
    const url = p2p + buyCurrency;
    const params = {
      "amount": payableAmount,
      "receiving_amount": receivableAmount,
      "postAd_user": postAdId,
      "add_id": id,
      "payment_timestamp": timestamp,
      "payment_type" : sellerSelectedPayment,
      "payment_method": method,
    }
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  buyRequest: async (orderId) => {
    const token = sessionStorage.getItem('token');
    const { p2p, buyRequest } = ApiConfig;
    const url = p2p + buyRequest;
    const params = {
      "order_id": orderId
    }

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  notify: async (orderId) => {
    const token = sessionStorage.getItem('token');
    const { p2p, notify } = ApiConfig;
    const url = p2p + notify;
    const params = {
      "order_id": orderId
    }

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  notifySellerTrader: async (orderDetails, status, orderId) => {
    const token = sessionStorage.getItem('token');
    const { p2p, notifyMerchent } = ApiConfig;
    const url = p2p + notifyMerchent;
    const params = {
    order_id: orderId,
    trader_status : status,
    }

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  notifySellerMerchent: async (orderDetails, status, orderId) => {
    const token = sessionStorage.getItem('token');
    const { p2p, notifySeller } = ApiConfig;
    const url = p2p + notifySeller;
    const params = {
    order_id: orderId,
    merchant_status : status,
    }

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  addPaymentMethod: async (payment, userId) => {
    const token = sessionStorage.getItem('token');
    const { p2p, addPaymentMethod } = ApiConfig;
    const url = p2p + addPaymentMethod;
    const params = {
      "payment_method": payment,
      user_id: userId
    }

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  editPaymentMethod: async (payment) => {
    const token = sessionStorage.getItem('token');
    const { p2p, editPaymentMethod } = ApiConfig;
    const url = p2p + editPaymentMethod;
    const params = {
      "payment_method": payment,
      'payment_id': payment?.id
    }

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPut(url, params, headers);
  },


  fetchPaymentMethods: async () => {
    const token = sessionStorage.getItem('token');
    const { p2p, fetchPaymentMethods } = ApiConfig;
    const url = p2p + fetchPaymentMethods;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  fiatCurrencyList: async () => {
    const token = sessionStorage.getItem('token');
    const { p2p, fiatCurrencyList } = ApiConfig;
    const url = p2p + fiatCurrencyList;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  myAds: async () => {
    const token = sessionStorage.getItem('token');
    const { p2p, myAds } = ApiConfig;
    const url = p2p + myAds;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },


  myOrders: async () => {
    const token = sessionStorage.getItem('token');
    const { p2p, myOrders } = ApiConfig;
    const url = p2p + myOrders;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },


  setSecurity: async (oldpassword, password, conPassword) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, change_password } = ApiConfig;
    const url = baseAuth + change_password;
    const params = {
      old_password: oldpassword,
      new_password: password,
      confirm_password: conPassword,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },


  createNewPost: async (input, paymentMethod) => {
    const token = sessionStorage.getItem('token');
    const { p2p, createNewPost } = ApiConfig;
    const url = p2p + createNewPost;
    const params = {
      "base_currency": input?.asset,
      "quote_currency": input?.fiat,
      "side": input?.side,
      "price_type": input?.priceType,
      "fixed_price": input?.price,
      "payment_time": input?.time,
      // "volume": input?.volume,
      "min_amount": input?.minAmount,
      "max_amount": input?.maxAmount,
      "payment_method": paymentMethod,
      "kyc": input?.kyc,
      "totalTransactions": input?.transaction,
      "counterCurrency": input?.minCurrency,
      "currencyAmount": input?.currencyAmount,
      "registered_days" : +input?.regDays
      // "remark": input?.remarks
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },


  currentPrice: async (fiat, asset) => {
    const token = sessionStorage.getItem('token');
    const { p2p, currentPrice } = ApiConfig;
    const url = p2p + currentPrice;
    const params = {
      "base_currency": asset,
      "quote_currency": fiat
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },


  p2pCoinList: async () => {
    const token = sessionStorage.getItem('token');
    const { baseCoin, p2pCoinList } = ApiConfig;
    const url = baseCoin + p2pCoinList;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  runtime: async (status, orderId) =>{
    const { p2p, p2pRuntime } = ApiConfig;
    const url = p2p + p2pRuntime;
    const params = {
      status: status,
      order_id : orderId
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },


  favoriteCoin: async (pairId) => {
    const token = sessionStorage.getItem('token');
    const { baseAuth, favoriteCoin } = ApiConfig;
    const url = baseAuth + favoriteCoin;
    const params = {
      "pair_id": pairId
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);

  },
  favoriteList: async () => {
    const token = sessionStorage.getItem('token');
    const { baseAuth, favoriteList } = ApiConfig;
    const url = baseAuth + favoriteList;

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);

  },
  setCurrency: async (currencyType) => {
    const token = sessionStorage.getItem("token");
    const { baseAuth, setCurrency } = ApiConfig;

    const url = baseAuth + setCurrency;
    const params = {
      currency: currencyType,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPut(url, params, headers);
  },

  rateOrder: async (rating) => {
    const token = sessionStorage.getItem("token");
    const { p2p, rateOrder } = ApiConfig;

    const url = p2p + rateOrder;
    const params = {
      ratings: rating,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

};

export default AuthService;
