import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Col, Row } from "react-bootstrap";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from "../../UtilityComponent/CustomAlertMessage";
import { $ } from "react-jquery-plugin";
import { ProfileContext } from "../../Context";
import { usePagination, useSortBy, useTable } from "react-table";
import QRCode from 'qrcode.react';


const FundPage = () => {
  const [fundData, setfundData] = useState([]);
  const [estimatedportfolio, setEstimatedportfolio] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [selectedDeposit, setSelectDeposit] = useState("");
  const [selectedChain, setSelectedchain] = useState("");
  const [tradeHistory, settradeHistory] = useState([]);
  const [minDeposit, setminDeposit] = useState();
  const [updatedLength, setupdatedLength] = useState(5);
  const [walletHisDetail, setwalletHisDetail] = useState();
  const [tradeHisDetails, settradeHisDetails] = useState();
  const [currencyId, setcurrencyId] = useState();
  const [allChain, setallChain] = useState();
  const [walletAddress, setWalletAddress] = useState('');
  const [DepositHistory, setDepositHistory] = useState([]);
  const [WithdrawalHistory, setWithdrawalHistory] = useState([]);
  const [wihtdrawalDetails, setwihtdrawalDetails] = useState({ minWithdrawal: '', maxWithdrawal: '', withdrawalFee: '' });
  const [otp, setOtp] = useState('');
  const [skipCount, setSkipCount] = useState(0);
  const [limitCount, setLimitCount] = useState(0);
  const { email, mobile, handleUserDetials } = useContext(ProfileContext);
  const [paymentOption, setPaymentOption] = useState('');
  const [adminDetails, setadminDetails] = useState([]);
  const [showBank, setshowBank] = useState('');
  const [inrImage, setInrImage] = useState();
  const [txNumber, settxNumber] = useState('');
  const [inrAmount, setinrAmount] = useState("");
  const [upiDetails, setUpiDetails] = useState({});
  const [withdrawBank, setWithdrawBank] = useState('');
  const [bankdetails, setBankDetails] = useState([]);

  let FunctCheck = true;
  useEffect(() => {
    if (FunctCheck) {
      getUserfunds();
      VerifyDeposit();
      EstimatedPortfolio();
      handleUserDetials();
      AdminBankDetails();
      adminUpiDetails();
      GetBankDetails();
      FunctCheck = false;
    }

  }, []);

  const GetBankDetails = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getBankDetails().then(async result => {
      if (result?.sucess) {
        LoaderHelper.loaderStatus(false);
        setBankDetails(result?.data);
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result?.message);
      }
    });
  };


  const adminUpiDetails = async () => {
    await AuthService.adminUpiDetails().then(async result => {
      if (result?.success) {
        setUpiDetails(result?.data[0])
      }
      return
    });
  }

  const AdminBankDetails = async () => {
    await AuthService.adminBankDetails().then(async (result) => {
      if (result?.success) {
        setadminDetails(result?.data)
        setshowBank(result?.data[0]?.bank_name)
      }
      return
    });
  };
  // ********* Get OTP ********** //
  const handleGetOtp = async (signId, resend) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getOtp(signId, resend).then(async (result) => {
      if (result?.success) {
        startTimer();
        alertSuccessMessage(result?.message);
      }
    });
  };

  // ********* Funds Data ********** //
  const getUserfunds = async (orderId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getUserfunds(orderId).then((result) => {
      if (result?.success) {
        setfundData(result?.data)
      }
    })
  };

  // ********* Coin Deposit Address ********** //
  const getDepostAddress = async (currId, chain) => {
    setSelectedchain(chain);
    setSelectDeposit("");
    LoaderHelper.loaderStatus(true);
    await AuthService.generateAddress(currId, chain).then((result) => {
      if (result?.success) {
        setSelectDeposit(result?.data)
      } else {
        $('#Deposit_modal')?.modal('hide');
      }
    })
  };

  // ********* Estimated Portfolio********** //
  const EstimatedPortfolio = async () => {
    await AuthService.estimatedPortfolio().then((result) => {
      if (result?.success) {
        setEstimatedportfolio(result?.data)
      };
    })
  };


  // ********* Trade History ********** //
  const TradeHistory = async (skip, limit) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.tradeHistory(skip, limit).then((result) => {
      if (result?.success) {
        if (result?.data?.length > 0) {
          setSkipCount(skip);
          setLimitCount(limit);
          settradeHistory(result?.data);
          return;
        } else if (skip !== 0) {
          alertWarningMessage('No more data found')
          return;
        }
      }
    })
  };
  // ********* Deposit Wallet History ********** //
  const DepositWalletHistory = async (skip, limit) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.walletDepositHistory(skip, limit).then(async (result) => {
      if (result?.success) {
        if (result?.data?.length > 0) {
          setSkipCount(skip);
          setLimitCount(limit);
          setDepositHistory(result?.data);
          return;
        } else if (skip !== 0) {
          alertWarningMessage('No more data found')
        }
      }
    });
  };
  // ********* Deposit Wallet History ********** //
  const WithdrawalWalletHistory = async (skip, limit) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.walletWithdrawalHistory(skip, limit).then(async (result) => {
      if (result?.success) {
        if (result?.data?.length > 0) {
          setSkipCount(skip);
          setLimitCount(limit);
          setWithdrawalHistory(result?.data);
          return;
        } else if (skip !== 0) {
          alertWarningMessage('No more data found')
        }
      }
    });
  };

  // ********* Coin Min Deposit Data ********** //
  // const coinDetails = async (currId) => {
  //   await AuthService.coinDetails(currId).then((result) => {
  //     if (result?.success) {
  //       setminDeposit(result?.data?.min_deposit);
  //       wihtdrawalDetails.minWithdrawal = result?.data?.min_withdrawal
  //       wihtdrawalDetails.maxWithdrawal = result?.data?.max_withdrawal
  //       wihtdrawalDetails.withdrawalFee = result?.data?.withdrawal_fee
  //     }
  //   })
  // };
  const [selectedCoinData, setSelectedCoinData] = useState();
  const coinDetails = async (currId, type) => {
    await AuthService.coinDetails(currId).then((result) => {
      if (result?.success) {
        setminDeposit(result?.data?.min_deposit);
        wihtdrawalDetails.minWithdrawal = result?.data?.min_withdrawal
        wihtdrawalDetails.maxWithdrawal = result?.data?.max_withdrawal
        wihtdrawalDetails.withdrawalFee = result?.data?.withdrawal_fee
        setSelectedCoinData(result?.data)

        if (type === 'deposit') {
          if (result?.data?.deposit_status === "INACTIVE") {
            alertErrorMessage('Deposit is disabled for this Coin');
            return;
          } else {
            $("#Deposit_modal").modal("show");
          }
        }

        if (type === 'waithdrawal') {
          if (result?.data?.withdrawal_status === 'INACTIVE') {
            alertErrorMessage('Withdrawal is disabled for this Coin');
            return;
          } else {
            $("#Withdraw_modal").modal("show");
          }

        }
      }
    }
    )
  };

  // ********* Withdrawal Currency********** //
  const WithdrawalCurrency = async (currencyId, selectedChain, withdrawAmount, walletAddress, otp, signId, selectedCurrency) => {
    setOtp('');
    if (!email) {
      alertErrorMessage('Please update your email in Profile section for Withdrawal');
      return
    }
    LoaderHelper.loaderStatus(true);
    await AuthService.withdrawalCurrency(currencyId, selectedChain, withdrawAmount, walletAddress, otp, signId, selectedCurrency).then((result) => {
      if (result?.success) {
        getUserfunds()
        alertSuccessMessage(result?.message);
      }
    })
  };
  //******** Auto Call Verify -Deposit after evry 5 minute **********//
  const VerifyDeposit = async () => {
    const ref = window.location.href.split("/");
    let timeout = setTimeout(VerifyDeposit, 300000)
    ref[ref.length - 1] !== 'FundPage' && clearTimeout(timeout);
    await AuthService.verifyDeposit().then(async (result) => {
      if (result?.success) {

      }
    });
  };
  //******** Wallet Address Validation **********//
  const [CheckWalletAddress, setCheckWalletAddress] = useState(false);
  const handleWalletAddress = (e) => {
    setCheckWalletAddress(true);
    setWalletAddress(e.target.value)
    if (selectedChain === 'BEP20' || selectedChain === 'RIK') {
      const bep20Regex = /^(0x)?[0-9a-fA-F]{40}$/;
      if (bep20Regex.test(e.target.value)) {
        setCheckWalletAddress(false);
      }
    } else if (selectedChain === 'TRC20') {
      const trc20Regex = /^(T|t)[A-Za-z1-9]{33}$/;
      if (trc20Regex.test(e.target.value)) {
        setCheckWalletAddress(false);
      }
    }
  };

  //****** */ Function to start the timer and disable the button***********//
  const [timer, setTimer] = useState(30);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const startTimer = () => {
    setIsButtonDisabled(true);
    setTimer(60);
  };
  useEffect(() => {
    let interval;
    if (timer > 0 && isButtonDisabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setIsButtonDisabled(false);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer, isButtonDisabled]);


  // ******* React Table Implementation Trade History *********** //
  const data = React.useMemo(() => tradeHistory, [tradeHistory]);
  const columns = React.useMemo(() => [
    {
      Header: "Sr No.", accessor: "_id", disableSortBy: true, Cell: ({ row }) => {
        const { index } = row; return index + skipCount + 1
      }
    },
    { Header: "Date/Time", accessor: "updatedAt", disableSortBy: true, Cell: ({ row }) => { return <Moment date={row?.values?.updatedAt} format="DD-MM-YYYY, h:mm:ss" /> } },
    { Header: "Trading Currency", accessor: "currency", disableSortBy: true, },
    { Header: "Side", accessor: "side", disableSortBy: true, },
    { Header: "Order Type", accessor: "order_type", disableSortBy: true, },
    { Header: "Price", accessor: "price", Cell: ({ row }) => { return parseFloat(row?.values?.price) } },
    { Header: "Executed", accessor: "quantity", Cell: ({ row }) => { return parseFloat(row?.values?.quantity?.toFixed(8)) } },
    { Header: "Total", accessor: "Total", disableSortBy: true, Cell: ({ row }) => { return parseFloat((row?.values?.price * row?.values?.quantity).toFixed(5)) } },
    { Header: "Transaction Type ", accessor: "transaction_type", disableSortBy: true, },
    { Header: "Fee", accessor: "fee", Cell: ({ row }) => { return parseFloat(row?.values?.fee?.toFixed(8)) } },
  ], [skipCount]);

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable({ columns, data }, useSortBy, usePagination);


  // ******* React Table Implementation Deposit Wallet History *********** //
  const data1 = React.useMemo(() => DepositHistory, [DepositHistory]);
  const columns1 = React.useMemo(() => [
    {
      Header: "Sr No.", accessor: "_id", disableSortBy: true, Cell: ({ row }) => {
        const { index } = row; return index + skipCount + 1
      }
    },
    { Header: "Date/Time", accessor: "updatedAt", disableSortBy: true, Cell: ({ row }) => { return <Moment date={row?.values?.updatedAt} format="DD-MM-YYYY, h:mm:ss" /> } },
    { Header: "Coin", accessor: "short_name", disableSortBy: true, },
    { Header: "Amount", accessor: "amount", Cell: ({ row }) => { return parseFloat(row?.values?.amount) } },
    { Header: "Transaction Type", accessor: "transaction_type", disableSortBy: true, },
    { Header: "Status", accessor: "status", disableSortBy: true },
  ], [skipCount]);

  const { getTableProps: getTableProps1, getTableBodyProps: getTableBodyProps1, headerGroups: headerGroups1, page: page1, prepareRow: prepareRow1 } = useTable({ columns: columns1, data: data1 }, useSortBy, usePagination);

  // ******* React Table Implementation Withdrwal Wallet History *********** //
  const data2 = React.useMemo(() => WithdrawalHistory, [WithdrawalHistory]);
  const columns2 = React.useMemo(() => [
    {
      Header: "Sr No.", accessor: "_id", disableSortBy: true, Cell: ({ row }) => {
        const { index } = row; return index + skipCount + 1
      }
    },
    { Header: "Date/Time", accessor: "updatedAt", disableSortBy: true, Cell: ({ row }) => { return <Moment date={row?.values?.updatedAt} format="DD-MM-YYYY, h:mm:ss" /> } },
    { Header: "Coin", accessor: "short_name", disableSortBy: true, },
    { Header: "Amount", accessor: "amount", Cell: ({ row }) => { return parseFloat(row?.values?.amount) } },
    { Header: "Transaction Type", accessor: "transaction_type", disableSortBy: true, },
    { Header: "Status", accessor: "status", disableSortBy: true },
  ], [skipCount]);

  const { getTableProps: getTableProps2, getTableBodyProps: getTableBodyProps2, headerGroups: headerGroups2, page: page2, prepareRow: prepareRow2 } = useTable({ columns: columns2, data: data2 }, useSortBy, usePagination);

  const HandleINRImage = (e) => {
    let ImageValidation = CheckValidImage(e);
    if (ImageValidation) {
      setInrImage(e.target.files[0])
    } else {
      setInrImage()
    }
  }
  const CheckValidImage = (event) => {
    if (!event.target.files[0]) {
      return false;
    }
    if (event.target.files[0].size < 2000000 && (event.target.files[0].type === "image/png" || event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/jpg")) {
      return true
    } else {
      alertWarningMessage('Only JPEG, PNG & JPG formats and file size upto 2MB are supported')
    }
  };

  const HandleDepositInr = async (inrAmount, txNumber, inrImage, paymentOption, bankName) => {
    LoaderHelper.loaderStatus(true);
    let filteredBank = adminDetails?.filter((item) => {
      return item?.bank_name === bankName
    })
    var formData = new FormData();
    formData.append("amount", inrAmount);
    formData.append("transaction_number", txNumber);
    formData.append("deposit_slip", inrImage);
    formData.append("payment_type", paymentOption);
    paymentOption === 'NEFT' && formData.append("admin_bank_details", filteredBank ? JSON.stringify(filteredBank[0]) : '');
    await AuthService.depositInr(formData).then((result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        alertSuccessMessage(result?.message);
        setinrAmount('');
        settxNumber('');
        setInrImage();
        $('#Deposit_modal_INR').modal('hide');
      }
      else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result?.message)
      }
    });
  };
  const openDepositINR = () => {
    $("#Deposit_modal").modal("hide");
    $("#Deposit_modal_INR").modal("show");
  }

  const HandleInrWithdrawal = async (withdrawAmount, withdrawBank) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.withdrawInr(withdrawAmount, withdrawBank).then((result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        alertSuccessMessage(result?.message)
        $('#Withdraw_modal3').modal('hide');
      }
      else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result?.message)
      }
    });
  };

  const transferHandler = async(selectedCurrency, withdrawAmount) =>{
    const result = await AuthService.transferToP2P(selectedCurrency, withdrawAmount)  
    if(result.success) {
        alertSuccessMessage(result.message)
        getUserfunds();
    } else{
        alertErrorMessage(result.message)
    }     
  }

  return (
    <>
      <section className="inner-page-banner">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title">Wallet</h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Wallet</li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container">
          <div className="d-flex-between mb-5 custom_dlflex">
            <ul className="nav custom-tabs overflowx_scroll funds_tab shadow-soft">
              <li><a className=" active" data-bs-toggle="tab" href="#funds" onClick={getUserfunds}>Funds</a></li>
              <li><a data-bs-toggle="tab" href="#tt_history" onClick={() => { DepositWalletHistory(0, 10); setSkipCount(0); setLimitCount(0) }}> Deposit History</a> </li>
              <li><a data-bs-toggle="tab" href="#withdrwal_history" onClick={() => { WithdrawalWalletHistory(0, 10); setSkipCount(0); setLimitCount(0) }}> Withdrawal History</a> </li>
              <li><a data-bs-toggle="tab" href="#tradehistory" onClick={() => { TradeHistory(0, 10); setSkipCount(0); setLimitCount(0) }}>Trade History</a></li>
            </ul>
            <div className="d-flex-between bidder shadow-soft_inner">
              <span>Estimated Portfolio : </span>
              <div className="d-flex-center ms-1">
                <span className="text-white">{estimatedportfolio}</span>
              </div>
            </div>
          </div>
          <div className="tab-content custom-tab-content py-0 shadow-soft">
            <div className="tab-pane fade show container active   " id="funds">
              <div className="table-responsive">
                <table className="table mb-0">
                  <thead  >
                    <tr>
                      <th>Sr No.</th>
                      <th>ASSETS</th>
                      <th>Available Balance</th>
                      <th>Locked</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fundData?.length > 0 ? (
                      fundData?.slice(0, updatedLength)?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.short_name}</td>
                          <td>{parseFloat(item?.balance.toFixed(8))}</td>
                          <td>{parseFloat(item?.locked_balance.toFixed(8))}</td>
                          <td>
                            {parseFloat(
                              +item?.balance + +item?.locked_balance
                                .toFixed(8))}
                          </td>
                          <td>
                            <a href="#/" className=" tb_btn badge badge-success mx-1" onClick={() => {
                              coinDetails(item?.currency_id, 'deposit'); setSelectedCurrency(item.short_name); setallChain(item.chain); getDepostAddress(item.currency_id, item.chain[0]); setcurrencyId(item.currency_id);
                            }}>
                              Deposit
                            </a>
                            <a href="#/" className=" tb_btn  badge badge-danger  mx-1" onClick={() => { setcurrencyId(item.currency_id); setallChain(item.chain); setSelectedCurrency(item.short_name); setSelectedchain(item.chain[0]); setWalletAddress(''); setWithdrawAmount(''); coinDetails(item?.currency_id, 'waithdrawal'); setCheckWalletAddress(false); setOtp('') }}>
                              Withdraw
                            </a>
                            <a href="#/" className=" tb_btn  badge badge-danger  mx-1" onClick={() => {setSelectedCurrency(item.short_name);  $("#p2p_modal").modal("show");}}>
                            Transfer to P2P Wallet
                            </a>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr rowSpan="5">
                        <td colSpan="12">
                          <p className="text-center" style={{ textAlign: "center" }}>
                            No Data Available
                          </p>
                        </td>
                      </tr>
                    )}
                    {(fundData?.length > 0 && updatedLength < fundData?.length) &&
                      <tr rowSpan="5">
                        <td colSpan="12" className="cursor-pointer loadMore" >
                          <p className="text-center cursor-pointer mb-0" style={{ cursor: 'pointer' }} onClick={() => {
                            setupdatedLength(updatedLength + 5); window.scrollTo({
                              top: document.documentElement.scrollHeight,
                              left: 0,
                              behavior: "smooth"
                            })
                          }} >
                            Load More...
                          </p>
                        </td>
                      </tr>}
                  </tbody>
                </table>

              </div>
            </div>

            {/* Deposit History */}
            <div className="tab-pane container fade  " id="tt_history">
              <div className="table-responsive">
                <><table className="table mb-0"  {...getTableProps1()}>
                  <thead   >
                    {headerGroups1.map((headerGroup, index) => (
                      <tr key={index} {...headerGroup.getHeaderGroupProps()} >
                        {headerGroup.headers.map((column, index) => (
                          <th key={index}  {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {(column.Header === 'Amount') && <i className="ri-arrow-up-down-fill ms-1" style={{ color: column.isSorted ? (column.isSortedDesc ? '#ff00009c' : 'green') : '' }}></i>}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps1()}>
                    {page1.map((row, index) => {
                      prepareRow1(row);
                      return (
                        <tr key={index} {...row.getRowProps()}>
                          {row.cells.map((cell, index) => (
                            <td key={index} {...cell.getCellProps()} className="cursor_pointer" data-bs-toggle="modal" data-bs-target="#wallet_history" onClick={() => { setwalletHisDetail(row?.original) }}>{cell.render("Cell")} </td>
                          ))}
                        </tr>
                      );
                    })}
                    {DepositHistory?.length === 0 && <tr rowSpan="5">
                      <td colSpan="12">
                        <p className="text-center" style={{ textAlign: "center" }}>
                          No Data Available
                        </p>
                      </td>
                    </tr>}
                  </tbody>
                </table></>
                {DepositHistory?.length !== 0 &&
                  <div className="container pt-3 pb-4  table_control" >
                    <div className=" row align-items-center " >
                      <div className=" row align-items-center justify-content-end" >
                        <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                          <input type="button" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" disabled={skipCount <= 1} />
                          <label className="btn " htmlFor="btnradio250" onClick={() => { DepositWalletHistory(skipCount - 10, limitCount - 10) }}>Previous</label>

                          <input type="button" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off" />
                          <label className="btn " htmlFor="btnradio375" disabled onClick={() => { DepositWalletHistory(skipCount + 10, limitCount + 10) }}>Next</label>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>
            </div>
            {/* Withdrawal History */}
            <div className="tab-pane container fade " id="withdrwal_history">
              <div className="table-responsive">
                <><table className="table a"  {...getTableProps2()}>
                  <thead   >
                    {headerGroups2.map((headerGroup, index) => (
                      <tr key={index} {...headerGroup.getHeaderGroupProps()} >
                        {headerGroup.headers.map((column, index) => (
                          <th key={index}  {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {(column.Header === 'Amount') && <i className="ri-arrow-up-down-fill ms-1" style={{ color: column.isSorted ? (column.isSortedDesc ? '#ff00009c' : 'green') : '' }}></i>}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps2()}>
                    {page2.map((row, index) => {
                      prepareRow2(row);
                      return (
                        <tr key={index} {...row.getRowProps()}>
                          {row.cells.map((cell, index) => (
                            <td key={index} {...cell.getCellProps()} className="cursor_pointer" data-bs-toggle="modal" data-bs-target="#wallet_history" onClick={() => { setwalletHisDetail(row?.original) }}>{cell.render("Cell")} </td>
                          ))}
                        </tr>
                      );
                    })}
                    {WithdrawalHistory?.length === 0 && <tr rowSpan="5">
                      <td colSpan="12">
                        <p className="text-center" style={{ textAlign: "center" }}>
                          No Data Available
                        </p>
                      </td>
                    </tr>}
                  </tbody>
                </table></>
                {WithdrawalHistory?.length !== 0 &&
                  <div className="container pt-3 pb-4  table_control" >
                    <div className=" row align-items-center " >
                      <div className=" row align-items-center justify-content-end" >
                        <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                          <input type="button" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" disabled={skipCount <= 1} />
                          <label className="btn " htmlFor="btnradio250" onClick={() => { WithdrawalWalletHistory(skipCount - 10, limitCount - 10) }}>Previous</label>

                          <input type="button" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off" />
                          <label className="btn " htmlFor="btnradio375" disabled onClick={() => { WithdrawalWalletHistory(skipCount + 10, limitCount + 10) }}>Next</label>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>
            </div>

            {/* *****Trade History******** */}
            <div className="tab-pane container fade  " id="tradehistory">
              <div className="table-responsive">
                <><table className="table a"  {...getTableProps()}>
                  <thead   >
                    {headerGroups.map((headerGroup, index) => (
                      <tr key={index} {...headerGroup.getHeaderGroupProps()} >
                        {headerGroup.headers.map((column, index) => (
                          <th key={index} {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {(column.Header === 'Price' || column.Header === 'Fee' || column.Header === 'Executed') && <i className="ri-arrow-up-down-fill ms-1" style={{ color: column.isSorted ? (column.isSortedDesc ? '#ff00009c' : 'green') : '' }}></i>}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                      prepareRow(row);
                      return (
                        <tr key={index} {...row.getRowProps()}>
                          {row.cells.map((cell, index) => (
                            <td key={index} {...cell.getCellProps()} className="cursor_pointer" data-bs-toggle="modal" data-bs-target="#trade_history" onClick={() => { settradeHisDetails(row?.original) }}>{cell.render("Cell")} </td>
                          ))}
                        </tr>
                      );
                    })}
                    {tradeHistory?.length === 0 && <tr rowSpan="5">
                      <td colSpan="12">
                        <p className="text-center" style={{ textAlign: "center" }}>
                          No Data Available
                        </p>
                      </td>
                    </tr>}
                  </tbody>

                </table></>
                {tradeHistory?.length !== 0 &&
                  <div className="container pt-3 pb-4  table_control" >
                    <div className=" row align-items-center justify-content-end" >
                      <div className="col-md-4" >
                        <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                          <input type="button" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" disabled={skipCount <= 1} />
                          <label className="btn " htmlFor="btnradio250" onClick={() => { TradeHistory(skipCount - 10, limitCount - 10) }}>Previous</label>
                          <input type="button" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off" />
                          <label className="btn " htmlFor="btnradio375" disabled onClick={() => { TradeHistory(skipCount + 10, limitCount + 10) }}>Next</label>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </section >

      {/* Deposit Modal All currencies  */}
      <div className="modal fade" id="Deposit_modal" tabIndex="-1" aria-labelledby="Deposit_modalLaebl" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">
                Deposit Funds
              </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i className="ri-close-fill"></i>
              </button>
            </div>
            <div className="modal-body px-8 py-5">
              <form action="#">
                <>
                  {selectedCurrency === "INR" ?
                    <>
                      <div className="p-3" >
                        <div className="row justify-content-center" >
                          <div className="col-lg-12" >
                            <div className="input-group" >
                              <select value={paymentOption} onChange={(e) => setPaymentOption(e.target.value)}>
                                <option selected disabled value=''>Select Payment Method</option>
                                <option value="NEFT">Payment Via NEFT</option>
                                <option value="UPI">Payment Via UPI</option>
                              </select>
                            </div>
                            <div className="form-group mt-4">
                              <button type="button" className="btn btn-gradient btn-small w-100 justify-content-center" onClick={openDepositINR} disabled={!paymentOption} >
                                <span>Next</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </> :

                    <div id="bnm">
                      <div className="btn-group btn_radio_group d-flex justify-content-center align-items-center m-auto " role="group" aria-label="Basic radio toggle button group">
                        {allChain ? allChain?.map((item, index) => {
                          return (
                            <button key={index} type="button" className={`btn btn-outline-primary ${selectedChain === item && "active"}`} htmlFor={item} onClick={() => { getDepostAddress(currencyId, item) }}>
                              {item}
                            </button>)
                        }) : ''}
                      </div>
                      <img alt="" src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${selectedDeposit}&choe=UTF-8`}
                        className="qr_img img-fluid" />
                      <input className="shareUrl-input copy_url js-shareUrl text-center" type="text" readOnly defaultValue={selectedDeposit} onClick={() => { navigator.clipboard.writeText(selectedDeposit); alertSuccessMessage('Copied!!') }}
                      />
                      <small className="text-center d-block mtb-2">
                        Click above to copy the Code.
                      </small>
                      <ul className="disclaimer mt-3">
                        <label>Disclaimer</label>
                        <li>
                          • Minimum deposit of {minDeposit} {selectedCurrency}, deposit
                          below that cannot be recovered.
                        </li>
                        <li>
                          • Please deposit only {selectedCurrency} on this
                          address. If you deposit any other coin, it will be
                          lost forever.
                        </li>
                        <li>
                          • This is {selectedChain} deposit address type.
                          Transferring to an unsupported network could result in
                          loss of deposit.
                        </li>
                      </ul>
                    </div>
                  }
                </>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Deposit Modal INR  */}
      <div class="modal fade" id="Deposit_modal_INR" tabindex="-1" aria-labelledby="Deposit_modalLaebl" aria-hidden="true" >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header flex-column px-8">
              <h3 class="modal-title" id="placeBitLaebl">
                Deposit Funds
              </h3>
              <button type="button" class="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i class="ri-close-fill"></i>
              </button>
            </div>

            <div class="modal-body px-8 pt-2 pb-4">
              <form action="#">
                <>
                  {paymentOption === 'NEFT' ? <>
                    <div className="p-1 pt-2" >
                      <div className="input-group mb-3" >
                        <select value={showBank} onChange={(e) => setshowBank(e.target.value)}>
                          {adminDetails?.map((item) => {
                            return (
                              <option value={item?.bank_name}>{item?.bank_name}</option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                    <h6 class="flex_amount mb-3"> Bank Account Details</h6>
                    <hr />
                    {adminDetails?.map((item) => {
                      return (
                        item?.bank_name === showBank &&
                        <div class="flex_amount mb-3 mt-2">
                          <div class="tt_data">
                            <div class="tt_item ">
                              <strong>Bank Name :</strong> {item?.bank_name}
                            </div>
                          </div>
                          <div class="tt_data">
                            <div class="tt_item ">
                              <strong>Bank Account Number : </strong> {item?.account_number}
                            </div>
                          </div>
                          <div class="tt_data">
                            <div class="tt_item ">
                              <strong>Account Holder Name :</strong> {item?.holder_name}
                            </div>
                          </div>
                          <div class="tt_data">
                            <div class="tt_item ">
                              <strong>IFSC Code :</strong> {item?.ifsc}
                            </div>
                          </div>
                        </div>
                      )
                    })}

                  </> : <>
                    <h6 class="flex_amount mb-3 mt-2">UPI Details</h6>
                    <hr />
                    <div class="flex_amount mb-0">
                      <div class="tt_data">
                        <div class="tt_item ">
                          <strong>UPI ID :</strong>{upiDetails?.upi_id}
                        </div>
                      </div>
                      <div class="tt_data mb-3">
                        <div class="tt_item justify-content-center flex-column align-items-center gap-2 ">
                          <div className="qr_upi "> <QRCode value={`upi://pay?pa=${upiDetails?.upi_id}&pn=CharlieExchange`} /></div>
                          <strong>Scan QR</strong>
                        </div>
                      </div>
                    </div>
                  </>}

                  <div className="form-group mb-4">
                    <input type="number" name="inrAmount" value={inrAmount} placeholder="Amount" onWheel={(e) => { e.target.blur() }} onChange={(e) => { setinrAmount(e.target.value) }} />
                  </div>
                  <div className="form-group mb-4">
                    <input type="text" name="inrAmount" value={txNumber} placeholder="Transaction Number" onChange={(e) => { settxNumber(e.target.value) }} />
                  </div>
                  <div className="row">
                    <div className="col-md-12 upload-area">
                      <div className="brows-file-wrapper">
                        <input name="file" className="inputfile" type="file" onChange={(e) => { HandleINRImage(e) }} />
                        {!inrImage ?
                          <label for="file" title="No File Choosen" style={{ height: "167px" }}>
                            <i className="ri-upload-cloud-line"></i>
                            <span className="text-center mb-2">
                              Choose a File
                            </span>
                            <span className="file-type text-center mt--10">
                              Drag or choose your file to upload
                            </span>
                          </label>
                          :
                          <label for="file" title="No File Choosen" style={{ height: "167px" }} >
                            <i className=" text-success ri-check-double-fill"></i>
                            <span className="text-center mb-2">
                              File Uploaded
                            </span>
                            <span className="file-type text-center mt--10">
                              {inrImage.name}
                            </span>
                          </label>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="form-group mt-4 ">
                    <button type="button" className="btn btn-gradient btn-small w-100 justify-content-center mb-4" disabled={!inrAmount || !txNumber || !inrImage} onClick={() => { HandleDepositInr(inrAmount, txNumber, inrImage, paymentOption, showBank) }}>
                      <span>Deposit INR</span>
                    </button>
                    <small class="mt-1 d-block text-center">
                      {/* <span className="onceDeposit">
                        Once Deposit It will Take Minimum Two Hours for
                        Confirm
                      </span> */}
                    </small>
                  </div>
                </>

              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Withdrwal modal first  */}
      <div class="modal fade" id="Withdraw_modal" tabindex="-1" aria-labelledby="Withdraw_modalLaebl" aria-hidden="true" >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header flex-column px-8">
              <h3 class="modal-title" id="placeBitLaebl">
                Withdraw Funds
              </h3>
              <button type="button" class="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i class="ri-close-fill"></i>
              </button>
            </div>
            <div class="modal-body px-8 py-4">
              {selectedCurrency === "INR" ? (
                <>
                  <div className="form-group mb-4">
                    <input className="" type="number" name="withdrawAmount" value={withdrawAmount} placeholder="Amount" onChange={(e) => { setWithdrawAmount(e.target.value) }} onWheel={(e) => e.target.blur()} />
                  </div>
                  <div className="input-group" >
                    <select className="" value={withdrawBank} onChange={(e) => setWithdrawBank(e.target.value)}>
                      <option selected disabled value=''>Select Bank</option>
                      {bankdetails ? bankdetails?.map((item) => {
                        return (
                          item?.verified === 1 &&
                          <option value={JSON.stringify(item)}>{item?.bank_name}</option>
                        )
                      }) : ''}
                    </select>
                  </div>
                  <div className="form-group mb-4 mt-3">
                    <button type="button" data-bs-dismiss="modal" className="btn btn-gradient btn-small w-100 justify-content-center" data-bs-toggle="modal" data-bs-target="#Withdraw_modal3" disabled={!withdrawAmount || !withdrawBank}>
                      <span>Withdraw INR</span>
                    </button>
                  </div>
                  <ul className="disclaimer mt-3">
                    <li>
                      • Withdrawal will be processed after 3 hours & in bank working hours.
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  <div className="btn-group btn_radio_group d-flex justify-content-center align-items-center m-auto " role="group" aria-label="Basic radio toggle button group">
                    {allChain ? allChain?.map((item) => {
                      return (
                        <button type="button" className={`btn  btn-outline-primary ${selectedChain === item && "active"}`} for={item} onClick={() => { setSelectedchain(item) }}>
                          {item}
                        </button>)
                    }) : ''}
                  </div>
                  <div className="form-group mb-4">
                    <input className="" type="text" name="wallet_Add" value={walletAddress} placeholder="Wallet Address" onChange={(e) => setWalletAddress(e.target.value)} />
                  </div>
                  <div className="form-group mb-4">
                    <input className="" type="number" name="amount_val" value={withdrawAmount} placeholder="Amount" onChange={(e) => setWithdrawAmount(e.target.value)} onWheel={(e) => e.target.blur()} />
                  </div>
                  <div className="field-otp-box mb-4">
                    <input className="" type="number" name="wallet_Add" value={otp} placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)} onWheel={(e) => { e.target.blur() }} />
                    <button type="btn" className=" btn-xs  btn btn-gradient " disabled={isButtonDisabled} onClick={() => handleGetOtp(email ? email : mobile, true)}>
                      <span> {isButtonDisabled ? `Resend OTP in ${timer} sec` : 'Get OTP'}</span>
                    </button>
                    <small className="d-block text-center">OTP will sent to {email ? email : mobile}</small>
                  </div>
                  <ul className="disclaimer mt-3">
                    <label>Disclaimer</label>
                    <li>
                      • Minimum withdrawal is {selectedCoinData?.min_withdrawal} {selectedCurrency}.
                    </li>
                    <li>
                      • Maximum withdrawal is {selectedCoinData?.max_withdrawal} {selectedCurrency}.
                    </li>

                  </ul>
                  <div className="form-group mb-4">
                    <button type="button" className="btn btn-gradient btn-small w-100 justify-content-center" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#Withdraw_modal2" disabled={!walletAddress || !withdrawAmount}>
                      <span>Withdraw</span>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>


      {/* Withdrawal Modal 2 INR*/}
      <div class="modal fade" id="Withdraw_modal3" tabindex="-1" aria-labelledby="Withdraw_modalLaebl" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header flex-column px-8">
              <h3 class="modal-title" id="placeBitLaebl">
                Confirm
              </h3>
              <hr />
              <button type="button" class="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i class="ri-close-fill"></i>
              </button>
            </div>
            <div class="modal-body px-8 py-2">
              <>

                <div className="form-group mb-4">
                  <h4>
                    You are going to withdraw {selectedCurrency}{" "}
                    {withdrawAmount} , please confirm your withdraw by clicking
                    'Confirm' button below.
                  </h4>
                </div>
                <Row>
                  <Col>
                    <div className="form-group">
                      <button type="button" className="btn btn-danger btn-small w-100 justify-content-center" data-bs-dismiss="modal">
                        <span>Cancel</span>
                      </button>
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group ">
                      <button type="button" className="btn btn-success btn-small w-100 justify-content-center" onClick={() => { HandleInrWithdrawal(withdrawAmount, withdrawBank) }}>
                        <span>Confirm</span>
                      </button>
                    </div>
                  </Col>
                </Row>
              </>
            </div>
          </div>
        </div>
      </div>

      {/* Withdrawal Modal 2 other currecny*/}
      <div className="modal fade" id="Withdraw_modal2" tabIndex="-1" aria-labelledby="Withdraw_modalLaebl" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">
                Confirm
              </h3>
              <hr />
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close" >
                <i className="ri-close-fill"></i>
              </button>
            </div>
            <div className="modal-body px-8 p-2">
              <>
                <div className="form-group mb-4">
                  <h4>
                    You are going to withdraw {selectedCurrency}{" "}
                    {withdrawAmount},
                    please confirm your withdraw by clicking 'Confirm' button
                    below.
                  </h4>
                </div>
                <Row>
                  <Col>
                    <div className="form-group mb-4">
                      <button type="button" className="btn btn-danger btn-small w-100 justify-content-center" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#Withdraw_modal2" >
                        <span>Cancel</span>
                      </button>
                    </div>
                  </Col>

                  <Col>
                    <div className="form-group mb-4">
                      <button type="button" className="btn btn-success btn-small w-100 justify-content-center" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#Withdraw_modal2" onClick={() => { WithdrawalCurrency(currencyId, selectedChain, withdrawAmount, walletAddress, otp, email ? email : mobile, selectedCurrency) }}>
                        <span>Confirm</span>
                      </button>
                    </div>
                  </Col>
                </Row>
              </>
            </div>
          </div>
        </div>
      </div>


       {/* Transfer to p2p modal  */}
      <div class="modal fade" id="p2p_modal" tabindex="-1" aria-labelledby="p2p_modalLaebl" aria-hidden="true" >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header flex-column px-8">
              <h3 class="modal-title" id="placeBitLaebl">
                Transfer Funds
              </h3>
              <button type="button" class="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i class="ri-close-fill"></i>
              </button>
            </div>
            <div class="modal-body px-8 py-4">         
                <>
                  <div className="form-group mb-4">
                    <input className="" type="text" value={selectedCurrency} disabled />
                  </div>
                  <div className="form-group mb-4">
                    <input className="" type="number" name="amount_val" value={withdrawAmount} placeholder="Amount" onChange={(e) => setWithdrawAmount(e.target.value)} onWheel={(e) => e.target.blur()} />
                  </div>
                  <div className="form-group mb-4">
                    <button type="button" className="btn btn-gradient btn-small w-100 justify-content-center" data-bs-dismiss="modal" onClick={()=> transferHandler(selectedCurrency, withdrawAmount)} disabled={!withdrawAmount}>
                      <span>Transfer</span>
                    </button>
                  </div>
                </>
              
            </div>
          </div>
        </div>
      </div>

      {/* TradeHistory modal */}
      <div className="modal fade" id="trade_history" tabIndex="-1" aria-labelledby="transfer_history" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">
                Trade Details
              </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i className="ri-close-fill"></i>
              </button>
            </div>
            <div className="modal-body px-8 py-5 body_history">
              <div className="tt_data">
                <div className="tt_item ">
                  <span className="tt_disable">Date &amp; Time</span>
                  <span className="tt_normal">
                    <b><Moment date={tradeHisDetails?.updatedAt} format='MMMM Do YYYY, h:mm:ss a' /> </b>
                  </span>
                </div>
                <div className="tt_item">
                  <span className="tt_disable">Currency</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.currency}</b>
                  </span>
                </div>
              </div>
              <div className="tt_item ">
                <span className="tt_disable">Side</span>
                <span className="tt_normal">
                  <b>{tradeHisDetails?.side}</b>
                </span>
              </div>

              <div className="tt_data">
                <div className="tt_item">
                  <span className="tt_disable">Transaction Type</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.transaction_type}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">
                    Fee
                  </span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.fee}</b>
                  </span>
                </div>

                <div className="tt_item ">
                  <span className="tt_disable">Fee Type</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.fee_type}</b>
                  </span>
                </div>
                <div className="tt_item">
                  <span className="tt_disable">Amount</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.amount}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">Order Type</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.order_type}</b>
                  </span>
                </div>

                <div className="tt_item ">
                  <span className="tt_disable">Quantity</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.quantity}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">Order Id</span>
                  <span className="tt_normal">
                    <b>{tradeHisDetails?.order_id}</b>
                  </span>
                </div>


                <div className="tt_item ">
                  <span className="tt_disable">Remarks</span>
                  <span className="tt_normal">
                    <b>Inoutbit Exchange</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Wallet History modal */}
      <div className="modal fade" id="wallet_history" tabIndex="-1" aria-labelledby="transfer_history" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">
                Transaction Details
              </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i className="ri-close-fill"></i>
              </button>
            </div>
            <div className="modal-body px-8 py-5 body_history">
              <div className="tt_data">
                <div className="tt_item">
                  <span className="tt_disable">Date/Time</span>
                  <span className="tt_normal">
                    <b><Moment date={walletHisDetail?.updatedAt} format='MMMM Do YYYY, h:mm:ss A' /></b>
                  </span>
                </div>
              </div>
              <div className="tt_data">
                <div className="tt_item">
                  <span className="tt_disable">Coin</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.short_name}</b>
                  </span>
                </div>
              </div>
              <div className="tt_item ">
                <span className="tt_disable">Status</span>
                <span className="tt_normal">
                  <b>{walletHisDetail?.status}</b>
                </span>
              </div>

              <div className="tt_data">
                <div className="tt_item">
                  <span className="tt_disable">Transaction Type</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.transaction_type}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">
                    Fee<small> (Incl.of all applicable taxes)</small>
                  </span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.fee}</b>
                  </span>
                </div>
                <div className="tt_item">
                  <span className="tt_disable">Amount</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.amount}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">From Address</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.from_address}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">To Address</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.to_address}</b>
                  </span>
                </div>

                <div className="tt_item ">
                  <span className="tt_disable">Transaction Hash</span>
                  <span className="tt_normal">
                    <b>{walletHisDetail?.transaction_hash}</b>
                  </span>
                </div>
                <div className="tt_item ">
                  <span className="tt_disable">Date &amp; Time</span>
                  <span className="tt_normal">
                    <b>{moment(walletHisDetail?.updatedAt).format("MMMM Do YYYY, h:mm:ss a")} </b>
                  </span>
                </div>

                <div className="tt_item ">
                  <span className="tt_disable">Remarks</span>
                  <span className="tt_normal">
                    <b>Inoutbit Exchange</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FundPage;
