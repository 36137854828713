import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Col, Row } from "react-bootstrap";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from "../../UtilityComponent/CustomAlertMessage";
import { $ } from "react-jquery-plugin";
import { ProfileContext } from "../../Context";
import { usePagination, useSortBy, useTable } from "react-table";


const P2PWallet = () => {
  const [walletData, setWalletData] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [updatedLength, setupdatedLength] = useState(5); 
  const [skipCount, setSkipCount] = useState(0);
  const [limitCount, setLimitCount] = useState(0);
  const [swapHistory, setSwapHistory] = useState([])
  const [swapHistoryDetail, setSwapHistoryDetail] = useState();


  let FunctCheck = true;
  useEffect(() => {
    if (FunctCheck) {
      getUserfunds();
      FunctCheck = false;
    }

  }, []);

 
  

  // ********* Funds Data ********** //
  const getUserfunds = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getUserfunds().then((result) => {
      if (result?.success) {
        setWalletData(result?.data)
      }
    })
  };


  const transferHandler = async(selectedCurrency, withdrawAmount) =>{
    const result = await AuthService.transferToSpot(selectedCurrency, withdrawAmount)  
    if(result.success) {
        alertSuccessMessage(result.message)
        getUserfunds();
    } else{
        alertErrorMessage(result.message)
    }     
  }

 

  

  
  // ********* Deposit Wallet History ********** //
  const SwapHistory = async (skip, limit) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.swapHistory(skip, limit).then(async (result) => {
      if (result?.success) {
        if (result?.data?.length > 0) {
          setSkipCount(skip);
          setLimitCount(limit);
          setSwapHistory(result?.data);
          return;
        } else if (skip !== 0) {
          alertWarningMessage('No more data found')
        }
      }
    });
  };

 
  

  
  //******** Auto Call Verify -Deposit after evry 5 minute **********//
  const VerifyDeposit = async () => {
    const ref = window.location.href.split("/");
    let timeout = setTimeout(VerifyDeposit, 300000)
    ref[ref.length - 1] !== 'FundPage' && clearTimeout(timeout);
    await AuthService.verifyDeposit().then(async (result) => {
      if (result?.success) {

      }
    });
  };
 

  // ******* React Table Implementation Swaping History *********** //
  const data1 = React.useMemo(() => swapHistory, [swapHistory]);
  const columns1 = React.useMemo(() => [
    {
      Header: "Sr No.", accessor: "_id", disableSortBy: true, Cell: ({ row }) => {
        const { index } = row; return index + skipCount + 1
      }
    },
    { Header: "Date/Time", accessor: "updatedAt", disableSortBy: true, Cell: ({ row }) => { return <Moment date={row?.values?.updatedAt} format="DD-MM-YYYY, h:mm:ss" /> } },
    { Header: "Coin", accessor: "short_name", disableSortBy: true, },
    { Header: "Amount", accessor: "amount", Cell: ({ row }) => { return parseFloat(row?.values?.amount) } },
    { Header: "Description", accessor: "wallet", disableSortBy: true, },
  ], [skipCount]);

  const { getTableProps: getTableProps1, getTableBodyProps: getTableBodyProps1, headerGroups: headerGroups1, page: page1, prepareRow: prepareRow1 } = useTable({ columns: columns1, data: data1 }, useSortBy, usePagination);

  

 
  

  return (
    <>
      <section className="inner-page-banner">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title">P2P Wallet</h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">P2P Wallet</li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container">
          <div className="d-flex-between mb-5 custom_dlflex">
            <ul className="nav custom-tabs overflowx_scroll funds_tab shadow-soft">
              <li><a className=" active" data-bs-toggle="tab" href="#funds" onClick={getUserfunds}>Funds</a></li>
              <li><a data-bs-toggle="tab" href="#tt_history" onClick={() => { SwapHistory(0, 10); setSkipCount(0); setLimitCount(0) }}> Swaping History</a> </li>
             
            </ul>
            
          </div>
          <div className="tab-content custom-tab-content py-0 shadow-soft">
            <div className="tab-pane fade show container active   " id="funds">
              <div className="table-responsive">
                <table className="table mb-0">
                  <thead  >
                    <tr>
                      <th>Sr No.</th>
                      <th>ASSETS</th>
                      <th>Available Balance</th>
                      <th>Locked</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {walletData?.length > 0 ? (
                      walletData?.slice(0, updatedLength)?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.short_name}</td>
                          <td>{parseFloat(item?.p2p_balance?.toFixed(8))}</td>
                          <td>{parseFloat(item?.p2p_locked_balance?.toFixed(8))}</td>
                          <td>
                            {parseFloat(
                              +item?.p2p_balance  +   +item?.p2p_locked_balance?.toFixed(8))}
                          </td>
                          <td>
                            <a href="#/" className=" tb_btn  badge badge-danger  mx-1" onClick={() => {  setSelectedCurrency(item.short_name); $("#Withdraw_modal").modal("show");}}>
                              Transfer to Spot Wallet
                            </a>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr rowSpan="5">
                        <td colSpan="12">
                          <p className="text-center" style={{ textAlign: "center" }}>
                            No Data Available
                          </p>
                        </td>
                      </tr>
                    )}
                    {(walletData?.length > 0 && updatedLength < walletData?.length) &&
                      <tr rowSpan="5">
                        <td colSpan="12" className="cursor-pointer loadMore" >
                          <p className="text-center cursor-pointer mb-0" style={{ cursor: 'pointer' }} onClick={() => {
                            setupdatedLength(updatedLength + 5); window.scrollTo({
                              top: document.documentElement.scrollHeight,
                              left: 0,
                              behavior: "smooth"
                            })
                          }} >
                            Load More...
                          </p>
                        </td>
                      </tr>}
                  </tbody>
                </table>

              </div>
            </div>

            {/* Deposit History */}
            <div className="tab-pane container fade  " id="tt_history">
              <div className="table-responsive">
                <><table className="table mb-0"  {...getTableProps1()}>
                  <thead   >
                    {headerGroups1.map((headerGroup, index) => (
                      <tr key={index} {...headerGroup.getHeaderGroupProps()} >
                        {headerGroup.headers.map((column, index) => (
                          <th key={index}  {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {(column.Header === 'Amount') && <i className="ri-arrow-up-down-fill ms-1" style={{ color: column.isSorted ? (column.isSortedDesc ? '#ff00009c' : 'green') : '' }}></i>}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps1()}>
                    {page1.map((row, index) => {
                      prepareRow1(row);
                      return (
                        <tr key={index} {...row.getRowProps()}>
                          {row.cells.map((cell, index) => (
                            <td key={index} {...cell.getCellProps()} className="cursor_pointer" data-bs-toggle="modal" data-bs-target="#swap_history" onClick={() => { setSwapHistoryDetail(row?.original) }}>{cell.render("Cell")} </td>
                          ))}
                        </tr>
                      );
                    })}
                    {swapHistory?.length === 0 && <tr rowSpan="5">
                      <td colSpan="12">
                        <p className="text-center" style={{ textAlign: "center" }}>
                          No Data Available
                        </p>
                      </td>
                    </tr>}
                  </tbody>
                </table></>
                {swapHistory?.length !== 0 &&
                  <div className="container pt-3 pb-4  table_control" >
                    <div className=" row align-items-center " >
                      <div className=" row align-items-center justify-content-end" >
                        <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                          <input type="button" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" disabled={skipCount <= 1} />
                          <label className="btn " htmlFor="btnradio250" onClick={() => { SwapHistory(skipCount - 10, limitCount - 10) }}>Previous</label>

                          <input type="button" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off" />
                          <label className="btn " htmlFor="btnradio375" disabled onClick={() => { SwapHistory(skipCount + 10, limitCount + 10) }}>Next</label>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>
            </div>
           
          </div>
        </div>
      </section >

      

      {/* Transfer modal  */}
      <div class="modal fade" id="Withdraw_modal" tabindex="-1" aria-labelledby="Withdraw_modalLaebl" aria-hidden="true" >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header flex-column px-8">
              <h3 class="modal-title" id="placeBitLaebl">
                Transfer Funds
              </h3>
              <button type="button" class="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i class="ri-close-fill"></i>
              </button>
            </div>
            <div class="modal-body px-8 py-4">
              
                <>
                  <div className="form-group mb-4">
                    <input className="" type="text" value={selectedCurrency} disabled />
                  </div>
                  <div className="form-group mb-4">
                    <input className="" type="number" name="amount_val" value={withdrawAmount} placeholder="Amount" onChange={(e) => setWithdrawAmount(e.target.value)} onWheel={(e) => e.target.blur()} />
                  </div>
                  <div className="form-group mb-4">
                    <button type="button" className="btn btn-gradient btn-small w-100 justify-content-center" data-bs-dismiss="modal" onClick={()=> transferHandler(selectedCurrency, withdrawAmount)} disabled={!withdrawAmount}>
                      <span>Transfer</span>
                    </button>
                  </div>
                </>
              
            </div>
          </div>
        </div>
      </div>

       {/* Swap History modal */}
       <div className="modal fade" id="swap_history" tabIndex="-1" aria-labelledby="swap_history" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">
                Transaction Details
              </h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i className="ri-close-fill"></i>
              </button>
            </div>
            <div className="modal-body px-8 py-5 body_history">
              <div className="tt_data">
                <div className="tt_item">
                  <span className="tt_disable">Date/Time</span>
                  <span className="tt_normal">
                    <b><Moment date={swapHistoryDetail?.updatedAt} format='MMMM Do YYYY, h:mm:ss A' /></b>
                  </span>
                </div>
              </div>
              <div className="tt_data">
                <div className="tt_item">
                  <span className="tt_disable">Coin</span>
                  <span className="tt_normal">
                    <b>{swapHistoryDetail?.short_name}</b>
                  </span>
                </div>
              </div>

              <div className="tt_data">
                <div className="tt_item">
                  <span className="tt_disable">Amount</span>
                  <span className="tt_normal">
                    <b>{swapHistoryDetail?.amount}</b>
                  </span>
                </div>          

                <div className="tt_item ">
                  <span className="tt_disable">Description</span>
                  <span className="tt_normal">
                    <b>{swapHistoryDetail?.wallet}</b>
                  </span>
                </div>

                <div className="tt_item ">
                  <span className="tt_disable">Remarks</span>
                  <span className="tt_normal">
                    <b>Inoutbit Exchange</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    
    </>
  );
};

export default P2PWallet;
