
import React from "react";
import { Link } from "react-router-dom";

const UserHeader = () => {
  const ShowMobileSidebar = () => {
    let button = document.getElementById('qwert');
    button.classList.add("active");
  }
  const HideMobileSidebar = () => {
    let button = document.getElementById('qwert');
    button.classList.remove("active");
  }

  return (
    <>
      <header className="ib-header header-default header-fixed header--sticky fluid-header">
        <div className="container">
          <div className="header-inner d-flex align-items-center justify-content-between">
            <div className="header-left d-flex align-items-center">
              <div>
                <div className="logo-wrapper show_web">
                  <Link to="/" className="logo logo-light">
                    <img src="/images/logo-white.svg" alt="brand" />
                  </Link> 
                </div>
                <div className="logo-wrapper show_mobile">
                  <Link to="/" className="logo logo-light">
                    <img src="/images/logo-icon.svg" alt="brand" />
                  </Link> 
                </div>
              </div>
              <div className="mainmenu-wrapper ms-md-5">
                <nav id="sideNav" className="mainmenu-nav d-none d-xl-block">
                  <ul className="mainmenu">
                    <li><Link to="/">Home</Link></li>                  
                    <li><Link to="/MarketPage">Market </Link> </li>
                    <li><Link to="/trade">Trade </Link></li>
                    <li><Link to="/p2p_trading">  P2P Trading </Link></li>                   
                  </ul>
                </nav>
              </div>
            </div>
            <div className="header-right d-flex align-items-center">
              <ul className="header-right-inner">
                <li className="wallet-button me-0">
                  <Link to="/login" className="btn btn-link text-white">
                    <span>Login</span>
                  </Link>
                  <div className="btn-group">
                    <Link to="/signup" className="btn btn-gradient btn-small">
                      <span>Register</span>
                    </Link>
                  </div>
                </li>
                <li className="setting-option mobile-menu-bar d-block d-xl-none ">
                  <button className="hamberger-button" type="button" onClick={ShowMobileSidebar}>
                    <i className="ri-menu-2-fill"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      {/* <!-- Start mobile menu area --> */}
      <div className="popup-mobile-menu" id="qwert" onClick={HideMobileSidebar} >
        <div className="inner">
          <div className="header-top">
            <div className="logo logo-custom-css">
              <Link className="logo-light" to="/">
                <img src="/images/logo-white.svg" alt="nft-logo" />
              </Link>
              <Link className="logo-dark" to="/">
                <img src="/images/logo-dark.svg" alt="nft-logo" />
              </Link>
            </div>
            <div className="close-menu">
              <button className="close-button shadow-soft" type="button" >
                <i className="ri-close-fill"></i>
              </button>
            </div>
          </div>
          <nav>
            <ul className="mainmenu" >
              <li><Link to="/" > <i className="ri-home-line me-2 "></i> Home </Link> </li>              
              <li><Link to="/MarketPage"> <i className="ri-file-list-3-line me-2"></i>  Market</Link></li>
              <li> <Link to="/trade" > <i className="ri-line-chart-line me-2"></i> Trade  </Link></li>
              <li> <Link to="/p2p_trading"><i className="ri-stack-line me-2 "></i>  P2P Trading</Link> </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default UserHeader;
