import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

function alertErrorMessage(message) {
  toast.error(message ? message?.toUpperCase() :'Network Error', {
    toastId: 'Toasterror',
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    closeButton: false,
  });
}

function alertSuccessMessage(message) {
    toast.success(message ? message?.toUpperCase() : 'Success', {
      toastId: 'Toastsuccess',
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
      closeButton: false
    });
}

function alertWarningMessage(message) {
  if (!toast.isActive('ToastWarning')) {
  toast.info(message ? message?.toUpperCase() :'Oops', {
    toastId: 'ToastWarning',
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  })};
}

const alertMessage = () => {
  toast.info(
    <div style={{ textAlign: 'center' }}>
      Please wait for seller to release funds
      <button onClick={() => toast.dismiss()}>OK</button>
    </div>,
    {
      position: toast.POSITION.TOP_CENTER,
      closeButton: false,
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: false,
    }
  );
};



export { alertErrorMessage, alertSuccessMessage, alertWarningMessage, alertMessage };