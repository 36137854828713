import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer-wrapper">
      <div className="footer-inner">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-4">
              <div className="footer-widget">
                <h4>Inoutbit</h4>
                <ul className="footer-list-widget">
                  <li>
                    <Link to="/stacking">Staking</Link>
                  </li>
                  <li>
                    <a rel="noreferrer"  target="_blank" href="https://coinmarketcap.com/">
                      Coinmarket Cap{" "}
                    </a>
                  </li>
                  <li>
                    <a rel="noreferrer"   target="_blank" href="https://www.coingecko.com/">
                      Coingecko
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- End .col --> */}

            <div className="col-lg-3 col-md-6 mb-4">
              <div className="footer-widget">
                <h4>Support</h4>
                <ul className="footer-list-widget">
                  <li>
                    {/* <Link to="/FAQ">FAQ</Link> */}
                  </li>
                  <li>
                    <Link to="/Support">Support</Link>
                  </li>
                  <li>
                    <Link to="#/">Fees</Link>
                  </li>
                  <li>
                    <Link to="/ReferralPage">Blog</Link>
                  </li>
                  <li>
                    <Link to="/Career">Career</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- End .col --> */}

            <div className="col-lg-3 col-md-6 mb-4">
              <div className="footer-widget">
                <h4>About</h4>
                <ul className="footer-list-widget">
                  <li>
                    <Link to="/AboutUs">About Us</Link>
                  </li>
                  <li>
                    <Link to="/#">Announcements</Link>
                  </li>
                  <li>
                    <Link to="/TermsOfUsePage">Terms of Use</Link>
                  </li>
                  <li>
                    <Link to="/PrivacyPolicyPage">Privacy & KYC Policy</Link>
                  </li>
                  <li>
                    <Link to="/#">Apply for listing</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- End .col --> */}

            <div className="col-lg-3 col-md-6 mb-4">
              <div className="footer-widget">
                <h4>Social Links</h4>
                <ul className="footer-list-widget">
                  <li>
                    <a rel="noreferrer" target="_blank"  href="https://www.facebook.com/profile.php?id=100084911176496"> Facebook </a>
                  </li>                 
                  {/* <li>
                    <a rel="noreferrer" target="_blank" href="https://twitter.com/OfficialInoutbit"> Twitter </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.youtube.com" > Youtube </a>
                  </li> */}
                  <li>
                    <a target="_blank" href="https://www.instagram.com/Inoutbit"> Instagram </a>
                  </li>
                  {/* <li>
                    <a target="_blank" href="https://telegram.org/"> Telegram </a>
                  </li>                 */}
                </ul>
              </div>
            </div>
            {/* <!-- End .col --> */}
          </div>
        </div>
        {/* <!-- End .container --> */}
      </div>
      <div className="copyright text-center">
        <div className="container">
          <p>           
            Copyright ©️ Inoutbit 2023. All Right Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
