import React, { useEffect } from "react";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}, []);
  return (
    <>
      <section className="inner-page-banner">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title"> About Us </h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  AboutUs
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="pb-90">
        <div className="container">
          <div className="row">
            <div className="col-md-10 m-auto">
              <div className="create-item-wrapper plicy_sec">
                <p>
                  First ever global trading platform that brings you knowledge
                  and will educate you about crypto . Inoutbit is a
                  next-generation cryptocurrency trading platform, which will
                  foster all your needs in a simple, easy and intuitive way.
                  <br />
                  <br />
                  Inoutbit aims to provide a world with world-class financial
                  system that will shape the economy. Inoutbit is build on high
                  standards and is audited and penetration tested.
                  <br />
                  <br />
                  We have experience in building financial systems of the
                  highest security standards and strive to ensure security
                  first.
                  <br />
                  <br />
                  Inoutbit is next generation crypto-assets trading to
                  revolutionize the current trading environment by providing a
                  trustworthy, transparent, safe and reliable place to exchange
                  the popular crypto-currencies, ERC20 token and other
                  crypto-currencies that are foreseen to evolve in the future.
                  <br />
                  <br />
                  We at Inoutbit offer you the most powerful crypto-asset trading
                  platform, you can now tarde all the top listed
                  crypto-currencies, anywhere any time.
                  <br />
                  <br />
                  With our user-friendly interface,trading and investing in
                  virtual assets has become an easy process. With next to zero
                  fees, trade crypto like a PRO with Inoutbit.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
