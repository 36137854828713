import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const TermsOfUsePage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}, []);

  return (
    <>
      <section className="inner-page-banner">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title"> Terms Of Use </h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Terms Of Use
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <section className="pb-90">
        <div className="container">
          <div className="row">
            <div className="col-md-10 m-auto">
              <div className="create-item-wrapper plicy_sec">
                <p>
                  These Inoutbit Terms of Use is entered into between you
                  (here in after referred to as “you” or “your”) and Inoutbit
                  operators (as defined below). By accessing, downloading, using
                  or clicking on “I agree” to accept any Inoutbit Services (as
                  defined below) provided by Inoutbit (as defined below), you
                  agree that you have read, understood and accepted all of the
                  terms and conditions stipulated in these Terms of Use
                  (here in after referred to as “these Terms”) as well as our
                  Privacy Policy at (support@Inoutbit.io). In addition, when
                  using some features of the Services, you may be subject to
                  specific additional terms and conditions applicable to those
                  features.
                  <br />
                  <br />
                  Please read the terms carefully as they govern your use of
                  Inoutbit Services.These terms contain important provisions
                  Including an arbitration provision that requires all claims to
                  Be resolved by way of legally binding arbitration.The terms of
                  the arbitration provision are set forth in Article 10,
                  “Resolving Disputes: Forum, Arbitration, Class Action Waiver”,
                  hereunder. As with any asset, the values of Digital Currencies
                  (as defined below) may fluctuate significantly and there is a
                  substantial risk of economic losses when purchasing, selling,
                  holding or investing in Digital Currencies and their
                  derivatives.By making use of Inoutbit services, you acknowledge
                  And agree that: (1) you are aware of the risks associated with
                  Transactions of digital currencies and their derivatives; (2)
                  You shall assume all risks related to the use of Inoutbit
                  Services and transactions of digital currencies and their
                  Derivatives; and (3) Inoutbit shall not be liable for any such
                  Risks or adverse outcomes.
                  <br />
                  <br />
                  By accessing, using or attempting to use Inoutbit Services in
                  any capacity, you acknowledge that you accept and agree to be
                  bound by these Terms. If you do not agree, do not access
                  Inoutbit or utilize Inoutbit services.
                  <br />
                  <br />
                  <strong>I. Definitions </strong>
                  <br />
                  <br />
                  <p>
                    <strong>Inoutbit Exchange - </strong>It refers to an
                    ecosystem comprising Inoutbit websites (whose domain names
                    include but are not limited to
                    https://www.Inoutbitexchange.io/), mobile applications,
                    clients, applets and other applications that are developed
                    to offer Inoutbit Services, and includes
                    independently-operated platforms, websites and clients
                    within the ecosystem.
                    <br />
                    <br />
                    In case of any inconsistency between relevant terms of use
                    of the above platforms and the contents of these Terms, the
                    respective applicable terms of such platforms shall prevail.
                    <ul>
                      <li>
                        <p>
                          <strong>a. Inoutbit Accounts - </strong>It refer to the
                          foundational virtual accounts, including main accounts
                          and subaccounts, which are opened by Inoutbit for Users
                          to record on Inoutbit their usage of Inoutbit Services,
                          transactions, asset changes and basic information.
                          Inoutbit Accounts serve as the basis for Users to enjoy
                          and exercise their rights on Inoutbit.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>b. Inoutbit Fiat Account - </strong>It means
                          the record of your fiat currencies’ balance (if any),
                          which is enabled through an electronic money wallet
                          (or similar account) service that is provided by a
                          Fiat Partner.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>c. Inoutbit Services - </strong>It refer to
                          various services provided to users by Inoutbit that are
                          based on Internet and/or blockchain technologies and
                          offered via Inoutbit websites, mobile applications,
                          clients and other forms (including new ones enabled by
                          future technological development). Inoutbit Services
                          include but are not limited to such, Digital assets
                          trading, Inoutbit academy, Inoutbit launchpad, Inoutbit
                          blockchain .
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>d. Inoutbit Operators - </strong>refer to all
                          parties that runs Inoutbit, including but not limited
                          to legal persons,unincorporated organizations and
                          teams that provide Inoutbit Services and are
                          responsible for such services. For convenience, unless
                          otherwise stated, references to “Inoutbit” and “we” in
                          these Terms specifically mean Inoutbit Operators(Under these terms, Inoutbit operators may change as Inoutbit’s business adjusts, in which case, the changed operators shall perform their obligations under these terms with you and provide services to you, and such change does not affect your rights and interests under these terms. Additionally, the scope of Inoutbit operators may be expanded due to the provision of new Inoutbit services, in which case, if you continue to use Inoutbit services, it is deemed that you have agreed to jointly execute these terms with the newly added Inoutbit operators. In case of a dispute, you shall determine the entities by which these terms are performed with you and the counterparties of the dispute, depending on the specific services you use and the particular actions that affect your rights or interests).
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>e. Inoutbit Platform Rules - </strong>It refer
                          to all rules, interpretations, announcements,
                          statements, letters of consent and other contents that
                          have been and will be subsequently released by
                          Inoutbit, as well as all regulations, implementation
                          rules, product process descriptions, and announcements
                          published in the Help Center or within products or
                          service processes.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>f. Digital Currency - </strong>It refer to a
                          form of currency that exists entirely in digital or
                          virtual form, not backed by a government or physical
                          asset. It is based on cryptography and decentralized
                          ledger technology known as blockchain.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>g. Digital Assets - </strong>It refer to
                          digital or virtual assets that are stored and
                          exchanged electronically. They can include anything
                          from digital currencies, to digital representations of
                          real-world assets, such as property titles, music, or
                          artwork. These digital assets have certain type of
                          value.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>h. Fiat Partner - </strong>A fiat partner
                          refers to a company or financial institution that
                          provides a bridge between traditional fiat currencies
                          (such as USD or EUR) and cryptocurrencies, allowing
                          users to easily buy and sell digital currencies using
                          fiat money.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>i. Fiat Services means - </strong>Fiat
                          services refer to financial services that involve
                          traditional government-issued currencies (fiat
                          currencies), such as INR USD, EUR, as opposed to
                          cryptocurrencies. Examples of fiat services include
                          banking, credit cards, loans, and money transfers.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>j. KYC - </strong>KYC stands for Know Your
                          Customer, which is a regulatory requirement that
                          financial institutions and other businesses must
                          follow to verify the identity of their customers. The
                          process involves collecting and verifying personal
                          information, such as name, address, and date of birth,
                          and may also include collecting government-issued
                          identification documents. KYC is designed to prevent
                          fraud, money laundering, and terrorist financing by
                          ensuring that businesses have a clear understanding of
                          who their customers are and can monitor their
                          activities.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>k. Spot Trading - </strong>Spot trading refers
                          to the buying and selling of financial assets, such as
                          stocks, commodities, or cryptocurrencies, for
                          immediate delivery or settlement, typically within a
                          few days.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>l. Users </strong>refer to all individuals,
                          institutions or organizations that access, download or
                          use Inoutbit or Inoutbit Services and who meet the
                          criteria and conditions stipulated by Inoutbit
                          exchange.
                        </p>
                      </li>
                    </ul>
                  </p>
                  {/* ////////// */}
                  <strong>II. GENERAL PROVISIONS </strong>
                  <br />
                  <br />
                  <p>
                    <strong> 1. About these terms </strong>
                    <ul>
                      <li>
                        <p>
                          <strong>a. Contractual Realtionship - </strong>It is a
                          legally binding agreement between you and Inoutbit
                          operators that defines the terms and conditions of
                          both parties mutual obligations and responsibilities.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>b. Supplementary Terms - </strong>Due to the
                          rapid development of Digital Currencies and Inoutbit
                          these Terms between you and Inoutbit Operators do not
                          enumerate or cover all rights and obligations of each
                          party, and do not guarantee full alignment with needs
                          arising from future development. Therefore, THE
                          PRIVACY POLICY OF Inoutbit PLATFORM RULES, AND ALL
                          OTHER AGREEMENTS ENTERED INTO SEPARATELY BETWEEN YOU
                          AND Inoutbit ARE DEEMED SUPPLEMENTARY TERMS THAT ARE AN
                          INTEGRAL PART OF THESE TERMS AND SHALL HAVE THE SAME
                          LEGAL EFFECT. YOUR USE OF Inoutbit SERVICES IS DEEMED
                          YOUR ACCEPTANCE OF THE ABOVE SUPPLEMENTARY TERMS.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>c. Changes to These Terms - </strong>Inoutbit
                          reserves the right to change or modify these Terms in
                          its discretion at any time. We will notify such
                          changes by updating the terms on its website and will
                          modify the [Last revised] date displayed on this page.
                          Any and all modifications or changes to these terms
                          Will become effective upon publication on the website
                          Or release to users. Therefore, your continued use of
                          Inoutbit services is deemed your acceptance of the
                          Modified agreement and rules. If you do not agree to
                          Any changes to these terms, you must stop using
                          Inoutbit services immediately. You are recommended to
                          Frequently review these terms to ensure your
                          Understanding of the terms and conditions that apply
                          To your access to and use of Inoutbit services.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>d. Prohibition of Use - </strong>By accessing
                          And using Inoutbit services, you represent and warrant
                          That you have not been included in any trade
                          Interdiction or economic sanctions list (such as the
                          United nations security council sanctions list), the
                          List of specially designated nationals maintained by
                          Ofac (the office of foreign assets control of the u.S.
                          Department of the treasury), or the denied persons or
                          Entity list of the u.S. Department of commerce.
                          Inoutbit reserves the right to choose markets and
                          Jurisdictions to conduct business, and may restrict or
                          Refuse, in its discretion, the provision of Inoutbit
                          Services in certain countries or regions.
                        </p>
                      </li>
                    </ul>
                    <strong>2. About Inoutbit </strong>
                    <p>
                      Inoutbit is a premier digital asset exchange that provides
                      secure and seamless access to a wide range of
                      cryptocurrencies. Our exchange is founded by a team of
                      experienced professionals who are passionate about the
                      transformative potential of blockchain technology. At our
                      exchange, we are committed to providing our users with a
                      seamless and secure trading experience. We believe in
                      creating a platform that is user-friendly, reliable, and
                      accessible to anyone, regardless of their level of
                      experience with cryptocurrencies. Our team is dedicated to
                      ensuring that our users have access to the latest market
                      data, real-time pricing, and a variety of advanced trading
                      tools. We take the security of our users' funds very
                      seriously, which is why we have implemented robust
                      security measures and best practices to safeguard our
                      users' assets. We are also committed to complying with all
                      relevant regulations and laws, and we strive to maintain
                      the highest standards of transparency and integrity in all
                      our dealings. Our mission is to empower our users to take
                      control of their financial futures by providing them with
                      a secure, reliable, and user-friendly platform to buy,
                      sell, and trade cryptocurrencies. We are committed to
                      delivering the best possible service and support to our
                      users, and we look forward to serving you on our platform.
                      <br />
                      <br />
                      Although Inoutbit has been committed to maintaining the
                      accuracy of the information provided through our Services,
                      Inoutbit cannot and does not guarantee its accuracy,
                      applicability, reliability, integrity, performance or
                      appropriateness, nor shall Inoutbit be liable for any loss
                      or damage that may be caused directly or indirectly by
                      your use of these contents. The information about Inoutbit
                      services may change without notice, and the main purpose
                      of providing such information is to help Users make
                      independent decisions. Inoutbit does not provide investment
                      or consulting advice of any kind, and is not responsible
                      for the use or interpretation of information on Inoutbit or
                      any other communication medium. All Users of Inoutbit
                      Services must understand the risks involved in Digital
                      Assets trading, and are recommended to trade responsibly
                      within their own capabilities.
                    </p>
                    <strong>
                      3. Inoutbit Account Registration and Requirements
                    </strong>
                    <p>
                      <strong>a. Registration- </strong>All Users must apply for
                      a Inoutbit Account at (
                      <a href="https://www.Inoutbitexchange.io/signup">
                        https://www.Inoutbitexchange.io/signup
                      </a>
                      ) before using Inoutbit Services. When you register on a
                      Inoutbit Account, you must provide the information
                      requested by Inoutbit , and accept these Terms, the Privacy
                      Policy, and other Inoutbit Platform Rules. Inoutbit may
                      refuse, in its discretion, to open an account for you. You
                      agree to provide complete and accurate information when
                      opening a Inoutbit Account, and agree to timely update any
                      information you provide to Inoutbit to maintain the
                      integrity and accuracy of the information. Each User
                      (person, business or legal entity) may maintain only one
                      main account at any given time. However, Users can open
                      one or more subaccounts under the main account with the
                      consent of Inoutbit under certain conditions such as (For
                      certain Inoutbit Services, you may be required to set up a
                      specific account independent from your Inoutbit Account,
                      based on the provisions of these Terms or the
                      Supplementary Terms. The registration, use, protection and
                      management of such trading accounts are equally governed
                      by the provisions mentioned for the main Inoutbit Account
                      unless otherwise stated in these Terms or the
                      Supplementary Terms).
                    </p>
                    <p>
                      <strong>b. Eligibility- </strong>
                      By registering to use a Inoutbit Account, confirm and
                      verify that:
                      <ul>
                        <li>
                          • you are at least 18 or are of legal age to form a
                          binding contract under applicable laws
                        </li>
                        <li>
                          • as an individual, legal person, or other
                          organization, you have full legal capacity and
                          sufficient authorizations to enter into these Terms
                        </li>
                        <li>
                          • you have not been previously suspended or removed
                          from using the Inoutbit Platform or the Inoutbit Service
                        </li>
                        <li>• you do not have an existing Inoutbit Account</li>
                        <li>
                          • you are not resident, located in or otherwise
                          attempting to access the Inoutbit Platform or the
                          Inoutbit Services from, or otherwise acting on behalf
                          of a person or legal entity that is resident or
                          located in, a Restricted Location .
                        </li>
                        <li>
                          • if you act as an employee or agent of a legal
                          entity, and enter into these Terms on their behalf,
                          you represent and warrant that you have all the
                          necessary rights and authorizations to bind such legal
                          entity and to access and use the Inoutbit platform and
                          Inoutbit Services on behalf of such legal entity
                        </li>
                        <li>
                          • Your use of the Inoutbit Platform and the Inoutbit
                          Services will not violate any and all laws and
                          regulations applicable to you or the legal entity on
                          whose behalf you are acting, including but not limited
                          to regulations on anti-money laundering,
                          anti-corruption, and counter-terrorist financing.
                        </li>
                        <li>
                          • Please note that there are legal requirements in
                          various countries which may restrict the products and
                          services that Inoutbit Operators can lawfully provide.
                          Accordingly, some products and services and certain
                          functionality within the Inoutbit Platform may not be
                          available or may be restricted in certain
                          jurisdictions or regions or to certain users. You
                          shall be responsible for informing yourself about and
                          observing any restrictions and/or requirements imposed
                          with respect to the access to and use of the Inoutbit
                          Platform .(Inoutbit Operators reserve the right to
                          change, modify or impose additional restrictions with
                          respect to the access to and use of the Inoutbit
                          Platform and/Services from time to time at their
                          discretion at any time without prior notification).
                        </li>
                      </ul>
                    </p>
                    <p>
                      <strong>c. User Identity Verification- </strong>
                      Your registration of an account with Inoutbit will be
                      deemed your agreement to provide required personal
                      information for identity verification. Such information
                      will be used to verify Users’ identity, identify traces of
                      money laundering, terrorist financing, fraud and other
                      financial crimes through Inoutbit, or for other lawful
                      purposes stated by Inoutbit. We will collect, use and share
                      such information in accordance with our Privacy Policy. In
                      addition to providing such information, you agree to allow
                      us to keep a record of that information during the period
                      for which your account is active and within five (5) years
                      after your account is closed, in compliance with global
                      industry standards on data storage. You also authorize us
                      to conduct necessary investigations directly or through a
                      third party to verify your identity or protect you and/or
                      us from financial crimes, such as fraud. The information
                      we require to verify your identity may include, but is not
                      limited to, your name, email address, contact information,
                      phone number, username, government-issued ID, date of
                      birth, and other information collected during account
                      registration. When providing the required information, you
                      confirm it is true and accurate. After registration, you
                      Must ensure that the information is true, complete, and
                      Timely updated when changed. If there are any grounds for
                      Believing that any of the information you provided is
                      Incorrect, false, outdated or incomplete, Inoutbit reserves
                      The right to send you a notice to demand correction,
                      Directly delete the relevant information, and, as the case
                      May be, terminate all or part of Inoutbit services we
                      Provide for you. If we are unable to reach you with the
                      Contact information you provided, you shall be fully
                      Liable for any loss or expense caused to Inoutbit during
                      Your use of Inoutbit services. You hereby acknowledge and
                      Agree that you have the obligation to update all the
                      Information if there is any change.By registering an
                      Account, you hereby authorize Inoutbit to conduct
                      Investigations that Inoutbit considers necessary, either
                      Directly or through a third party, to verify your identity
                      Or protect you, other users and/or Inoutbit from fraud or
                      Other financial crimes, and to take necessary actions
                      Based on the results of such investigations. You also
                      Acknowledge and agree that your personal information may
                      Be disclosed to credit bureaus and agencies for fraud
                      Prevention or financial crime prevention, which may
                      Respond to our investigations in full.
                    </p>
                    <p>
                      <strong>d. Account Usage Requirements- </strong>
                      Inoutbit Account can only be used by the account
                      registrant. We reserves the right to suspend, freeze or
                      cancel the use of Inoutbit Accounts by persons other than
                      account registrant. If you suspect or become aware of any
                      unauthorized use of your username and password, you should
                      notify Inoutbit immediately. Inoutbit holds no liability or
                      accountability for any loss or damage arising from the use
                      of Inoutbit Account by you or any third party with or
                      without your authorization.
                    </p>
                    <p>
                      <strong>e. Account Security - </strong>
                      Here at Inoutbit we ensures maintaining the security of
                      User entrusted funds, and has implemented highest industry
                      standard protection for Inoutbit Services. However, the
                      actions of individual Users may pose risks. You shall
                      agree to treat your access credentials (such as username
                      and password) as confidential information, and not to
                      disclose such information to any third party. You also
                      agree to be solely responsible for taking the necessary
                      security measures to protect your Inoutbit Account and
                      personal information.
                      <br />
                      <br />
                      You should be solely responsible for keeping safe of your
                      Inoutbit Account and password, and be responsible for all
                      the transactions under your Inoutbit Account. Inoutbit holds
                      no liability for any loss or consequences caused by
                      authorized or unauthorized use of your account
                      credentials, including but not limited to information
                      disclosure, information release, consent or submission of
                      various rules and agreements by clicking on the website,
                      online agreement renewal, etc.
                      <br />
                      <br />
                      By creating a Inoutbit Account, you hereby agree that:
                      <ul>
                        <li>
                          • you will notify Inoutbit immediately if you are aware
                          of any unauthorized use of your Account and password
                          or any other violation of security rules;
                        </li>
                        <li>
                          • you will strictly abide by all mechanisms or
                          procedures of Inoutbit regarding security,
                          authentication, trading, charging, and withdrawal; and
                        </li>
                        <li>
                          • you will take appropriate steps to logout from
                          Inoutbit at the end of each visit.
                        </li>
                      </ul>
                    </p>
                    <strong>III. Inoutbit SERVICES </strong>
                    After completing the registration process of Inoutbit Account
                    and verification of personal information , you may use
                    Inoutbit Services which includes but not limited to Spot
                    trading, Fiat services, Margin trading, Saving, Staking,
                    etc. in accordance with the provisions of these Terms
                    (including Inoutbit Platform Rules and other individual
                    agreements). Inoutbit has the right to:
                    <ul>
                      <li>
                        • Provide, modify or terminate, in its discretion, any
                        Inoutbit Services; and
                      </li>
                      <li>
                        • Allow or prohibit some Users’ use of any Inoutbit
                        Services in accordance with relevant Inoutbit Platform
                        Rules.
                      </li>
                    </ul>
                    Inoutbit has exclusive authority to:
                    <ul>
                      <li>
                        • determine which digital asset will be listed on the
                        platform
                      </li>
                      <li>
                        • adding or removing any digital asset from the platform
                      </li>
                      <li>
                        • changing order size available of any digital asset
                      </li>
                    </ul>
                    In respect of such additions, removals, or amendments,
                    Inoutbit may, but is not obliged to, notify Users in advance
                    and Inoutbit shall have no liability to Users in connection
                    with such additions, removals or amendments.
                    <p>
                      <strong>1. SERVICE USAGE GUIDELINES </strong>
                      <p>
                        <strong>a) License </strong>
                        Provided that you constantly comply with the express
                        terms and conditions stated in these Terms, Inoutbit
                        grants you a revocable, limited, royalty-free,
                        non-exclusive, non-transferable, and non-sublicensable
                        license to access and use Inoutbit Services through your
                        computer or Internet compatible devices for your
                        personal/internal purposes.
                        <br />
                        <br />
                        You are prohibited to use Inoutbit Services for resale or
                        commercial purposes, including transactions on behalf of
                        other persons or entities. All the above actions are
                        expressly prohibited and constitute a material violation
                        of these Terms. The content layout, format, function and
                        access rights regarding Inoutbit Services should be
                        stipulated in the discretion of Inoutbit. Inoutbit
                        platform reserves all rights not expressly granted in
                        these Terms. Therefore, you are hereby prohibited from
                        using Inoutbit Services in any way not expressly
                        authorized by these Terms.
                        <br />
                        <br />
                        These Terms only grant a limited license to access and
                        use of Inoutbit Services. Therefore, you hereby agree
                        that when you use Inoutbit Services, Inoutbit does not
                        transfer Inoutbit Services or the ownership or
                        intellectual property rights of any Inoutbit intellectual
                        property to you or anyone else. All the text, graphics,
                        user interfaces, visual interface, photos, sounds,
                        process flow diagrams, computer code , programs,
                        software, products, information and documents, as well
                        as the design, structure, selection, coordination,
                        expression,and layout of any content included in the
                        services or provided through Inoutbit Services, are
                        exclusively owned, controlled and/or licensed by Inoutbit
                        Operators or its members, parent companies, licensors or
                        affiliates.
                        <br />
                        <br />
                        You hereby transfer all rights, ownership and interests
                        of the Feedback( any feedback, suggestions, ideas, or
                        other information) or materials and all related
                        intellectual property rights to Inoutbit. You have no
                        right and hereby waive any request for acknowledgment or
                        compensation based on any Feedback, or any modifications
                        based on any Feedback.
                      </p>

                      <p>
                        <strong>b) Restriction</strong>
                        <br />
                        <br />
                        When you use Inoutbit services, you agree and undertake
                        to comply with the following provisions:
                        <ul>
                          <li>
                            a. During the use of Inoutbit Services, all
                            activities you carry out should comply with the
                            requirements of applicable laws and regulations,
                            these Terms, and various guidelines of Inoutbit;
                          </li>
                          <li>
                            b. You agree not to use the services for market
                            manipulation (such as pump and dump schemes, wash
                            trading, self-trading, front running, spoofing or
                            layering, pump and dumb schemes,front running, quote
                            stuffing regardless of whether prohibited by law or
                            not);
                          </li>
                          <li>
                            c. Your use of Inoutbit Services should not violate
                            public interests, public morals, or the legitimate
                            interests of others, including any actions that
                            would interfere with, disrupt, negatively affect, or
                            prohibit other Users from using any Inoutbit
                            Services;
                          </li>
                        </ul>
                        The following commercial uses of Inoutbit data are
                        prohibited without written consent of Inoutbit:
                        <ul>
                          <li>
                            a. Trading services that make use of Inoutbit quotes
                            or market bulletin board information.
                          </li>
                          <li>
                            b. Data feeding or streaming services that make use
                            of any market data of Inoutbit.
                          </li>
                          <li>
                            c. Any other websites/apps/services that charge for
                            or earns profit from market data obtained from
                            Inoutbit(including through advertising or referral
                            fees).
                          </li>
                        </ul>
                        Without prior written consent from Inoutbit, you may not
                        modify, replicate, duplicate, copy, download, store,
                        further transmit, disseminate, transfer, disassemble,
                        broadcast, publish, remove or alter any copyright
                        statement or label, or license, sub-license, sell,
                        mirror, design, rent, lease, private label, grant
                        security interests in the properties or any part of the
                        properties, or create their derivative works or
                        otherwise take advantage of any part of the properties.
                        <br />
                        <br />
                        You may not:
                        <ul>
                          <li>a. Use Inoutbit services in any illegal way.</li>
                          <li>
                            b. use any devices, software or routine programs to
                            interfere with the normal operation of Inoutbit
                            Services or any transactions on Inoutbit Services, or
                            any other person’s use of Inoutbit Services
                          </li>
                          <li>
                            c. Reverse look-up, track or seek to track any
                            information of any other Users or visitors of
                            Inoutbit Services
                          </li>
                          <li>
                            d. attempt to access any part or function of the
                            properties without authorization, or connect to
                            Inoutbit Services or any Inoutbit servers or any other
                            systems or networks of Inoutbit Services provided
                            through the services by hacking, password mining or
                            any other unlawful or prohibited means
                          </li>
                          <li>
                            e. forge headers, impersonate, or otherwise
                            manipulate identification, to disguise your identity
                            or the origin of any messages or transmissions you
                            send to Inoutbit
                          </li>
                          <li>
                            f. take any actions that imposes an unreasonable or
                            disproportionately large load on the infrastructure
                            of systems or networks of Inoutbit Services or the
                            infrastructure of any systems or networks connected
                            to Inoutbit services
                          </li>
                          <li>
                            g. probe, scan or test the vulnerabilities of
                            Inoutbit Services or any network connected to the
                            properties, or violate any security or
                            authentication measures on Inoutbit Services or any
                            network connected to Inoutbit Services
                          </li>
                          <li>
                            h. use any deep linking, web crawlers, bots, spiders
                            or other automatic devices, programs, scripts,
                            algorithms or methods, or any similar or equivalent
                            manual processes to access, obtain, copy or monitor
                            any part of the properties, or replicate or bypass
                            the navigational structure or presentation of
                            Inoutbit Services in any way, in order to obtain or
                            attempt to obtain any materials, documents or
                            information in any manner not purposely provided
                            through Inoutbit Services
                          </li>
                        </ul>
                        By accessing Inoutbit Services, you agree that Inoutbit
                        has the right to investigate any violation of these
                        Terms, unilaterally determine whether you have violated
                        these Terms, and take actions under relevant regulations
                        without your consent or prior notice. Examples of such
                        actions include, but are not limited to:
                        <ul>
                          <li>a. Blocking and closing order requests;</li>
                          <li>b. Freezing your account;</li>
                          <li>c. Reporting the incident to the authorities;</li>
                          <li>
                            d. Publishing the alleged violations and actions
                            that have been taken;
                          </li>
                          <li>
                            e. Deleting any information you published that are
                            found to be violations.
                          </li>
                        </ul>
                      </p>
                    </p>
                    <p>
                      <strong>2. Spot Trading </strong>
                      By making use of Spot Trading, you acknowledge and agree
                      that you have read, understood and accepted the Spot
                      Trading Terms of Use, which shall apply to all Spot
                      Trading (as such term is defined in the Spot Trading Terms
                      of Use).
                    </p>
                    <p>
                      <strong>3. Fiat Services</strong>
                      By accepting these Terms, you acknowledge and agree that:
                      <ul>
                        <li>
                          • You have read, accepted and will comply with these
                          Terms, the Legal Statement, the Privacy Policy, and
                          any other Inoutbit Platform Rules related to Fiat
                          Services;
                        </li>
                        <li>
                          • Inoutbit may partner with any Fiat Partners and that,
                          if applicable, you will open an account with such Fiat
                          Partners by completing the relevant registration and
                          identity verification for such account, and by
                          accepting any user agreements, terms and conditions,
                          policies and procedures or similar documents of any
                          Fiat Partners;
                        </li>
                        <li>
                          • Any payments expressed in any fiat currencies and
                          which have been made in connection with any Fiat
                          Services may not be canceled, recalled or refunded,
                          and that any chargeback requests in connection with
                          any Fiat Services that involve a Fiat Partner, are
                          subject to that Fiat Partner's policies and
                          procedures.
                        </li>
                      </ul>
                    </p>
                    <p>
                      <strong>4. Margin Trading </strong>
                      By making use of the Margin Services, you acknowledge and
                      agree that you have read, understood and accepted the
                      “Margin Service Terms of Use”, which shall apply to all
                      Margin Services (as such term is defined in the Margin
                      Service Terms of Use).
                    </p>
                    <p>
                      <strong>5. Staking </strong>
                      Inoutbit will from time to time launch Staking Programs for
                      specific types of Digital Currencies to reward, as per
                      certain rules, users who hold such Digital Currencies in
                      their Inoutbit Accounts. When participating in Staking
                      Programs, you should note that:
                      <ul>
                        <li>
                          a. Unless otherwise stipulated by Inoutbit, Staking
                          Programs are free of charge and Users may trade during
                          the staking period;
                        </li>
                        <li>
                          b. Inoutbit does not guarantee Users’ proceeds under
                          any Staking Program;
                        </li>
                        <li>
                          c. Inoutbit has the right to initiate or terminate
                          Staking Program for any Digital Currencies or modify
                          rules on such programs in its sole discretion;
                        </li>
                        <li>
                          d. Users shall ensure that sources of the Digital
                          Currencies they hold in Inoutbit Accounts are legal and
                          compliant and undertake to observe related laws and
                          regulations. Otherwise, Inoutbit has the right to take
                          necessary steps in accordance with these Terms or
                          Inoutbit Platform Rules, including, without limitation,
                          freezing Inoutbit Accounts or deducting the Digital
                          Currencies awarded to Users who violate the rules of
                          respective Staking Programs.
                        </li>
                      </ul>
                    </p>
                    <p>
                      <strong>6. Inoutbit Savings Service </strong>
                      Inoutbit offers Inoutbit Savings, a service to provide Users
                      with value-added services for their idle Digital Assets.
                      To use Inoutbit Savings service, you must conclude with
                      Inoutbit a separate Inoutbit Savings Service User Agreement
                      (saving service agreement link) and open a special Inoutbit
                      Savings service account, following the completion of
                      registration and identity verification for your Inoutbit
                      Account. When using Inoutbit Savings service, you should
                      note that:
                      <ul>
                        <li>
                          a. Inoutbit Savings assets will be used in
                          cryptocurrency leveraged borrowing and other
                          businesses.
                        </li>
                        <li>
                          b. When you use Inoutbit Savings service, you will
                          unconditionally authorize Inoutbit to distribute and
                          grant the leveraged interest according to Inoutbit
                          Platform Rules.
                        </li>
                        <li>
                          c. You shall abide by relevant laws and regulations to
                          ensure that the sources of Digital Assets are
                          legitimate and compliant when using Inoutbit Savings
                          service.
                        </li>
                        <li>
                          d. When you use Inoutbit Savings service, you should
                          fully recognize the risks of investing in Digital
                          Assets and operate cautiously.
                        </li>
                        <li>
                          e. You agree that all investment operations conducted
                          on Inoutbit represent your true investment intentions
                          and that unconditionally accept the potential risks
                          and benefits of your investment decisions.
                        </li>
                        <li>
                          f. Inoutbit reserves the right to suspend or terminate
                          Inoutbit Savings service. If necessary, Inoutbit can
                          suspend and terminate Inoutbit Savings service at any
                          time.
                        </li>
                        <li>
                          g. Due to network delay, computer system failures and
                          other force majeure, which may lead to delay,
                          suspension, termination or deviation of execution of
                          Inoutbit Savings service, Inoutbit will use reasonable
                          effort to ensure but not promise that Inoutbit Savings
                          service execution system runs stably and effectively.
                          Inoutbit does not take any responsibility if the final
                          execution fails to match your expectations due to the
                          above factors.
                        </li>
                      </ul>
                    </p>
                    <p>
                      <strong>IV. LIABILITIES</strong>
                      <p>
                        <strong>
                          1. Disclaimer of Damages and Limitation of Liabilities
                        </strong>
                        To the maximum extent permitted by applicable law, in no
                        Event will Inoutbit, its affiliates and their respective
                        Shareholders, members, directors, officers, employees,
                        Attorneys, agents, representatives, suppliers or
                        Contractors be liable for any incidental, indirect,
                        Special, punitive, consequential or similar damages or
                        Liabilities whatsoever (including, without limitation,
                        Damages for loss of data, information, revenue, profits
                        Or other businesses or financial benefits) arising out
                        Of Inoutbit services, any performance or non-performance
                        Of Inoutbit services, or any other product, service or
                        Other item provided by or on behalf of Inoutbit and its
                        Affiliates, whether under contract, statute, strict
                        Liability or other theory even if Inoutbit has been
                        Advised of the possibility of such damages except to the
                        Extent of a final judicial determination that such
                        Damages were a result of Inoutbit’s gross negligence,
                        Fraud, willful misconduct or intentional violation of
                        Law. Some jurisdictions do not allow the exclusion or
                        Limitation of incidental or consequential damages, so
                        The above limitation may not apply to you.
                        <br />
                        <br />
                        Notwithstanding the foregoing, in no event will the
                        Liability of Inoutbit, its affiliates and their
                        Respective shareholders, members, directors, officers,
                        Employees, attorneys, agents, representatives, suppliers
                        Or contractors arising out of services offered by or on
                        Behalf of Inoutbit and its affiliates, any performance or
                        Non-performance of Inoutbit services, or any other
                        Product, service or other item, whether under contract,
                        Statute, strict liability or other theory, exceed the
                        Amount of the fees paid by you to Inoutbit under these
                        Terms in the twelve-month period immediately preceding the event
                        Giving rise to the claim for liability.
                      </p>
                      <p>
                        <strong>2. Disclaimer of Warranties</strong>
                        To the maximum extent permitted under applicable law,
                        Inoutbit services, Inoutbit materials and any product,
                        Service or other item provided by or on behalf of
                        Inoutbit are offered on an “as is” and “as available”
                        Basis, and Inoutbit expressly disclaims, and you waive,
                        Any and all other warranties of any kind, whether
                        Express or implied, including, without limitation,
                        Warranties of merchantability, fitness for a particular
                        Purpose, title or non-infringement or warranties arising
                        From course of performance, course of dealing or usage
                        In trade. Without limiting the foregoing, Inoutbit does
                        Not represent or warrant that the site, Inoutbit services
                        Or Inoutbit materials are accurate, complete, reliable,
                        Current, error-free, or free of viruses or other harmful
                        Components. Inoutbit does not guarantee that any order
                        Will be executed, accepted, recorded or remain open.
                        Except for the express statements, agreements and rules
                        Set forth in these terms, you hereby acknowledge and
                        Agree that you have not relied upon any other statement
                        Or agreement, whether written or oral, with respect to
                        Your use and access of Inoutbit services. Without
                        Limiting the foregoing, you hereby understand and agree
                        That Inoutbit will not be liable for any losses or
                        Damages arising out of or relating to:
                        <ul>
                          <li>
                            A. Any Inaccuracy, Defect or Omission of Digital
                            Assets Price Data
                          </li>
                          <li>
                            B. Any Error or Delay in the Transmission of Such
                            Data
                          </li>
                          <li>C. Interruption in Any Such Data</li>
                          <li>
                            D. Regular or Unscheduled Maintenance Carried Out by
                            Inoutbit and Service Interruption and Change
                            Resulting From Such Maintenance
                          </li>
                          <li>
                            E. Any Damages Incurred by Other Users’ Actions,
                            Omissions or Violation of These Terms
                          </li>
                          <li>
                            F. Any Damage Caused by Illegal Actions of Other
                            Third Parties or Actions Without Authorized by
                            Inoutbit
                          </li>
                          <li>
                            G. Other Exemptions Mentioned in Disclaimers and
                            Platform Rules Issued by Inoutbit
                          </li>
                        </ul>
                        The disclaimer of implied warranties contained herein
                        May not apply if and to the extent it is prohibited by
                        Applicable law of the jurisdiction in which you reside.
                      </p>
                      <p>
                        <strong>3. Indemnification</strong>
                        You agree to indemnify and hold harmless Inoutbit'
                        Operators, their affiliates, contractors, licensors, and
                        their respective directors, officers, employees and
                        agents from and against any claims, actions,
                        proceedings, investigations, demands, suits, costs,
                        expenses and damages (including attorneys’ fees, fines
                        or penalties imposed by any regulatory authority)
                        arising out of or related to:
                        <ul>
                          <li>
                            a. Your use of, or conduct in connection with,
                            Inoutbit Services,
                          </li>
                          <li>
                            b. Your breach or our enforcement of these Terms, or
                          </li>
                          <li>
                            c. Your violation of any applicable law, regulation,
                            or rights of any third party during your use of
                            Inoutbit Services.
                          </li>
                        </ul>
                        You are obligated to indemnify Inoutbit Operators, their
                        affiliates, contractors, licensors, and their respective
                        directors, officers, employees or agents pursuant to
                        these Terms, Inoutbit will have the right, in its sole
                        discretion, to control any action or proceeding and to
                        determine whether Inoutbit wishes to settle, and if so,
                        on what terms.
                      </p>
                    </p>
                    <p>
                      <strong>V. Announcements </strong>
                      Please be aware that all official announcements, news,
                      promotions, competitions and airdrops will be listed on (
                      <Link to="/announcements">Announcements</Link>). Users
                      Undertake to refer to these materials regularly and
                      Promptly. Inoutbit will not be held liable or responsible
                      In any manner of compensation should users incur personal
                      Losses arising from ignorance or negligence of the
                      Announcements.
                    </p>
                    <p>
                      <strong>VI. Termination of Agreement</strong>
                      <p>
                        <strong>1. Suspension of Inoutbit Accounts </strong>
                        You agree that Inoutbit shall have the right to
                        immediately suspend your Inoutbit Account (and any
                        accounts beneficially owned by related entities or
                        affiliates), freeze or lock the Digital Assets or funds
                        in all such accounts, and suspend your access to Inoutbit
                        for any reason including if Inoutbit suspects any such
                        accounts to be in violation of these Terms, our Privacy
                        Policy, or any applicable laws and regulations. You
                        agree that Inoutbit shall not be liable to you for any
                        permanent or temporary modification of your Inoutbit
                        Account, or suspension or termination of your access to
                        all or any portion of Inoutbit Services. Inoutbit shall
                        reserve the right to keep and use the transaction data
                        or other information related to such Inoutbit Accounts.
                        The above account controls may also be applied in the
                        following cases:
                        <br />
                        <br />
                        The Inoutbit Account is subject to a governmental
                        proceeding, criminal investigation or other pending
                        litigation;
                        <ul>
                          <li>
                            • We detect unusual activities in the Inoutbit
                            Account;
                          </li>
                          <li>
                            • We detect unauthorized access to the Inoutbit
                            Account;
                          </li>
                          <li>
                            • We are required to do so by a court order or
                            command by a regulatory/government authority.
                          </li>
                        </ul>
                      </p>

                      <p>
                        <strong>2. Cancellation of Inoutbit Accounts </strong>
                        In case of any of the following events, Inoutbit shall
                        have the right to directly terminate these Terms by
                        cancelling your Inoutbit Account, and shall enjoy the
                        right but not the obligation to permanently freeze
                        (cancel) the authorizations of your Inoutbit Account on
                        Inoutbit and withdraw the corresponding Inoutbit Account
                        thereof: after Inoutbit terminates services to you;
                        <br />
                        <br />
                        You allegedly register or register in any other person’s
                        name as a Inoutbit User again, directly or indirectly;
                        the information that you have provided is untruthful,
                        inaccurate, outdated or incomplete;
                        <br />
                        <br />
                        When these Terms are amended, you state your
                        unwillingness to accept the amended Terms by applying
                        for cancellation of your Inoutbit Account or by other
                        means;
                        <br />
                        <br />
                        You request that Inoutbit Services be terminated; and any
                        other circumstances where Inoutbit deems it should
                        terminate Inoutbit Services.
                        <br />
                        <br />
                        Should your Inoutbit Account be terminated, the account
                        and transactional information that meet data retention
                        standards will be securely stored for 5 years. In
                        addition, if a transaction is unfinished during the
                        account termination process, Inoutbit shall have the
                        right to notify your counterparty of the situation at
                        that time. You acknowledge that a user-initiated account
                        exit (right to erasure under GDPR or other equivalent
                        regulations) will also be subjected to the termination
                        protocol stated above.
                        <br />
                        <br />
                        If Inoutbit is informed that any Digital Assets or funds
                        held in your Inoutbit Account are stolen or otherwise are
                        not lawfully possessed by you, Inoutbit may, but has no
                        obligation to, place an administrative hold on the
                        affected funds and your Inoutbit Account. If Inoutbit does
                        lay down an administrative hold on some or all of your
                        funds or Inoutbit Account, Inoutbit may continue such hold
                        until such time as the dispute has been resolved and
                        evidence of the resolution acceptable to Inoutbit has
                        been provided to Inoutbit in a form acceptable to
                        Inoutbit. Inoutbit will not involve itself in any such
                        dispute or the resolution of the dispute. You agree that
                        Inoutbit will have no liability or responsibility for any
                        such hold, or for your inability to withdraw Digital
                        Assets or funds or execute trades during the period of
                        any such hold.
                      </p>

                      <p>
                        <strong>
                          3. Remaining Funds after Inoutbit Account Termination
                        </strong>
                        Once a Inoutbit Account is closed/withdrawn, all
                        remaining account balance (which includes charges and
                        liabilities owed to Inoutbit) will be payable immediately
                        to Inoutbit. Upon payment of all outstanding charges to
                        Inoutbit (if any), Users will have 5 business days to
                        withdraw all Digital Assets or funds from the account.
                      </p>
                      <p>
                        <strong>
                          4. Remaining Funds after Inoutbit Account Termination
                          Due to Fraud, Violation of Law, or Violation of These
                          Terms
                        </strong>
                        Inoutbit maintains full custody of the Digital Assets,
                        funds and User data/information which may be turned over
                        to governmental authorities in the event of Inoutbit
                        Accounts’ suspension/closure arising from fraud
                        investigations, investigations of violation of law or
                        violation of these Terms.
                      </p>

                      <p>
                        <strong>5. Dormant Accounts </strong>
                        Inoutbit may provide a written notice requiring you to
                        close all of your open positions and withdraw all of
                        your Digital Assets from your Inoutbit Account within 30
                        days of the notice. In the event that you fail to do so,
                        Inoutbit may in its absolute discretion and without prior
                        notice to you:
                        <ul>
                          <li>
                            a. Deem your Inoutbit account as a dormant account;
                          </li>
                          <li>
                            b. Close any open positions in any Inoutbit products;
                          </li>
                          <li>
                            c. Convert the Digital Assets to a different type of
                            Digital Asset (e.g., from BTC to BUSD). For the
                            avoidance of doubt, none of the Inoutbit Operators
                            shall be liable for any loss of profit, tax
                            obligations or any other loss, damage or expense
                            incurred by you resulting from such conversion;
                          </li>
                          <li>
                            d. Transfer such dormant account (including any
                            Digital Assets contained therein) to an affiliate of
                            the Inoutbit Operators, any third-party custodian or
                            an isolated wallet where it is deemed reasonably
                            necessary by Inoutbit to do so. In the event that
                            such transfer has taken place, you have the right to
                            retrieve your digital assets from subject to
                            satisfying Inoutbit’ verification requirements,
                            including completing KYC;
                          </li>
                          <li>
                            e. Charge a dormant account fee to cover the cost of
                            maintaining the assets by the Inoutbit Operators, its
                            affiliates or any third-party and such fee shall be
                            withdrawn directly from the dormant account on a
                            monthly basis; and
                          </li>
                          <li>
                            f. Close a dormant account at any time, and Inoutbit
                            will not be liable for any loss, damage or expense
                            incurred by you as a result of the closure of a
                            dormant account unless there was fraud or willful
                            default by Inoutbit. After a dormant account is
                            closed, it cannot be reactivated by you (i.e. you
                            will need to register a new Inoutbit account if you
                            wish to continue to use Inoutbit Services).
                          </li>
                        </ul>
                      </p>
                    </p>
                    {/*  */}
                    <p>
                      <strong>VII. No Financial Advice </strong>
                      Inoutbit is not your broker, intermediary, agent, or
                      advisor and has no fiduciary relationship or obligation to
                      you in connection with any trades or other decisions or
                      activities effected by you using Inoutbit Services. No
                      communication or information provided to you by Inoutbit is
                      intended as, or shall be considered or construed as,
                      investment advice, financial advice, trading advice, or
                      any other sort of advice. Unless otherwise specified in
                      these Terms, all trades are executed automatically, based
                      on the parameters of your order instructions and in
                      accordance with posted trade execution procedures, and you
                      are solely responsible for determining whether any
                      investment, investment strategy or related transaction is
                      appropriate for you according to your personal investment
                      objectives, financial circumstances and risk tolerance,
                      and you shall be solely responsible for any loss or
                      liability therefrom. You should consult legal or tax
                      professionals regarding your specific situation. Inoutbit
                      does not recommend that any Digital Asset should be
                      bought, earned, sold, or held by you. Before making the
                      decision to buy, sell or hold any Digital Asset, you
                      should conduct your own due diligence and consult your
                      financial advisors prior to making any investment
                      decision. Inoutbit will not be held responsible for the
                      decisions you make to buy, sell, or hold Digital Asset
                      based on the information provided by Inoutbit.
                    </p>
                    {/*  */}
                    <p>
                      <strong>VIII. Compliance with Local Laws </strong>
                      It is Users’ responsibility to abide by local laws in
                      relation to the legal usage of Inoutbit Services in their
                      local jurisdiction as well as other laws and regulations
                      applicable to Users. Users must also factor, to the extent
                      of their local laws all aspects of taxation, the
                      withholding, collection, reporting and remittance to their
                      appropriate tax authorities.ALL USERS OF Inoutbit SERVICES
                      ACKNOWLEDGE AND DECLARE THAT THEIR FUNDS COME FROM
                      LEGITIMATE SOURCES AND DO NOT ORIGINATE FROM ILLEGAL
                      ACTIVITIES; USERS AGREE THAT Inoutbit WILL REQUIRE THEM TO
                      PROVIDE OR OTHERWISE COLLECT THE NECESSARY INFORMATION AND
                      MATERIALS AS PER RELEVANT LAWS OR GOVERNMENT ORDERS TO
                      VERIFY THE LEGALITY OF THE SOURCES AND USE OF THEIR FUNDS.
                      Inoutbit maintains a stance of cooperation with law
                      enforcement authorities globally and will not hesitate to
                      seize, freeze, terminate Users’ accounts and funds which
                      are flagged out or investigated by legal mandate.
                    </p>
                    {/*  */}
                    <p>
                      <strong>IX. Privacy Policy </strong>
                      Access to Inoutbit Services will require the submission of
                      certain personally identifiable information. Please review
                      Inoutbit' Privacy Policy at
                      <Link to="/PrivacyPolicy"> ( PrivacyPolicy ) </Link> for a
                      summary of Inoutbit' guidelines regarding the collection
                      and use of personally identifiable information.
                    </p>
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsOfUsePage;
