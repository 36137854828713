import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import { alertSuccessMessage, alertWarningMessage, alertErrorMessage } from "../../UtilityComponent/CustomAlertMessage";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import { $ } from "react-jquery-plugin";
import Header from "./Header";

const NewPost = () => {
  const navigate = useNavigate();
  const [coinList, setCoinList] = useState([]);
  const [fiatCurrency, setFiatCurrency] = useState([]);
  const [tAndC, setTAndC] = useState(false);
  const [inputData, setInputData] = useState({ asset: '', maxAmount: '', minAmount: '', price: '', priceType: 'Limit', fiat: '', remarks: '', side: 'BUY', time: '5', kyc: false, registered: false, regDays: '', transaction: '', minCurrency: '', currencyAmount: '' });
  const [paymentMethod, setpaymentMethod] = useState([]);
  const [sellPaymentMethod, setSellPaymentMethod] = useState([]);
 const [upiList, setUpiList] = useState([]);
 const [bankList, setBankList] = useState([]);
  const [coins,setCoins] = useState([])
  const [type, setType] = useState()
  
  const paymentList = ["UPI", "BANK"]

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    p2pCoinList()
    fiatCurrencyList()
   fetchPaymentMethods()
    handleCoinList()
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'fiat' || name === 'asset') {
      setInputData({ ...inputData, priceType: 'Limit' });
    }
    if (name === 'priceType' && value === 'Fixed') {
      if (!inputData?.asset || !inputData?.fiat) {
        alertWarningMessage('Please Select Asset and Fiat currency');
        setInputData({ ...inputData, priceType: 'Limit' });
        return
      } else {
        currentPrice()
        setInputData({ ...inputData, priceType: 'Fixed' });
      }
    }

    setInputData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  console.log(paymentMethod, "7777")


  const p2pCoinList = async () => {
    await AuthService.p2pCoinList().then(async (result) => {
      if (result?.success) {
        setCoinList(result?.data)
      }
    });
  };

  const fiatCurrencyList = async () => {
    await AuthService.fiatCurrencyList().then(async (result) => {
      if (result?.success) {
        setFiatCurrency(result?.data)
      }
    });
  };

  const currentPrice = async () => {
    await AuthService.currentPrice(inputData?.fiat, inputData?.asset).then(async (result) => {
      if (result?.success) {
        if (inputData?.side === 'BUY') {
          setInputData({ ...inputData, price: result?.data?.buy_price, priceType: 'Fixed' });
        } else {
          setInputData({ ...inputData, price: result?.data?.sell_price, priceType: 'Fixed' });
        }
      }
    });
  };

  const handleCoinList = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getCoinList().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setCoins(result.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };



  const createAd = async () => {
    if (!tAndC || !inputData?.asset || !inputData?.fiat || !inputData?.maxAmount || !inputData?.minAmount || !inputData?.price || !inputData?.priceType || !inputData?.side || !inputData?.time || paymentMethod?.length === 0) {
      alertWarningMessage('Please fill all required details');
      return
    }
    LoaderHelper?.loaderStatus(true);
    await AuthService.createNewPost(inputData, paymentMethod).then(async (result) => {
      LoaderHelper?.loaderStatus(false)
      if (result?.success) {
        alertSuccessMessage(result?.message);
        setInputData({ asset: '', maxAmount: '', minAmount: '', price: '', priceType: 'Limit', fiat: '', remarks: '', side: 'BUY', time: '5', kyc: false, registered: false, regDays: '', transaction: '', minCurrency: '', currencyAmount: ''});
        setTAndC(false)
        setpaymentMethod([])
        navigate("/p2p_trading")
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    });
  };

  const handleBuyPayment = (item, e) => {
    if (e.target.checked) {
      // setpaymentMethod([...paymentMethod, item])
      let filteredList = upiList?.filter(value => value == item)
      setpaymentMethod([...paymentMethod, ...filteredList])
    } 
    // else {
    //   let filteredItem = paymentMethod?.filter((item) => item !== e.target.value);
    //   setpaymentMethod(filteredItem)
    // }
  };
  const handleCheckboxChange = (event, item) => {
    const { checked } = event.target;
  
    if (checked) {
      // If checked, add the item to the state
      setpaymentMethod((prevPaymentMethod) => [ item]);
    } else {
      // If unchecked, remove the item from the state
      setpaymentMethod((prevPaymentMethod) =>
        prevPaymentMethod.filter((paymentItem) => paymentItem.id !== item.id)
      );
    }
  };

  // const selectSellPayment = (data) => {
  //   let isSelected = sellPaymentMethod?.filter((item) => item.id === data.id);
  //   if (isSelected?.length > 0) {
  //     let arr = sellPaymentMethod?.filter((item) => item.id !== data.id);
  //     setSellPaymentMethod(arr)
  //     const indexOfExisting = paymentMethod.findIndex((item) => item === data.type);
  //     paymentMethod.splice(indexOfExisting, 1);
  //   }
  //   else {
  //     setpaymentMethod([...paymentMethod, data?.type]);
  //     setSellPaymentMethod([...sellPaymentMethod, data]);
  //     $('#add_payment_method')?.modal('hide');
  //     alertSuccessMessage('Payment Mode Added')
  //   }
  // };

  const fetchPaymentMethods = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.fetchPaymentMethods().then(async (result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
         setUpiList(result?.data?.UpiDetails)
         setBankList(result?.data?.BankDetails)
      }
    });
  };





  return (
    <>
       <Header />


      <div className="buy_bar pb-5" >
        <div className="container" >
          <div className="bar_row" >
            <div className="bar_left" >
              <div className="bar_title" >
                <h3> Create New Post  </h3>
                {/* <p className="mb-0" >Lorem Ipsum is simply dummy text of the printing and typesetting industry.  </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bar_data" >
        <div className="container" >
          <div className="row" >
            <div className="col-lg-7" >
              <form onSubmit={(e) => { e.preventDefault(); createAd() }}>
                <div className="post_data" >

                  <div className="post_box" >
                    <h5> <img src="/images/nav_arrow.svg" width="10" className="" alt="" /> I want to {inputData?.side} </h5>
                    <div className="row" >
                      <div className="col-md-6" >
                        <div className="form-group" >
                          <label>Asset</label>
                          <select className="form-select form-control shadow-soft-inner" required name="asset" onChange={handleInputChange} value={inputData?.asset}>
                            <option value='' disabled selected>Select Asset</option>
                            {coinList ? coinList?.map((item, index) => {
                              return (
                                <option key={index}>{item?.short_name}</option>
                              )
                            }) : null}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6" >
                        <div className="form-group" >
                          <label>Fiat Currency</label>
                          <select className="form-select form-control shadow-soft-inner" required name="fiat" onChange={handleInputChange} value={inputData?.fiat} >
                            <option value='' disabled selected>Select Fiat Currency</option>
                            {fiatCurrency ? fiatCurrency?.map((item, index) => {
                              return (
                                <option key={item?.id}>{item?.short_name}</option>
                              )
                            }) : null}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6" >
                        <div className="form-group" >
                          <label>Side</label>
                          <select className="form-select form-control shadow-soft-inner" required name="side" onChange={(e) => { handleInputChange(e); setpaymentMethod([], setSellPaymentMethod([])) }} value={inputData?.side}>
                            <option value='BUY'>Buy</option>
                            <option value='SELL'>Sell</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12" >
                        <div className="pay_warning pay_success">  Fee: 0% </div>
                      </div>
                    </div>
                  </div>
                  <div className="post_box" >
                    <h5> <img src="/images/nav_arrow.svg" width="10" className="" alt="" />  Price Settings </h5>
                    <div className="row" >
                      <div className="col-md-6" >
                        <div className="form-group" >
                          <label>Price Type</label>
                          <select className="form-select form-control shadow-soft-inner" name="priceType" required onChange={handleInputChange} value={inputData?.priceType}>
                            <option value='Limit'>Limit</option>
                            <option value='Fixed'>Fixed</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6" >
                        <div className="form-group" >
                          <label> Price</label>
                          <input className="shadow-soft-inner" required type="number" placeholder="Enter Price" onWheel={(e) => e.target.blur()} name="price" onChange={handleInputChange} disabled={inputData?.priceType === 'Fixed'} value={inputData?.price} />
                        </div>
                      </div>
                      <div className="col-md-6" >
                        <div className="form-group" >
                          <label> Minimum Amount</label>
                          <input className="shadow-soft-inner" required type="number" placeholder="Enter Minimum Amount" onWheel={(e) => e.target.blur()} name="minAmount" onChange={handleInputChange} value={inputData?.minAmount} />
                        </div>
                      </div>
                      <div className="col-md-6" >
                        <div className="form-group" >
                          <label> Maximum Amount</label>
                          <input className="shadow-soft-inner" required type="number" placeholder="Enter Maximum Amount" onWheel={(e) => e.target.blur()} name="maxAmount" onChange={handleInputChange} value={inputData?.maxAmount} />
                        </div>
                      </div>
                      {/* <div className="col-12" >
                        <div className="pay_warning ">
                          <h3 className="text-warning" > <sub className="me-1" >Pair</sub>    80 INR/USDT</h3>
                          <ul>
                            <li>Market Price : 79.74 INR/USDT</li>
                            <li>The Limit Price is fixed market fluctuations</li>
                          </ul>
                        </div>
                      </div> */}

                    </div>
                  </div>
                  {/* <div className="post_box" >
                    <h5> <img src="/images/nav_arrow.svg" width="10" className="" alt="" />  Volume</h5>
                    <div className="row" >
                      <div className="col-12" >
                        <div className="form-group" >
                          <label>Volume</label>
                          <input className="shadow-soft-inner" id="volumee" required type="number" placeholder="Enter Volume" onWheel={(e) => e.target.blur()} name="volume" onChange={handleInputChange} value={inputData?.volume} />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="post_box" >
                    <h5> <img src="/images/nav_arrow.svg" width="10" className="" alt="" />  Time</h5>
                    <div className="row" >
                      <div className="col-12" >
                        <div className="form-group" >
                          <label>Time Period</label>
                          <select className="form-select form-control shadow-soft-inner" required name="time" onChange={handleInputChange} value={inputData?.time}>
                            <option value='5'>5 minutes</option>
                            <option value='10'>10 minutes</option>
                            <option value='15'>15 minutes</option>
                            <option value='30'>30 minutes</option>
                            <option value='60'>1 hour</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="post_box" >
                    <h5> <img src="/images/nav_arrow.svg" width="10" alt="" />  Select Payment Method </h5>  
                           
                              <div className="pay_radio pay_select">
                                <div className="row"> 
                                {(upiList.length > 0 || bankList.length > 0) &&                                 
                                  <div className=" col-md-6 cursor-pointer" data-bs-toggle="modal" data-bs-target="#payment_method_list"> 
                                       {  
                                        paymentMethod.length > 0 ? paymentMethod.map(item => type == "UPI" ? 
                                          <div className="form-check form-checkd" > 
                                            {item?.upi_id}   
                                              <i class="ri-checkbox-circle-fill text-gradient  me-2"></i>
                                          </div>
                                          :  
                                          <div className="form-check form-checkd" > 
                                              {item?.bank_name} 
                                              <i class="ri-checkbox-circle-fill text-gradient  me-2"></i>
                                          </div>                                           
                                          )  :   
                                          <div className="form-check form-checkd" > 
                                              Select Payment Method
                                              <i class="ri-add-line ri-xl text-gradient  me-2"></i>
                                            </div>  
                                        }                                        
                                  </div>                                  
                                } 
                                <div className="col-md-6" >
                                  <button className="form-check text-center btn-outline-warning" type="button" onClick={()=>navigate("/profile/BankAccount")}
                                  //  data-bs-toggle="modal" data-bs-target="#add_payment_method"
                                  >
                                    Add New Method
                                  </button> 
                                </div>
                                </div>
                              </div> 
                           

                          {/* )} */}

                          {/* : <>
                            {paymentList?.map((item) => {
                              return (
                                <div key={item?.id} className='form-field-wrapper bank-acc mb-3 shadow-soft active_payment'>
                                  <div className="acc_details">
                                    <div className="row mt-2" >
                                      <div className="col-md-12 mb-3">
                                        <div className="d-flex align-items-center justify-content-between mb-4 " >
                                          <h6 className="badge rounded-pill badge-success mb-0 p-2">{item?.type}</h6>
                                          <h6 className="badge rounded-pill badge-danger mb-0 p-2 cursor-pointer cancel_payment" onClick={() => selectSellPayment(item)}><i className="ri-close-fill"></i></h6>
                                        </div>
                                        <hr />
                                      </div>
                                      {Object.entries(item)
                                        .filter(([key]) => key !== 'type' && key !== 'id')
                                        .map(([key, value]) => {
                                          const updatedName = key.replace(/_/g, ' ');
                                          return (
                                            <div className="col-md-4" key={key}>
                                              <small>{updatedName}</small>
                                              <h6 className="text-start">{value}</h6>
                                            </div>
                                          )
                                        })}
                                    </div>
                                    <hr />
                                  </div>
                                </div>
                              )
                            })} */}
                           
                            {/* </>} */}

  
                  </div>

                  {/* <div className="post_box" >
                    <h5> <img src="/images/nav_arrow.svg" width="10" className="" alt="" /> Remarks (Optional) </h5>
                    <div className="row" >
                      <div className="col-md-12" >
                        <div className="form-group " >
                          <textarea className="  " placeholder="Write your remark here..." name="remarks" onChange={handleInputChange} value={inputData?.remarks} ></textarea>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="post_box" >
                    <h5> <img src="/images/nav_arrow.svg" width="10" className="" alt="" />Currency Holdings </h5>
                    <div className="row" >
                    <div className="col-md-6" >
                        <div className="form-group" >
                          <label>Currency</label>
                          <select className="form-select form-control shadow-soft-inner" required name="minCurrency" onChange={handleInputChange} value={inputData?.minCurrency} >
                            <option value='' disabled selected>Select Currency</option>
                            {coins ? coins?.map((item, index) => {
                              return (
                                <option key={item?._id}>{item?.short_name}</option>
                              )
                            }) : null}
                          </select>
                        </div>
                      </div>
                    <div className="col-md-6" >
                          <div className="form-group">
                            <label>Enter Amount</label>
                            <input className="shadow-soft-inner" type="number" placeholder="0" name="currencyAmount" onWheel={(e) => e.target.blur()}onChange={handleInputChange} value={inputData?.currencyAmount} />
                          </div>
                        </div>
                      </div>
                     
                      
                  </div>

                  <div className="post_box mb-1" >
                    <h5> <img src="/images/nav_arrow.svg" width="10" className="" alt="" />  Counterparty Conditions</h5>
                    <div className="pay_radio pay_select " >
                      <div className="row" >
                        <div className="col-md-6" >
                          <div className="form-check ">
                            <input className="form-check-input" type="checkbox" id="Completed" checked={inputData?.kyc} onChange={(e) => setInputData({ ...inputData, kyc: e.target.checked })} />
                            <label className="form-check-label" htmlFor="Completed">
                              Completed KYC
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6" >
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="Registered" checked={inputData?.registered} onChange={(e) => { setInputData({ ...inputData, registered: e.target.checked, regDays: !e.target.checked ? '' : inputData?.regDays }); }} />
                            <label className="form-check-label" htmlFor="Registered">
                              Registered
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12" >
                          <div className="form-group " >
                            <label> Register day(s) ago</label>
                            <input className="shadow-soft-inner " type="text" placeholder="0" name="regDays" disabled={!inputData?.registered} onChange={handleInputChange} value={inputData?.regDays} />
                          </div>
                        </div>
                        <div className="col-md-12" >
                          <div className="form-group " >
                            <label>Number of transactions successfully completed</label>
                            <input className="shadow-soft-inner " type="number" placeholder="0" name="transaction" onWheel={(e) => e.target.blur()}onChange={handleInputChange} value={inputData?.transaction} />
                          </div>
                        </div>


                      </div>
                    </div>

                  </div>

                  <div className="advertiseList_tradingAgreement__5tQwy">
                    <div className="form-check mb-4">
                      <input className="form-check-input" type="checkbox" required id="flexCheckDefault" onChange={(e) => setTAndC(e.target.checked ? true : false)} />
                      I have read and agree to Inoutbit Peer-to-Peer (P2P) Service Agreement</div>
                  </div>

                  <div className="advertiseList_placeAnOrderBtnContent__L7B0w row gx-1">
                    <div className="col-6 col-md-4"><button type="button" className="btn text-white shadow-soft btn-block w-100"><span>Cancel</span></button></div>
                    <div className="col-6  col-md-8"><button type="submit" className="btn btn-success btn-block w-100 btn-shadow-soft"  >Create Ad </button></div>
                  </div>

                </div>
              </form>
            </div>

            {/* Buy\Sell Card */}
            <div className="col-lg-5" >
              <div className="side_sticky d-none d-md-block" >
                <div className="post_prev chat_box card" >
                  <div className="prev_head chat-header" >
                    <h4 className="mb-0" >Posting <b className={inputData?.side === "BUY" ? "text-success" : "text-danger"} >{inputData?.side} {inputData?.asset} AD </b>  </h4>
                  </div>
                  <ul className="prev_body" >
                    <li>
                      <div>
                        Price
                      </div>
                      <strong>{(inputData?.asset || inputData?.price || inputData?.fiat) && `${inputData?.asset} / ${inputData?.price} `} <small>{inputData?.fiat}</small> </strong>
                    </li>
                    {/* <li>
                      <div>
                        Amount
                      </div>
                      <strong> <small>{(inputData?.volume || inputData?.price) && `${inputData?.volume * inputData?.price}`} {inputData?.fiat}</small> </strong>
                    </li>
                    <li>
                      <div>
                        Volume
                      </div>
                      <strong> <small>{inputData?.volume} {inputData?.asset}</small> </strong>
                    </li> */}
                    <li>
                      <div>
                        Limits
                      </div>
                      <strong><small>{(inputData?.minAmount || inputData?.maxAmount) && `${inputData?.minAmount} - ${inputData?.maxAmount} ${inputData?.fiat}`} </small> </strong>
                    </li>
                    <li>
                      <div>
                        Payment Method
                      </div>
                      <div className="advertiseList_paymentContent__1oElX justify-content-end">
                        {paymentMethod?.map((item, index) =>
                          <span key={index} className={`advertiseList_paymentList__IageJ ${index % 3 === 0 ? 'badge-danger' : index % 3 === 1 ? 'badge-primary' : 'badge-success'}`}><span><span>{item.type}</span></span></span>)}
                        {/* <span className="advertiseList_paymentList__IageJ badge-primary"><span><span>Tinkoff</span></span></span>
                        <span className="advertiseList_paymentList__IageJ badge-success"><span><span> UPI</span></span></span> */}
                      </div>
                    </li>
                    <div className="text-muted" >
                      <label>Remarks</label>
                      <p className="text_remarks" > <small> Minimum {inputData?.currencyAmount + " " + inputData?.minCurrency} is required in {inputData?.side === "BUY" ? "Seller's": "Buyer's"} P2P Wallet </small></p>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="add_payment_method" tabIndex="-1" aria-labelledby="add_payment_methodLaebl" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" >
          <div className="modal-content">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">Select Payment Method</h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i className="ri-close-fill"></i></button>
            </div>
            <div className="modal-body px-md-4 custom-form ">
              {/* <div className="scroll_payment">
                {paymentList?.length > 0 ?
                  paymentList?.map((item, index) => {
                    const activeItem = sellPaymentMethod?.filter((data) => data.id === item.id);
                    const isActive = activeItem?.length > 0;
                    return (
                      <div key={index} className={`form-field-wrapper bank-acc mb-3 shadow-soft ${isActive && 'active_payment'}`} >
                        <div className="acc_details">
                          <div className="row mt-2" style={{ maxWidth: '700px' }}>
                            <div className="col-md-12 mb-3">
                              <div className="d-flex align-items-center justify-content-start mb-4 " >
                                <h6 className={`badge rounded-pill  mb-0 p-2 badge-${index % 3 === 0 ? 'success' : index % 3 === 1 ? 'danger' : 'primary'}`}>{item?.type}</h6>
                              </div>
                              <hr />
                            </div>
                            {Object.entries(item)
                              .filter(([key]) => key !== 'type' && key !== 'id')
                              .map(([key, value]) => {
                                const updatedName = key.replace(/_/g, ' ');
                                return (
                                  <div className="col-md-4 cursor-pointer" key={key} onClick={() => selectSellPayment(item)}>
                                    <h5 className="text-start"><small>{updatedName}</small> <br />{value}</h5>
                                  </div>
                                )
                              })}
                          </div>
                          <hr />
                        </div>
                      </div>
                    )
                  })

                  : <div className="favouriteData mt-5">
                    <img src="/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                    <p className="mt-2 mb-4" >No payment method added</p>
                  </div>}
              </div> */}
            </div>
            <div className="modal-footer px-8">
              <button type="button" className="btn btn-gradient btn-medium justify-content-center w-100" data-bs-dismiss="modal" aria-label="Close" onClick={() => navigate('/PaymentMethod')}><span><i className="ri-add-fill"></i>Add new</span></button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="payment_method_form" tabIndex="-1" aria-labelledby="payment_method_formLaebl" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-2 pb-5" style={{ backgroundColor: 'rgb(34 36 38)' }}>
                        <div className="modal-header flex-column px-8">
                            <h3 className="modal-title" id="placeBitLaebl">Set my payment method</h3>
                            <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i className="ri-close-fill"></i></button>
                        </div>
                        <div className="modal-body px-md-4 custom-form">
                        <form className="pay_radio pay_select">
                        {type == "UPI" ? upiList?.map((item, index) => {
                                    // return (
                                        // item?.payment_method.map(item => item.type == sellerSelectedPayment  &&
                                        //   Object.entries(item)?.map((item) => {
                                          
                                              return (
                                                  <div key={item} className="form-check cursor-pointer" data-bs-dismiss="modal" >
                                                      <input type="radio" className="form-check-input text-gradient" id={item._id} value={item._id} name={item?._id}
                                                     checked={paymentMethod.some((paymentItem) => paymentItem._id === item._id)}
                                                     onChange={(e) => handleCheckboxChange(e, item)}/>
                                                      <label className="form-check-label mb-0" for={item._id} > {item?.upi_id} </label>                                              
                                                  </div>
                                              )
                                          }) 
                                    
                                        : bankList?.map((item, index) => {
                                  return (
                                      // item?.payment_method.map(item => item.type == buyerSelectedPayment  &&
                                      //   Object.entries(item)?.map((item) => {
                                      //       return (
                                                <div key={item} className="form-check cursor-pointer" data-bs-dismiss="modal" >
                                                  <input type="radio" className="form-check-input text-gradient"  value={item._id} name={item?._id}
                                                     checked={paymentMethod.some((paymentItem) => paymentItem._id == item._id)}
                                                     onChange={(e) => handleCheckboxChange(e, item)}/>
                                                    {/* <label for={item._id} >Account holder Name: {item?.account_holder_name}</label> */} 
                                                    <label for={item._id} className="form-check-label mb-0 sc_bnk_radio" >
                                                      <b>
                                                      {item?.bank_name}
                                                      </b>
                                                      <small>{item?.account_number} </small>
                                                    </label>
                                                    {/* <label>Acount Number: {item?.account_number}</label> */}
                                                    {/* <label>Account Type: {item?.account_type}</label> */}
                                                     {/* <label>Branch: {item?.branch_name}</label>       */}
                                                </div>
                                            )
                                        
                                  
                                  })}
                              </form>
                             </div>

                        {/* <div className="modal-footer px-8">
                            {addType === 'ADD' ? <button type="button" className="  btn btn-gradient btn-medium justify-content-center w-100" onClick={() => addPaymentMethod()} ><span>Add {selectedPaymentMode}</span></button> : <button type="button" className="  btn btn-gradient btn-medium justify-content-center w-100" onClick={() => editPaymentMethod()} ><span>Edit {selectedPaymentMode}</span></button>}


                        </div> */}

                    </div>
                </div>
            </div>

      <div className="modal fade" id="payment_method_list" tabIndex="-1" aria-labelledby="payment_method_listLaebl" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered"  >
                    <div className="modal-content p-2 pb-5"  >
                        <div className="modal-header flex-column px-8">
                            <h3 className="modal-title" id="placeBitLaebl"> Choose Payment Method </h3>
                            <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i className="ri-close-fill"></i></button>
                        </div>
                        <div className="modal-body py-0">
                            { (upiList.length > 0  && bankList.length == 0) &&
                             <button type="button" className="btn btn-gradient  px-3 btn-medium justify-content-between w-100 m-2 pe-0 " 
                             data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#payment_method_form" onClick={()=>{setType("UPI"); console.log(type,"1111")}}>
                              <span>Select UPI ID </span> <i class="ri-arrow-right-line"></i></button>    
                            }
                            { (upiList.length == 0  && bankList.length > 0) &&
                             <button type="button" className="btn btn-gradient  px-3 btn-medium justify-content-between w-100 m-2 pe-0" 
                             data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#payment_method_form" onClick={()=>{setType("BANK"); console.log(type,"1111")}}>
                              <span>  Bank Account </span><i class="ri-arrow-right-line"></i></button>    
                            }

                            {(upiList.length > 0  && bankList.length > 0) &&
                            paymentList?.map((item, index) => {
                                return (
                                  <>
                                    <button key={index} type="button" className="btn btn-gradient btn-medium px-3 justify-content-between w-100 my-2" 
                                    data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#payment_method_form" 
                                    onClick={()=>{setType(item); 
                                    console.log(type,"1111")}}><span>Selet {item} </span> <i class="ri-arrow-right-line pe-0"></i> 
                                    </button>       
                                    </>
                                )
                            })  }
                        </div>
                    </div>
                </div>
            </div>





    </>
  );
};

export default NewPost;
