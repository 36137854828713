import React, { useContext, useEffect } from 'react';
import './index.css';
import { widget } from '../charting_library';
import Datafeed from './datafeed';
import { ProfileContext } from '../../Context';
import logo from './favicon.png'

export default function TVChartContainer({ symbol }) {
	const { check } = useContext(ProfileContext)
	let tvWidget = null;
	const getChart = (symbol) => {
		const widgetOptions = {
			symbol: `${symbol}`,
			interval: '1',
			fullscreen: false,
			timezone: 'Asia/Kolkata',
			container: 'TVChartContainer',
			datafeed: Datafeed,
			has_intraday: true,
			library_path: '/charting_library/',
			pricescale: 100000000,
			intraday_multipliers: ['1', '60'],
			custom_css_url: 'css/style.css',
			height: '390px',
			time_frames: [
				{ text: '6M', resolution: 'D', description: '6 Month' },
				{ text: '1Y', resolution: 'W', description: '1 Year' },
				{ text: '5Y', resolution: 'W', description: '5 Year' },
			],
			theme: 'dark',
			overrides: {
				"symbolInfo.priceFormatter": {
					precision: 4
				},
				"paneProperties.background": '#303234',
				"paneProperties.backgroundType": "solid",
			},

			loading_screen: {
				backgroundColor: "#303234",
			},
			time_scale: {
				min_bar_spacing: 20
			},
			logo: {
				image: logo,
				link: "http://103.175.163.162:5020/trade"
			},

			// enabled_features: ["hide_left_toolbar_by_default",],
			disabled_features: ["use_localstorage_for_settings", "adaptive_logo", "border_around_the_chart"]

		};

		tvWidget = new widget(widgetOptions);
		tvWidget.onChartReady(() => {
			tvWidget.headerReady().then(() => {
				console.log('Chart is ready to display');

			});
		});
	};

	useEffect(() => {

		getChart(symbol);
	}, [symbol, check]);


	return (
		<>
			<div id={'TVChartContainer'} />
		</>

	);
}