import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../UtilityComponent/CustomAlertMessage";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import Header from "./Header";

const MyPost = () => {
    const [myPost, setmyPost] = useState();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        uploadedPost();
    }, []);

    const uploadedPost = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.myAds().then(async (result) => {
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                setmyPost(result?.data)
            }
        });
    };

    const copyCode = (id) => {
        navigator.clipboard
            .writeText(id)
            .then(() => {
                alertSuccessMessage("Copied!!");
            })
            .catch(() => {
                alertErrorMessage("Something went wrong");
            });
    };

    return (
        <>
    <Header />


            <div className="pp_data cst-table mt-4" >
                <div className="container" >

                    <div className="tab-content" id="myTabContent">

                        {/* buy data tab */}
                        <div className="tab-pane fade show active" id="pills-Orders" role="tabpanel" aria-labelledby="ills-Orders-tab-tab">
                            <div className="cst-table-content shadow-soft table-responsive ">
                                <table className="table table-striped " >
                                    <thead className="cst-table-thead">
                                        <tr>
                                            <th className=" ">Type</th>
                                            <th className=" ">Currency</th>
                                            {/* <th className=" ">Fiat Amount</th> */}
                                            <th className=" ">Price </th>
                                            <th className=" ">Crypto amount</th>
                                            <th className=" ">Payment Methods </th>
                                            <th className=" ">Status </th>
                                        </tr>
                                    </thead>
                                    <tbody className="cst-table-tbody">
                                        {myPost?.length > 0 ? myPost?.map((item) => {
                                            return (
                                                <tr className=" ">
                                                    <td className=" ">
                                                        <div className="advertiseList_avatarContent__uAQHP">
                                                            <div className="advertiseList_adAvatarRight__SbOZP"><span className={`advertiseList_nickName__VI9tJ text-${item?.side === 'BUY' ? 'success' : 'danger'}`}>{item?.side}</span>
                                                                <p className="advertiseList_merchantOrder__aO2o_"> Date:  <Moment date={item?.createdAt} format='DD/MM/YY || hh:mm A' /> </p>
                                                                {/* <div className="advertiseList_firstTrade__Z5OC5">
                                                                    <p className="advertiseList_merchantOrder__aO2o_">
                                                                        <span className="text-white" >
                                                                            {item?.order_id}
                                                                        </span>
                                                                        <span className="advertiseList_tradingLine__swVrO"></span>
                                                                        <span className="text-white cursor-pointer" onClick={() => copyCode(item?.order_id)}><i className="ri-file-copy-line"></i> </span>
                                                                    </p>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className=" ">
                                                        {item?.base_short_name}
                                                    </td>
                                                    {/* <td className=" ">
                                                        {item?.volume * item?.fixed_price} INR
                                                    </td> */}
                                                    <td className=" ">
                                                        {item?.fixed_price}  {item?.quote_short_name}
                                                    </td>
                                                    <td className=" ">
                                                        {item?.volume} {item?.base_short_name}
                                                    </td>
                                                    <td className=" ">
                                                        <div className="advertiseList_paymentContent__1oElX">
                                                            {}
                                                            {item?.payment_method?.map((data, index) => {
                                                                return (
                                                                    <span key={index} className={`advertiseList_paymentList__IageJ badge-${index % 3 === 0 ? 'success' : index % 3 === 1 ? 'danger' : 'primary'}`}>
                                                                        <span>
                                                                            {/* <span >{item?.side === 'BUY' ? data : data?.type}</span> */}
                                                                            <span>{ data?.type}</span>
                                                                        </span>
                                                                    </span>
                                                                )
                                                            })}
                                                        </div>
                                                    </td>
                                                    <td className="  text-danger">
                                                        {item?.status}
                                                    </td>

                                                </tr>
                                            )
                                        }) :  <p className="text-center p-3 pb-4 no-data h-100 mb-0" >
                                        <img src="/images/no-data.png" className='img-fluid ' alt="no data" width="52" />
                                        No Data Available
                                      </p>}


                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* sell data tab */}
                        <div className="tab-pane fade" id="pills-Statement" role="tabpanel" aria-labelledby="pills-Statement-tab">
                            <div className="cst-table-content shadow-soft table-responsive ">
                                <table className="table table-striped mb-0" >
                                    <thead className="cst-table-thead">
                                        <tr>
                                            <th className=" ">Payment Method</th>
                                            <th className=" ">Order Type</th>
                                            <th className=" ">Date</th>
                                            <th className=" ">Amount </th>
                                            <th className=" ">Status  </th>
                                            <th className=" ">Actual Amount Received </th>
                                        </tr>
                                    </thead>
                                    <tbody className="cst-table-tbody">
                                        <tr className=" ">
                                            <td className=" ">
                                                Bank Account
                                            </td>
                                            <td className=" ">
                                                Buy
                                            </td>
                                            <td className=" ">
                                                2023-07-03
                                            </td>
                                            <td className=" ">
                                                3,000 INR
                                            </td>
                                            <td className="  text-success">
                                                Completed
                                            </td>
                                            <td className="  text-gradient">
                                                <b>3,000 INR</b>
                                            </td>
                                        </tr>
                                        <tr className=" ">
                                            <td className=" ">
                                                Bank Account
                                            </td>
                                            <td className=" ">
                                                Buy
                                            </td>
                                            <td className=" ">
                                                2023-07-03
                                            </td>
                                            <td className=" ">
                                                3,000 INR
                                            </td>
                                            <td className="  text-success">
                                                Completed
                                            </td>
                                            <td className="  text-gradient">
                                                <b>3,000 INR</b>
                                            </td>
                                        </tr>
                                        <tr className=" ">
                                            <td className=" ">
                                                Bank Account
                                            </td>
                                            <td className=" ">
                                                Buy
                                            </td>
                                            <td className=" ">
                                                2023-07-03
                                            </td>
                                            <td className=" ">
                                                3,000 INR
                                            </td>
                                            <td className="  text-success">
                                                Completed
                                            </td>
                                            <td className="  text-gradient">
                                                <b>3,000 INR</b>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>

                </div>
            </div>



        </>
    );
};

export default MyPost;
