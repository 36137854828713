import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import {useNavigate, useParams } from "react-router-dom";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import useCountdownTimer from "../../UtilityComponent/CountdownTimer";
import { $ } from "react-jquery-plugin";
import { alertErrorMessage, alertSuccessMessage, alertMessage, alertWarningMessage } from "../../UtilityComponent/CustomAlertMessage";
import Header from "./Header";
import { ApiConfig } from "../../Api_Module/Api_Config/ApiEndpoints";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import Messenger from "../Chat/Messenger";

const BuyPTwop = () => {
  const { orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState({});
  const [selectedPayment, setSelectedPayment] = useState();
  const [timerData, setTimerData] = useState({ timeStamp: '', timeLimit: '' });
  const [isComponentMounted, setComponentMounted] = useState(false);
  const [frndId, setFrndId] = useState('')
  const navigate = useNavigate()
  const [rating, setRating] = useState(0);

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  useEffect(() => {
    // Set the component as mounted once it has rendered
    setComponentMounted(true);
  }, []);


  const Id = sessionStorage.getItem("userId")
  

  const orderDetail = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.buyRequest(orderId).then(async (result) => {
    LoaderHelper.loaderStatus(false);
      if (result?.success) {
        setOrderDetails(result?.data?.OrderData)
        setSelectedPayment(result?.data?.PostData?.payment_method[0])
        setTimerData({
          timeStamp: +result?.data?.OrderData?.payment_timestamp,
          timeLimit: +result?.data?.OrderData?.payment_time * 60,
        })
      }
    });
  };

const rateHandler = async(rating) =>{
const result = await AuthService.rateOrder(rating)
if(result.success){
  alertSuccessMessage(result.message)
  navigate("/p2p_trading")
} else{
  alertErrorMessage(result.message)
}
}

  useEffect(()=>{
    if(orderDetails){
      if(orderDetails.postAd_user == Id) {
       setFrndId(orderDetails.trader_id)  
      }else{
        setFrndId(orderDetails.postAd_user)
      }
    }

  },[orderDetails])

  const addOrderNotification = async (orderId) => {
      LoaderHelper.loaderStatus(true);
      await AuthService.addOrderNotification(orderId).then(async (result) => {
        LoaderHelper.loaderStatus(false);
        if (result.success) {
          try {
           console.log(result.message)
          } catch (error) {
            console.log(error);
          }
        }
      });
    };

  const notifyHandler = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.notify(orderId).then(async (result) => {
    LoaderHelper.loaderStatus(false);
      if (result?.success) {
        alertSuccessMessage("Funds transfered successfully")
        navigate("/p2p_trading")
      }
    });
  };

  const notifySeller = async (orderDetails,status, orderId) => {
    await AuthService.notifySellerTrader(orderDetails,status, orderId).then(async (result) => {
      if (result?.success) {
         $("#notify_modal").modal('show');
        alertSuccessMessage(result.message)
      }
    });
  };

  const notifySellerMerchent = async (orderDetails, status, orderId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.notifySellerMerchent(orderDetails, status, orderId).then(async (result) => {
    LoaderHelper.loaderStatus(false);
      if (result?.success) {
        if(status == "CONFIRMED" && orderDetails?.postAd_user == Id && orderDetails?.side == "SELL"){
          notifyHandler()
          addOrderNotification(orderId)
        }       
        else {
          // if(status == "CONFIRMED"){
          //   addOrderNotification(orderId)
          // }     
          if(status == 'DISPUTE'){
            $("#notify_modal_buy").modal('show')
          }
          else if(orderDetails?.trader_id == Id){
            $("#notify_modal_buy").modal('show')
          } 
        
          else if(orderDetails?.postAd_user == Id){
            $("#notify_modal").modal('show')

          }
          alertWarningMessage(result.message)
        }
        
        // $("#notify_modal").modal('show');
      }
    });
  };

  const notifySellerTrader = async (orderDetails, status, orderId) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.notifySellerTrader(orderDetails, status, orderId).then(async (result) => {
    LoaderHelper.loaderStatus(false);
      if (result?.success) {
        if(status == "CONFIRMED" && orderDetails?.trader_id == Id && orderDetails?.side == "BUY" ){
          notifyHandler()
          addOrderNotification(orderId)
        }  
        else{
          // if(status == "CONFIRMED"){
          //   addOrderNotification(orderId)
          // }     
          if(status == 'DISPUTE'){
            $("#notify_modal_buy").modal('show')
          }
          else if(orderDetails?.trader_id == Id){
            $("#notify_modal").modal('show')
          } 
          else if(orderDetails?.postAd_user == Id){
            $("#notify_modal_buy").modal('show')

          }
          alertWarningMessage(result.message)
        }
        
        // $("#notify_modal").modal('show');
      }
    });
  };

  console.log(orderDetails, "8888")


  const copyText = (data) => {
    navigator.clipboard.writeText(data).then(() => { alertSuccessMessage("Copied!!"); }).catch(() => { alertErrorMessage("Something went wrong") })
  };



  const { remainingTime, formattedTime } = useCountdownTimer(timerData?.timeStamp, timerData?.timeLimit);
  let timer = formattedTime?.split("");

  console.log(formattedTime, "7777")

 useEffect(()=>{
if(isComponentMounted && formattedTime == "00:00" && orderDetails.status != "CONFIRMED"){
  handleRunTime("PENDING", orderId)
}
 }, [formattedTime])

 const handleRunTime = async(orderId, status) =>{
  LoaderHelper.loaderStatus(true);
  const result = await AuthService.runtime(orderId, status)
  if(result.success){
    LoaderHelper.loaderStatus(false);
     navigate("/p2p_trading")
     alertErrorMessage('Time ran out. Your order is cancelled...')
    //  alertErrorMessage("Transaction does not completed on time due to which order is in dispute")
  } else{
    LoaderHelper.loaderStatus(false);
    alertErrorMessage(result.message)
  }
 }

  useEffect(() => {
    if (timerData?.timeStamp && timerData?.timeLimit) {
      let maxTimeToComplete = timerData?.timeStamp + timerData?.timeLimit;
      let currTime = Math.floor(Date.now() / 1000);
      if (currTime >= maxTimeToComplete) {
        handleRunTime("CANCELLED", orderId)
        alertErrorMessage('Time ran out. Your order is cancelled...')
        navigate('/p2p_trading')
      }
    }
  }, [remainingTime, timerData]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    orderDetail();
  }, []);


  console.log(orderDetails?.payment_method, "888")

  return (
    <>
      <Header />


      <div className="buy_bar" >
        <div className="container" >
          <div className="bar_row" >
            <div className="bar_left" >
              <div className="bar_title" >
                <h3 className="d-flex align-items-center justify-content-between" >  {orderDetails?.side === 'SELL' ? "Buy" : 'Sell'} {orderDetails?.base_short_name}   <span className="btn btn-sm btn-gradient mobile_chat_icon d-md-none shadow-soft text-white" > Chat </span> </h3>
                <p className="mb-0" >Order has been created :
{orderDetails.status == "CONFIRMED" ? "" :
                  <div className="CountDown_orderCountdown__Gx8IG">
                    <span data-testid="minutes">
                      <span><span className="CountDown_minutesContent___Z3lA shadow-soft">{timer[0] === 'N' ? '0' : timer[0]}</span>
                        <span className="CountDown_minutesContent___Z3lA  shadow-soft">{timer[1] === 'a' ? "0" : timer[1]}</span>
                      </span>
                    </span><span className="CountDown_segmentation__1LinY">:</span>
                    <span data-testid="seconds"><span>
                      <span className="CountDown_minutesContent___Z3lA shadow-soft ">{timer[3] === ':' ? "0" : timer[3]}</span>
                      <span className="CountDown_minutesContent___Z3lA  shadow-soft">{timer[4] === 'N' ? "0" : timer[4]}</span>
                    </span>
                    </span>
                  </div> }

                </p>
              </div>
            </div>
            <div className="bar_right" >
              <p>
                Order Number <b className="ms-3">{orderDetails?.order_id} <i className=" ms-2 ri-file-copy-line cursor-pointer" onClick={() => copyText(orderDetails?.order_id)}></i> </b>
              </p>
              <p>
                Time Created <b className="ms-3"><Moment format="DD:MM:YY hh:mm A" >{orderDetails?.createdAt}</Moment></b>
              </p>
            </div>
          </div>
        </div>
      </div>


      <div className="bar_data" >
        <div className="container" >
          <div className="row" >
            <div className="col-lg-7" >
              <div className="buy_data" >
                <div className="timeline" >
                  <div className="timeline_data" >
                    <h5>Confirm Order Details</h5>
                    <div className="row mt-3 mt-md-5" >
                      <p className="col-6 col-md-3" >
                        <span className="text-success advertiseList_expandPrice__Bgj3_">
                          <b>{orderDetails?.amount} </b> </span>
                        <span className="advertiseList_adLimitTitle__sGUqF d-block">Amount</span>
                      </p>

                      <p className="col-6  col-md-3" >
                        <span className="text-white advertiseList_expandPrice__Bgj3_">
                          <b>{orderDetails?.fixed_price}</b> </span>
                        <span className="advertiseList_adLimitTitle__sGUqF d-block">Price </span>
                      </p>

                      <p className="col-6  col-md-3" >
                        <span className="text-white advertiseList_expandPrice__Bgj3_">
                          <b>{orderDetails?.receiving_amount}</b> </span>
                        <span className="advertiseList_adLimitTitle__sGUqF d-block">Quantitiy</span>
                      </p>


                    </div>
                  </div>

                  <div className="timeline_data" >
                    <h5> Payment Method </h5>
                    {/* <p>Please confirm that you have successfully transferred the money to the seller thorough the following payment methods.</p> */}
                    {/* <div className="pay_warning" >
                      {orderDetails?.side === 'SELL' ? "The following is the seller’s payment info. Please make sure the money is transferred from an account you own, matching your verified name. Money will not be transferred automatically by the platform."
                        :
                        'Provide valid payment details to Buyer. Please make sure the money is transferred to an account you own, matching your verified name. Money will not be transferred automatically by the platform.'}

                    </div> */}
                    <div className="ac_details" >
                      <div className="row" >
                        <div className="col-md-5" >

                          <div className="pay_radio" >
                            {
                             (orderDetails?.side === 'BUY' || orderDetails?.side === 'SELL') && orderDetails?.trader_id == Id &&
                              // orderDetails?.payment_method?.map((item, index) => {
                              //   console.log(item,"mmmmmmm")
                              //   return (
                                  <div className="form-check">
                                    {/* <input className="form-check-input" type="text" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => setSelectedPayment(item)} checked={item === selectedPayment} /> */}
                                    <label className="form-check-label" for="flexRadioDefault1">
                                      {selectedPayment?.type}
                                    </label>
                                  </div>}
                                    
                                  {(orderDetails?.side === 'BUY' || orderDetails?.side === 'SELL') && orderDetails?.postAd_user == Id &&
                                   <div className="form-check">
                                    {/* <input className="form-check-input" type="text" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => setSelectedPayment(item)} checked={item === selectedPayment} /> */}
                                    <label className="form-check-label" for="flexRadioDefault1">
                                      {orderDetails?.payment_method[0]?.type}
                                    </label>
                                  </div>
                                  }
                            
                           
                              
                          </div>

                        </div>
                        <div className="col-md-7" >
                          <div className="ac_data" >
                            {
                             (orderDetails?.side === 'BUY' && orderDetails?.trader_id == Id) &&
                              selectedPayment && Object.entries(selectedPayment)?.map(([key, value]) => {
                                const updatedName = key.replace(/_/g, ' ').toLowerCase().trim();
                                console.log(updatedName, "99")
                                return (
                                  (updatedName !== 'id' && updatedName !== 'type' && updatedName !== 'user id' && updatedName !== 'createdat'&& updatedName !== 'updatedat' && updatedName !== 'v' && updatedName !== 'verified' ) &&
                                  <div className="ac_row" key={key}> 
                                  {updatedName == 'upi image' ? <img src={ApiConfig.baseUrl + value}/> :
                                  <>                             
                                    <small> {updatedName } </small>
                                    <strong onClick={() => copyText(value)}> {value} <i className="ms-2 ri-file-copy-line  cursor-pointer"></i> </strong> </>}
                                  </div>
                                 )
                              }) }

{
                             (orderDetails?.side === 'SELL' && orderDetails?.trader_id == Id) &&
                              selectedPayment && Object.entries(selectedPayment)?.map(([key, value]) => {
                                const updatedName = key.replace(/_/g, ' ').toLowerCase().trim();
                                console.log(updatedName, "99")
                                return (
                                  (updatedName !== 'id' && updatedName !== 'type' && updatedName !== 'user id' && updatedName !== 'createdat'&& updatedName !== 'updatedat' && updatedName !== 'v' && updatedName !== 'verified' ) &&
                                  <div className="ac_row" key={key}> 
                                  {updatedName == 'upi image' ? <img src={ApiConfig.baseUrl + value}/> :
                                  <>                             
                                    <small> {updatedName } </small>
                                    <strong onClick={() => copyText(value)}> {value} <i className="ms-2 ri-file-copy-line  cursor-pointer"></i> </strong> </>}
                                  </div>
                                 )
                              }) }

                              {
                                (orderDetails?.side === 'BUY' && orderDetails?.postAd_user == Id) &&
                                orderDetails?.payment_method[0] && Object.entries(orderDetails?.payment_method[0])?.map(([key, value]) => {
                                   const updatedName = key.replace(/_/g, ' ').toLowerCase().trim();
                                   console.log(updatedName, "99")
                                   return (
                                     (updatedName !== 'id' && updatedName !== 'user id' && updatedName !== 'createdat'&& updatedName !== 'updatedat' && updatedName !== 'v' && updatedName !== 'verified' ) &&
                                     <div className="ac_row" key={key}> 
                                     {updatedName == 'upi image' ? <img src={ApiConfig.baseUrl + value}/> :
                                     <>                             
                                       <small> {updatedName } </small>
                                       <strong onClick={() => copyText(value)}> {value} <i className="ms-2 ri-file-copy-line  cursor-pointer"></i> </strong> </>}
                                     </div>
                                    )
                                 }) 
        
                              } 

{
                                (orderDetails?.side === 'SELL' && orderDetails?.postAd_user == Id) &&
                                orderDetails?.payment_method[0] && Object.entries(orderDetails?.payment_method[0])?.map(([key, value]) => {
                                   const updatedName = key.replace(/_/g, ' ').toLowerCase().trim();
                                   console.log(updatedName, "99")
                                   return (
                                     (updatedName !== 'id' && updatedName !== 'user id' && updatedName !== 'createdat'&& updatedName !== 'updatedat' && updatedName !== 'v' && updatedName !== 'verified' ) &&
                                     <div className="ac_row" key={key}> 
                                     {updatedName == 'upi image' ? <img src={ApiConfig.baseUrl + value}/> :
                                     <>                             
                                       <small> {updatedName } </small>
                                       <strong onClick={() => copyText(value)}> {value} <i className="ms-2 ri-file-copy-line  cursor-pointer"></i> </strong> </>}
                                     </div>
                                    )
                                 }) 
        
                              } 

                              
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  {(orderDetails?.side === 'BUY' && orderDetails?.postAd_user == Id) && <div className="timeline_data" >
                    <h5> Once the funds have been transferred, click ( Transfer Completed, Notify Buyer ) </h5>
                    <div className=" pt-4 pt-md-5">
                      <div className=" " >
                        <button type="button" className="btn btn-success btn-shadow-soft mx-2" disabled={orderDetails?.merchant_status === "CONFIRMED" ? true : false} onClick={()=>{notifySellerMerchent(orderDetails,"CONFIRMED" , orderId);addOrderNotification(orderId)}}>Transfer Completed, Notify Buyer </button>
                        <button type="button" className="btn text-white shadow-soft  mx-2" disabled={orderDetails?.merchant_status === "CONFIRMED" ? true : false}  onClick={()=>handleRunTime( "CANCELLED", orderId)}><span>Cancel</span></button>
                      </div>
                    </div>
                  </div> }
                   {(orderDetails?.side === 'BUY' && orderDetails?.trader_id == Id) &&  <div className="timeline_data" >
                    <h5>Did you recieve the payment ?</h5>
                    <div className=" pt-4 pt-md-5">
                      <div className=" " >
                        <button type="button" className="btn btn-success btn-shadow-soft mx-2" disabled={orderDetails?.trader_status === "CONFIRMED" ? true : false} onClick={()=>{notifySellerTrader(orderDetails ,"CONFIRMED", orderId);  $("#rating_modal").modal('show')}}>Yes</button>
                        <button type="button" className="btn text-danger shadow-soft  mx-2" disabled={orderDetails?.trader_status === "CONFIRMED" ? true : false} onClick={()=>notifySellerTrader(orderDetails ,"DISPUTE", orderId)}><span>No</span></button>
                      </div>
                    </div>
                    </div> }

                    {(orderDetails?.side === 'SELL' && orderDetails?.postAd_user == Id) &&  <div className="timeline_data" >
                    <h5>Did you recieve the payment ?</h5>
                    <div className=" pt-4 pt-md-5">
                      <div className=" " >
                        <button type="button" className="btn btn-success btn-shadow-soft mx-2" disabled={orderDetails?.merchant_status === "CONFIRMED" ? true : false} onClick={()=>{notifySellerMerchent(orderDetails ,"CONFIRMED", orderId);  $("#rating_modal").modal('show')}}>Yes</button>
                        <button type="button" className="btn text-danger shadow-soft  mx-2" disabled={orderDetails?.merchant_status === "CONFIRMED" ? true : false} onClick={()=>notifySellerMerchent(orderDetails ,"DISPUTE", orderId)}><span>No</span></button>
                      </div>
                    </div>
                    </div> }

                    {(orderDetails?.side === 'SELL' && orderDetails?.trader_id == Id) && <div className="timeline_data" >
                    <h5> Once the funds have been transferred, click ( Transfer Completed, Notify Seller ) </h5>
                    <div className=" pt-4 pt-md-5">
                      <div className=" " >
                        <button type="button" className="btn btn-success btn-shadow-soft mx-2" disabled={orderDetails?.trader_status === "CONFIRMED" ? true : false} onClick={()=>{notifySellerTrader(orderDetails ,"CONFIRMED" , orderId);addOrderNotification(orderId)}}>Transfer Completed, Notify Seller </button>
                        <button type="button" className="btn text-white shadow-soft  mx-2" disabled={orderDetails?.trader_status === "CONFIRMED" ? true : false} onClick={()=>handleRunTime( "CANCELLED", orderId)}><span>Cancel</span></button>
                      </div>
                    </div>
                  </div> }


                  {/* {orderDetails?.side === 'BUY' && <div className="timeline_data" >
                    <h5> Once the funds have been transferred, click ( Transfer Completed, Notify Seller ) </h5>
                    <div className=" pt-4 pt-md-5">
                      <div className=" " >
                        <button type="button" className="btn btn-success btn-shadow-soft mx-2" onClick={()=>notifySeller("CONFIRMED" , orderId)}>Transfer Completed, Notify Seller </button>
                        <button type="button" className="btn text-white shadow-soft  mx-2"><span>Cancel</span></button>
                      </div>
                    </div>
                  </div>}
                   {orderDetails?.side === 'SELL' || orderDetails?.trader_id == Id &&  <div className="timeline_data" >
                    <h5>Did you recieve the payment ?</h5>
                    <div className=" pt-4 pt-md-5">
                      <div className=" " >
                        <button type="button" className="btn btn-success btn-shadow-soft mx-2" onClick={()=>notifySellerMerchent("CONFIRMED", orderId)}>Yes</button>
                        <button type="button" className="btn text-danger shadow-soft  mx-2" onClick={()=>notifySellerMerchent("DISPUTE", orderId)}><span>No</span></button>
                      </div>
                    </div>
                    </div>
                   } */}
                    {/* <h5> Once the funds have been transferred, click ( Transfer Completed, Notify Seller ) </h5> */}
                    {/* <div className=" pt-4 pt-md-5">
                      <div className=" " >
                        <h5>Once you get the payment, click ( Transfer funds ) </h5>
                        <button type="button" className="btn btn-success btn-shadow-soft mx-2" onClick={()=>notifyHandler()}>Transfer Funds</button>
                      </div>
                    </div> */}
                 

                  {/* <div className="timeline_data" >
                    <h5> Once the funds have been transferred, click ( Transfer Completed, Notify Seller ) </h5>
                    <div className=" pt-4 pt-md-5">
                      <div className=" " >
                        <button type="button" className="btn btn-success btn-shadow-soft mx-2" onClick={()=>notifyHandler()}>Transfer Completed, Notify Seller </button>
                        <button type="button" className="btn text-white shadow-soft  mx-2"><span>Cancel</span></button>
                      </div>
                    </div>
                  </div> */}



                </div>
              </div>

              <hr />
              <div className="data_desc" >
                <strong>Discription</strong>
                <ul>
                  <li> Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</li>
                  <li> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</li>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                </ul>
              </div>
            </div>

            {frndId &&
            <div className="col-lg-5">
            {/* <div className="side_sticky modal_chat_mobile " > */}
                <Messenger frndId = {frndId} orderId={orderId} orderStatus={orderDetails?.status}/>
            {/* </div> */}
            </div>  }
         
         

            {/* <div className="col-lg-5" >
              <div className="side_sticky modal_chat_mobile " >
                <div className="chat_box card" >

                  <div className="chat-header">
                    <div className="avatar-box  d-flex align-items-start justify-content-">
                      <div><span className="avatar shadow-soft"> <span>V</span></span>
                        <div>
                          <span className="nickName d-block">VavilonTraders</span>
                          <p className="advertiseList_merchantOrder__aO2o_ mt-1"><span className="text-warning"><i className="ri-star-fill me-1"></i><i className="ri-star-fill me-1"></i><i className="ri-star-fill me-1"></i><i className="ri-star-fill me-1"></i><i className="ri-star-fill me-1"></i></span><span className="advertiseList_tradingLine__swVrO"></span><span className="text-warning">5.0</span></p>

                        </div>
                      </div>
                      <span className=" text-white chat_close_icon d-md-none" > <i className="ri-close-fill"></i> </span>
                    </div>
                    <div className="other-box">
                      <div>
                        <div className="line-info">
                          <div>
                            <div className="label">30D Trades</div>
                            <div className="value">7898</div>
                          </div>
                          <div>
                            <div className="label">30D Completion Rate</div>
                            <div className="value">100.00%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="chat_body scroll_y scroll_y_reverse" >
                    <div className="msg_history scroll_bar">
                      <div className="incoming_msg">
                        <div className="incoming_msg_img avatar shadow-soft "><span>V</span></div>
                        <div className="received_msg">
                          <div className="received_withd_msg">
                            <p>Hi, How are you ?</p> </div>
                        </div>
                      </div>
                      <div className="outgoing_msg">
                        <div className="sent_msg">
                          <p>Hello, i am fine thankyou, what about you ?</p>
                        </div>
                        <div className="incoming_msg_img avatar shadow-soft "><span>V</span></div>
                      </div>
                      <div className="incoming_msg">
                        <div className="incoming_msg_img avatar shadow-soft "><span>V</span></div>
                        <div className="received_msg">
                          <div className="received_withd_msg">
                            <p>Hi, How are you ?</p> </div>
                        </div>
                      </div>
                      <div className="outgoing_msg">
                        <div className="sent_msg">
                          <p>Hello, i am fine thankyou, what about you ?</p>
                        </div>
                        <div className="incoming_msg_img avatar shadow-soft "><span>V</span></div>
                      </div>
                      <div className="incoming_msg">
                        <div className="incoming_msg_img avatar shadow-soft "><span>V</span></div>
                        <div className="received_msg">
                          <div className="received_withd_msg">
                            <p>Hi, How are you ?</p> </div>
                        </div>
                      </div>
                      <div className="outgoing_msg">
                        <div className="sent_msg">
                          <p>Hello, i am fine thankyou, what about you ?</p>
                        </div>
                        <div className="incoming_msg_img avatar shadow-soft "><span>V</span></div>
                      </div>
                      <div className="incoming_msg">
                        <div className="incoming_msg_img avatar shadow-soft "><span>V</span></div>
                        <div className="received_msg">
                          <div className="received_withd_msg">
                            <p>Hi, How are you ?</p> </div>
                        </div>
                      </div>
                      <div className="outgoing_msg">
                        <div className="sent_msg">
                          <p>Hello, i am fine thankyou, what about you ?</p>
                        </div>
                        <div className="incoming_msg_img avatar shadow-soft "><span>V</span></div>
                      </div>
                      <div className="incoming_msg">
                        <div className="incoming_msg_img avatar shadow-soft "><span>V</span></div>
                        <div className="received_msg">
                          <div className="received_withd_msg">
                            <p>Hi, How are you ?</p> </div>
                        </div>
                      </div>
                      <div className="outgoing_msg">
                        <div className="sent_msg">
                          <p>Hello, i am fine thankyou, what about you ?</p>
                        </div>
                        <div className="incoming_msg_img avatar shadow-soft "><span>V</span></div>
                      </div>
                      <div className="incoming_msg">
                        <div className="incoming_msg_img avatar shadow-soft "><span>V</span></div>
                        <div className="received_msg">
                          <div className="received_withd_msg">
                            <p>Hi, How are you ?</p> </div>
                        </div>
                      </div>
                      <div className="outgoing_msg">
                        <div className="sent_msg">
                          <p>Hello, i am fine thankyou, what about you ?</p>
                        </div>
                        <div className="incoming_msg_img avatar shadow-soft "><span>V</span></div>
                      </div>
                      <div className="incoming_msg">
                        <div className="incoming_msg_img avatar shadow-soft "><span>V</span></div>
                        <div className="received_msg">
                          <div className="received_withd_msg">
                            <p>1. Navigate to the [Buy] section and pick your desired fiat currency for payment and the cryptocurrency you wish to acquire. Next, input the amount you'd like to pay or the amount of cryptocurrency you want to receive.

                              2. Simply click [Buy] and choose a convenient payment method

                              3. Once your payment is verified, the cryptocurrencies you've purchased will be automatically credited to your Inoutbit account</p> </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="chat-footer send-message">
                    <form action="" method="">
                      <input cols="10" rows="1" className="form-control" placeholder="Type your message here ..." />
                      <span className="chat_action">
                        <a href="#" id="attach" className="attach_icon"> <input type="file" />  <i className="ri-attachment-2"></i> </a>
                        <a href="#" id="attach" className="attach_icon icon_send" >  <i className="ri-send-plane-fill"></i></a>
                      </span>
                    </form>
                  </div>





                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    {/* Rating Modal */}
    <div className="modal fade" id="rating_modal" tabIndex="-1" aria-labelledby="rating_modalLaebl" aria-hidden="false">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '500px' }}>
                    <div className="modal-content p-2 pb-5" style={{ backgroundColor: 'rgb(34 36 38)',display:"flex", alignItems: "center" }}>
                        <div className="modal-header flex-column px-8" style={{borderBottom: "none"}}>
                            <h3 className="modal-title" id="placeBitLaebl">Please Rate the order experience</h3>
                            {/* <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">OK</button> */}
                        </div>
                        <div>
                        {[1, 2, 3, 4, 5].map((star) => (
        <span
          key={star}
          style={{ cursor: 'pointer', color: star <= rating ? 'gold' : 'gray'}}
          onClick={() => handleRatingChange(star)}
         
        >
          ★
        </span>
      ))}
      </div>

<p>You rated: {rating}</p>
<button type="button" className="btn btn-gradient" onClick={()=>rateHandler(rating)}>Submit</button>
                                      

                    </div>
                </div>
            </div>

{/* Modal */}
      <div className="modal fade" id="notify_modal" tabIndex="-1" aria-labelledby="notifuy_modalLaebl" aria-hidden="false">
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '500px' }}>
                    <div className="modal-content p-2 pb-5" style={{ backgroundColor: 'rgb(34 36 38)' }}>
                        <div className="modal-header flex-column px-8" style={{borderBottom: "none"}}>
                            <h3 className="modal-title" id="placeBitLaebl">Please check the funds in P2P Wallet.</h3>
                            {/* <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">OK</button> */}
                        </div>
                        <div className="modal-footer p-1" style={{justifyContent:"center", borderTop: "none"}}>                  
                             <div className="btn btn-gradient btn-medium justify-content-center w-20 m-2" data-bs-dismiss="modal" aria-label="Close" onClick={()=>navigate("/P2PWallet")}><span>OK</span></div>
                        </div>
                        <h6>If you do not receive the funds, then please go to my Orders & update the order status to "NO".</h6>

                    </div>
                </div>
      </div>

      <div className="modal fade" id="notify_modal_buy" tabIndex="-1" aria-labelledby="notifuy_modalLaebl" aria-hidden="false">
          <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '500px' }}>
              <div className="modal-content p-2 pb-5" style={{ backgroundColor: 'rgb(34 36 38)' }}>
                  <div className="modal-header flex-column px-8" style={{borderBottom: "none"}}>
                      <h3 className="modal-title" id="placeBitLaebl">Please check the balance in your account</h3>
                      {/* <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">OK</button> */}
                  </div>
                  <div className="modal-footer p-1" style={{justifyContent:"center", borderTop: "none"}}>                  
                        <div className="btn btn-gradient btn-medium justify-content-center w-20 m-2" data-bs-dismiss="modal" aria-label="Close"><span>OK</span></div>
                  </div>
                  <h6>If you do not receive the payment, then please go to my Orders & update the order status to "NO".</h6>

              </div>
          </div>
      </div>

         

           






    </>
  );
};

export default BuyPTwop;
