import axios from 'axios';
import {FRIEND_GET_SUCCESS,MESSAGE_GET_SUCCESS,MESSAGE_SEND_SUCCESS} from "../types/messengerType";
import { ApiConfig } from '../../Api_Module/Api_Config/ApiEndpoints';

export const getFriends = (frndId, orderId) => async(dispatch) => {
     try{
          const token = sessionStorage.getItem("token")
          const config = {
               headers: {
                 Authorization: token,
               },
             };

          const payload = {
             friendId: frndId,
             order_id : orderId
             };
            
         
          const response = await axios.post(`${ApiConfig.baseUrl}v1/messenger/get-friends`, payload, config);
           dispatch({
                type: FRIEND_GET_SUCCESS,
                payload : {
                     friends : response.data
                }
           })

     }catch (error){
          console.log(error.response.data);
     }
}

export const messageSend = (data) => async(dispatch) => {
    try{
     const token = sessionStorage.getItem("token")
          const config = {
               headers: {
                 Authorization: token,
               },
             };
     const response = await axios.post(`${ApiConfig.baseUrl}v1/messenger/send_message`, data, config);
     dispatch({
          type : MESSAGE_SEND_SUCCESS,
          payload : {
               message : response.data?.data
          }
     })
    }catch (error){
     console.log(error.response?.data);
    }
}


export const getMessage = (id, orderId) =>  async(dispatch) => {
          try{
               const token = sessionStorage.getItem("token")
               const config = {
                    headers: {
                      Authorization: token,
                    },
                  };

               const payload = {
                    order_id : orderId
               };  
               const response = await axios.post(`${ApiConfig.baseUrl}v1/messenger/get-message/:id?frnId=${id}` , payload, config )
               console.log(response.data.data, "55555")
              dispatch({
                   type : MESSAGE_GET_SUCCESS,
                   payload : {
                    message : response.data.data
                   }
              })
          }catch (error){
               console.log(error.response.data)
          }
     }


export const ImageMessageSend = (formData, setMsgImage) => async(dispatch)=>{
     try{
          const token = sessionStorage.getItem("token")
               const config = {
                    headers: {
                         'Content-Type': 'multipart/form-data',
                         "Authorization": token,
                    },
                  };
          const response = await axios.post(`${ApiConfig.baseUrl}v1/messenger/image_message_send`,formData, config);
          setMsgImage(response?.data?.data?.message?.image)
          dispatch({
               type: MESSAGE_SEND_SUCCESS,
               payload : {
                    message : response?.data.data
               }
          })
          
     }catch (error){
          console.log(error.response.data);

     }

}
