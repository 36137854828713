import React from "react";

const MaitancePage = () => {

    return (
        <>  
        <div className="maintance_page" >
                <marquee className='text-danger pb-2 pt-2 m-0'>We're currently undergoing maintenance to improve your experience. We'll be back shortly. Thank you for your patience.
            </marquee>
            <div style={{ margin: '0px', padding: '0px', height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', background: '#ffffff' }}>
                <img alt="" src="/images/maintance.jpg" className="img-fluid" />
            </div>
        </div>
            
        </>
    );

}

export default MaitancePage;