import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ProfileContext } from "../Context";
import LandingPage from "../Ui_Components/LandingPage";
import UserHeader from "../Ui_Components/UserHeader";
import LoginPage from "../Ui_Components/LoginPage";
import AuthHeader from "../Ui_Components/AuthHeader";
import SignupPage from "../Ui_Components/SignupPage";
import Footer from "../Ui_Components/Footer";
import AccountPage from "../Ui_Components/ProfilePage";
import TradePage from "../Ui_Components/TradePage";
import MarketPage from "../Ui_Components/MarketPage";
import ComingSoonPage from "../UtilityComponent/ComingSoonPage";
import FundPage from "../Ui_Components/FundPage";
import KycPage from "../Ui_Components/KycPage";
import TermsOfUsePage from "../Ui_Components/TermsOfUsePage";
import Career from "../Ui_Components/CareerPage";
import Support from "../Ui_Components/Support";
import AboutUs from "../Ui_Components/AboutUs";
import PrivacyPolicyPage from "../Ui_Components/PrivacyPolicyPage";
import ForgotPassPage from "../Ui_Components/ForgotPassPage";
import ReferralPage from "../Ui_Components/ReferralPage";
import ReferralList from "../Ui_Components/ReferralList";
import PTwoP from "../Ui_Components/PTwoP";
import BuyPTwop from "../Ui_Components/PTwoP/BuyPTwop";
import NewPost from "../Ui_Components/PTwoP/NewPost";
import MyOrders from "../Ui_Components/PTwoP/MyOrders";
import AuthService from "../Api_Module/Api_Services/AuthService";
import MaitancePage from "../UtilityComponent/MaintancePage";
import SettingsPage from "../Ui_Components/SettingsPage";
import TwofactorPage from "../Ui_Components/TwofactorPage";
import CurrencyPrefrence from "../Ui_Components/CurrencyPrefrence";
import BankAccount from "../Ui_Components/BankAccount";
import NotificationPage from "../Ui_Components/NotificationPage";
import SecurityPage from "../Ui_Components/SecurityPage";
import ActivitylogPage from "../Ui_Components/ActivitylogPage";
import DownloadReports from "../Ui_Components/DownloadReports";
import TransferHistory from "../Ui_Components/TransferHistory";
import MyPost from "../Ui_Components/PTwoP/MyPost";
import PaymentMethod from "../Ui_Components/PTwoP/PaymentMethod";
import P2PWallet from "../Ui_Components/P2PWallet";
import Messenger from "../Ui_Components/Chat/Messenger";


const Routing = () => {
  const [token, settoken] = useState();
  const [showMaintance, setshowMaintance] = useState(false);
  const { updateRouting } = useContext(ProfileContext);
  useEffect(() => {
    const authToken = sessionStorage.getItem("token");
    settoken(authToken)
  }, [updateRouting]);

  useEffect(() => {
    GetMaintenance()
  }, []);

  const GetMaintenance = async () => {
    await AuthService.getMaintenance().then((result) => {
      if (result?.success) {
        try {
          setshowMaintance(result?.data?.maintenance);
        } catch (error) {
          console.log(error);
        }
      }
      return;
    });
  };
  return (
    showMaintance === false ?
      <Router>

        {/*  // ********* Component for AlertBox ********** // */}
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover limit={1} theme="light" />

        {/* // ********* Routing ********** // */}
        {token ? <AuthHeader /> : <UserHeader />}
        <Routes >
          <Route exact path="/" element={<LandingPage />}></Route>
          <Route exact path="/*" element={<LandingPage />}></Route>
          <Route exact path="/comingsoon" element={<ComingSoonPage />}></Route>
          <Route exact path="/trade" element={<TradePage />}></Route>
          <Route exact path="/MarketPage" element={<MarketPage />}></Route>
          <Route exact path="/Career" element={<Career />}></Route>
          <Route exact path="/AboutUs" element={<AboutUs />}></Route>
          <Route exact path="/PrivacyPolicyPage" element={<PrivacyPolicyPage />}></Route>
          
          <Route exact path="/ReferralPage" element={<ReferralPage />}></Route>
          <Route exact path="/TermsOfUsePage" element={<TermsOfUsePage />}></Route>
          <Route exact path="/FundPage" element={token ? <FundPage /> : <LoginPage />}></Route>
          <Route exact path="/P2PWallet" element={token ? <P2PWallet /> : <LoginPage />}></Route>
          <Route exact path="/login" element={token ? <AccountPage /> : <LoginPage />}></Route>
          <Route exact path="/ReferralList" element={token ? <ReferralList /> : <LoginPage />}></Route>
          <Route exact path="/ForgotPassPage" element={!token ? <ForgotPassPage /> : <AccountPage />}></Route>
          <Route exact path="/KycPage" element={token ? <KycPage /> : <LoginPage />}></Route>
          <Route exact path="/signup" element={token ? <AccountPage /> : <SignupPage />}></Route>
          <Route exact path="/Profile" element={token ? <AccountPage /> : <LoginPage />}>
            <Route exact path="SettingsPage" element={<SettingsPage />}></Route>
            <Route exact path="Support" element={<Support />}></Route>
            <Route exact path="TwofactorPage" element={<TwofactorPage />}></Route>
            <Route exact path="CurrencyPrefrence" element={<CurrencyPrefrence />}></Route>
            <Route exact path="BankAccount" element={<BankAccount />}></Route>
            <Route exact path="NotificationPage" element={<NotificationPage />}></Route>
            <Route exact path="SecurityPage" element={<SecurityPage />}></Route>
            <Route exact path="ActivitylogPage" element={<ActivitylogPage />}></Route>
            <Route exact path="DownloadReports" element={<DownloadReports />}></Route>
            <Route exact path="TransferHistory" element={<TransferHistory />}></Route>
          </Route>
          <Route exact path="/p2p_trading" element={token ? <PTwoP /> : <LoginPage />}></Route>
          <Route exact path="/buyPTwop_details/:orderId" element={<BuyPTwop />}></Route>
          <Route exact path="/add_post" element={token ? <NewPost /> : <LoginPage />}></Route>
          <Route exact path="/my_orders" element={token ? <MyOrders /> : <LoginPage />}></Route>
          <Route exact path="/MyPost" element={token ? <MyPost /> : <LoginPage />}></Route>
          <Route exact path="/PaymentMethod" element={token ? <PaymentMethod /> : <LoginPage />}></Route>
          <Route exact path="/messanger" element={token ? <Messenger /> : <LoginPage />}></Route>
        </Routes >
        <Footer />
      </Router>
      : <>

        <Router>
          <Routes>
            <Route path='*' element={<MaitancePage />} />
          </Routes>
        </Router>
      </>
  );
};

export default Routing;
